package tools

import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import net.sergeych.bintools.CRC
import net.sergeych.sprintf.sprintf

@Suppress("unused")
fun Instant.shortString() = "%td.%1!tm.%1!ty %1!tT".sprintf(this)

fun Instant.onlyDateAsString() = withMonthNameFull(this)

fun withMonthNameFull(instant: Instant): String {
    val d = instant.toLocalDateTime(TimeZone.currentSystemDefault())
    val n = d.monthNumber
    return "${d.dayOfMonth} ${
        when (n) {
            1 -> "Января"
            2 -> "Февраля"
            3 -> "Марта"
            4 -> "Апреля"
            5 -> "Мая"
            6 -> "Июня"
            7 -> "Июля"
            8 -> "Августа"
            9 -> "Сентября"
            10 -> "Октября"
            11 -> "Ноября"
            12 -> "Декабря"
            else -> instant.toLocalDateTime(TimeZone.currentSystemDefault())
        }
    } ${d.year}"
}

fun String.trimMiddle(size: Int): String {
    if (this.length <= size) return this
    var l0 = (size - 1) / 2
    val l1 = l0
    if (l0 + l1 + 1 < size) l0++
    if (l0 + l1 + 1 != size) throw RuntimeException("big in trimMiddle: $size $l0 $l1")
    return substring(0, l0) + '…' + substring(length - l1)
}

fun String.trimToEllipsis(size: Int): String {
    if (this.length <= size) return this
    return (this.substring(0, size - 1)) + '…'
}

fun Number.toDataSize(): String {
    var d = toLong()
    if (d < 1024)
        return "${d}b"
    d /= 1024
    if (d < 1024)
        return "${d}Kb"
    d /= 1024
    if (d < 1024)
        return "${d}Mb"
    d /= 1024
    if (d < 1024)
        return "${d}Gb"
    d /= 1024
//    if( d < 1024 )
    return "${d}Tb"
}

fun signedCrc32(text: String): Int = CRC.crc32(text.encodeToByteArray()).toInt()