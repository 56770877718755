package net.sergeych.intecowork.api

enum class BlockType {
    Meta, KeyContainer, Body, Image, Attachment, Log, PublicHandle,
    EncryptedMainKey,
    PublicMainKey,
    Info,
    State,
    DeletionMark,
    // 2nd gen blocks ----------------------------------------------------------
    /**
     * Chat blocks relies on id, Api
     */
    Chat,
    /**
     * Cell is a block that does not use structure and relies on its guid
     * that could have special encoding.
     */
    Cell,
    UserData,
    Comment
}