package editor.plugins

import DocsCache
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import controls.classNames
import document.Caret
import document.Fragment
import editor.DocChain
import editor.Editor
import editor.Point
import editor.operations.*
import kotlinx.browser.window
import net.sergeych.intecowork.doc.IcwkDocument
import net.sergeych.mp_tools.globalLaunch
import net.sergeych.mp_tools.trimToEllipsis
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.I
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.events.KeyboardEvent
import org.w3c.dom.url.URL
import views.titleOrDefault

val URL_PATTERN = "(http|ftp|https)://([\\w_-]+(?:\\.?[\\w_-]+)+)([\\w.,@?^=%&:/~+#-]*[\\w@?^=%&/~+#-])"

fun getLinks(text: String): List<IntRange> {
    val pattern = Regex(URL_PATTERN)

    return pattern.findAll(text).map { it.range }.toList()
}

//fun isDocumentLink(link: String): Boolean {
//    val url = URL(link)
//
//    return url.hostname == window.location.hostname && url.pathname.startsWith("/documents")
//}
//
//fun getDocumentId(url: String): Long? {
//
//}
//
//suspend fun DocChain.updateLinks() {
//
//    forEachBlock { b ->
//        var updated = b
//
//        val links = b.getTextFragments().filter { it.isLink() && it.href != null && isDocumentLink(it.href) }
//        var i = 0
//
//        links.forEach {
//            if (it.href != null) {
//                val updatedName = getLinkName(it.href)
//                if (updatedName != it.text) {
//                    console.log("UPDATED LINK NAME", it.text, updatedName)
//                    val updatedLink = it.copy(text = updatedName)
//                    updated = updated.withUpdatedFragment(updatedLink)
//                }
//            }
//        }
//
//        updated
//    }
//}

//suspend fun DocChain.forEachBlock(fn: suspend (b: Block) -> Block) {
//    var i = 0
//
//    val c = caret ?: throw BugException("Can't fix without caret")
//
//    while (i < elements.size) {
//        val block = elements[i]
//        val fixed = fn(block)
//
//        if (block != fixed ) { update(fixed) }
//
//        i++
//    }
//}

fun DocChain.addAutoLink(caretStart: Caret, caretEnd: Caret, docId: Long) {
    val origin = window.location.origin
    val link = "${origin}/documents/${docId}"

    val span = Fragment.StyledSpan(text="", href=link)
    delete(CRange(caretStart, caretEnd))
    insertTerminalFragment(span)
}

suspend fun Editor.addAutoLink(caretStart: Caret, caretEnd: Caret, docId: Long) {
    // FIXME: add replay

    runTransaction("ADD AUTO DOCUMENT LINK") { chain, style ->
        chain.addAutoLink(caretStart, caretEnd, docId)
    }
}

class LinkObserver(val dc: Editor) {
    var documents = DocsCache.all.toList()
    var filtered by mutableStateOf<List<IcwkDocument>>(emptyList())
    var isSearchOn by mutableStateOf(false)
    var caretStart: Caret? = null
    var search by mutableStateOf("")
    var selectedIndex by mutableStateOf(0)

    val shortcuts = listOf(
        Shortcut.propagatedEvent("Выход из меню подстановки ссылки", Combo(Mod.ESCAPE)) { dc, ev ->
            turnOff()

            return@propagatedEvent false
        },
        Shortcut.simplePropagated("Каретка влево", Combo(Mod.ALEFT)) { turnOff() },
        Shortcut.simplePropagated("Каретка вправо", Combo(Mod.ARIGHT)) { turnOff() },
        Shortcut.simplePropagated("К предыдущему слову",
            Combo(setOf(Mod.ALT, Mod.ALEFT))
        ) { turnOff() },

        Shortcut.simplePropagated("К следующему слову",
            Combo(setOf(Mod.ALT, Mod.ARIGHT))
        ) { turnOff() },

        Shortcut.simplePropagated("В начало абзаца",
            Combo(setOf(Mod.CTRL, Mod.ALEFT))
        ) { turnOff() },

        Shortcut.simplePropagated("В конец абзаца",
            Combo(setOf(Mod.CTRL, Mod.ARIGHT))
        ) { turnOff() },

        Shortcut.simplePropagated("К предыдущему абзацу",
            Combo(setOf(Mod.CTRL, Mod.AUP))
        ) { turnOff() },

        Shortcut.simplePropagated("К следующему абзацу",
            Combo(setOf(Mod.CTRL, Mod.ADOWN))
        ) { turnOff() },
        Shortcut.simplePropagated("Каретка влево с выделением",
            Combo(setOf(Mod.SHIFT, Mod.ALEFT))
        ) { turnOff() },
        Shortcut.simplePropagated("Каретка вправо с выделением",
            Combo(setOf(Mod.SHIFT, Mod.ARIGHT))
        ) { turnOff() },

        Shortcut.simplePropagated("Каретка вверх с выделением",
            Combo(setOf(Mod.SHIFT, Mod.AUP))
        ) { turnOff() },

        Shortcut.simplePropagated("Каретка вниз с выделением",
            Combo(setOf(Mod.SHIFT, Mod.ADOWN))
        ) { turnOff() },

        Shortcut.simplePropagated("К предыдущему слову с выделением",
            Combo(setOf(Mod.ALT, Mod.SHIFT, Mod.ALEFT))
        ) { turnOff() },

        Shortcut.simplePropagated("К следующему слову с выделением",
            Combo(setOf(Mod.ALT, Mod.SHIFT, Mod.ARIGHT))
        ) { turnOff() },

        Shortcut.simplePropagated("В начало абзаца с выделением",
            Combo(setOf(Mod.CTRL, Mod.SHIFT, Mod.ALEFT))
        ) { turnOff() },

        Shortcut.simplePropagated("В конец абзаца с выделением",
            Combo(setOf(Mod.CTRL, Mod.SHIFT, Mod.ARIGHT))
        ) { turnOff() },

        Shortcut.simplePropagated("К предыдущему абзацу с выделением",
            Combo(setOf(Mod.CTRL, Mod.SHIFT, Mod.AUP))
        ) { turnOff() },

        Shortcut.simplePropagated("К следующему абзацу с выделением",
            Combo(setOf(Mod.CTRL, Mod.SHIFT, Mod.ADOWN))
        ) { turnOff() },
        Shortcut.propagatedEvent("Каретка вверх", Combo(Mod.AUP)) { dc, ev ->
            if (isSearchOn && filtered.size > 0) {
                selectedIndex = (selectedIndex + filtered.size - 1) % filtered.size

                return@propagatedEvent false
            } else {
                return@propagatedEvent true
            }
        },
        Shortcut.propagatedEvent("Каретка вниз", Combo(Mod.ADOWN)) { dc, ev ->
            if (isSearchOn && filtered.size > 0) {
                selectedIndex = (selectedIndex + filtered.size + 1) % filtered.size

                return@propagatedEvent false
            } else {
                return@propagatedEvent true
            }
        },
        Shortcut.propagatedEvent("Удалить символ",
            Combo(Mod.BACKSPACE)
        ) { dc, ev ->
            if (!isSearchOn) return@propagatedEvent true
            else {
                if (search.length > 1) {
                    search = search.substring(0 until search.length - 1)
                    searchDocs()
                }
                else {
                    turnOff()
                }
            }

            console.log("SAE", search)

            return@propagatedEvent true
        },
        Shortcut.propagatedEvent("Подставить ссылку", Combo(Mod.ENTER)) { dc, ev ->
            if (!isSearchOn || filtered.size == 0) return@propagatedEvent true
//            val scope = rememberCoroutineScope()

            globalLaunch { addLink() }

            return@propagatedEvent false
        },
        Shortcut.propagatedEvent("Ввод символа",
            Combo(setOf(Mod.SHIFT, Mod.SINGLE))
        ) { dc, ev ->
            processKey(ev)

            true
        },
        Shortcut.propagatedEvent("Ввод символа",
            Combo(setOf(Mod.SINGLE))
        ) { dc, ev ->
            processKey(ev)

            true
        },
    )

    suspend fun addLink(index: Int? = null) {
        val selectedDoc = filtered[index ?: selectedIndex]

        caretStart?.let { cStart ->
            dc.caret?.let { cEnd ->
                dc.addAutoLink(cStart, cEnd, selectedDoc.docId)
            }
        }

        turnOff()
    }

    fun searchDocs() {
        filtered = documents.filter { it.titleOrDefault.lowercase().indexOf(search.lowercase()) != -1 }

        if (filtered.isEmpty()) turnOff()
    }

    fun processKey(ev: KeyboardEvent) {
        if (!isSearchOn) {
            if (ev.key == "+") turnOn()
        } else {
            if ((search != "" || ev.key != " ")) {
                search += ev.key
                searchDocs()
            } else turnOff()
        }
    }

    fun turnOn() {
        caretStart = dc.caret
        isSearchOn = true
        console.log("DOC SEARCH ON")
    }

    fun turnOff() {
        isSearchOn = false
        caretStart = null
        selectedIndex = 0
        filtered = emptyList()
        search = ""
        console.log("DOC SEARCH OFF")
    }

    fun init() {
        globalLaunch {
            DocsCache.allFlow.collect { list ->
                documents = list
                dc.eventManager.allBlocks.forEach {
                    dc.eventManager.sendRedrawBlock(it, dc)
                }
            }
        }
    }

    fun disconnect() {

    }

    fun getLinkName(url: String?): String? {
        return getDocumentID(url)?.let { docId ->
            var doc = documents.find { it.docId == docId }

            // FIXME: first document load, documents are empty?
            if (doc == null) doc = DocsCache.all.toList().find { it.docId == docId }

            doc?.titleOrDefault
        } ?: url
    }

    @Composable
    fun render() {
        val shouldRender = isSearchOn && filtered.isNotEmpty() && caretStart != null

        if (shouldRender) {
            fun positionCSS(isActive: Boolean): String {
                return if (isActive) "dropdown-item active" else "dropdown-item"
            }

            dc.caretBoxDOMInstant()?.let {
                val caretPoint = Point((it.x1 + it.x0)/2, (it.y1+it.y0)/2)
                val topLeft = calculateTopLeft(caretPoint)


                Div({
                    classes("shadow", "bg-body", "rounded", SC_MODAL_CSS)
                    id("docs-list")
                    style {
                        position(org.jetbrains.compose.web.css.Position.Absolute)
                        left(topLeft.x.px)
                        top((topLeft.y + window.scrollY).px)
                        property("z-index", "10000")
                        maxWidth(MENU_WIDTH.px)
                    }
                }) {

//            Text("context")
                    Div({
                        classNames("dropdown-menu show")
                        style {
                            property(
                                "width",
                                "${MENU_WIDTH}px"
                            )
                        }
                    }) {
                        A(null, {
                            classNames("dropdown-item disabled")
                        }) {
                            I {
                                Text("Добавить ссылку на документ:")
                            }
                        }
                        filtered.forEachIndexed { idx, it ->
                            A(null, {
                                classNames(positionCSS(idx == selectedIndex))
                                onClick {
                                    globalLaunch { addLink(idx) }
                                }
                            }) { Text(it.titleOrDefault.trimToEllipsis(20)) }
                        }
                    }
                }
            }
        }
    }

//    // TODO: add to replay
//    suspend fun autoupdate() {
//        dc.runTransaction(
//            "Link autoupdate",
//            false,
//        ) { chain, transactionStyle ->
//            dc.domObserver.waitAll("LinkObserver: autoupdate")
//
//            chain.updateLinks()
//        }
//    }

    companion object {
        fun isDocumentURL(url: String?): Boolean {
            if (url == null) return false

            try {
                val urlObj = URL(url)
                // FIXME: use Router.DOCUMENT_ROUTE
                return urlObj.hostname == window.location.hostname && urlObj.pathname.startsWith("/documents")
            } catch(e: Throwable) {
                return false
            }
        }

        fun getDocumentID(url: String?): Long? {
            if (url == null || !isDocumentURL(url)) return null
            val urlObj = URL(url)

            return try {
                urlObj.pathname.split("/").last().toLong()
            } catch(e: Throwable) {
                null
            }
        }
    }
}