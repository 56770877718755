package document

import document.ParagraphStyle.Companion.Predefined
import document.Stylesheet.PreparedStyle
import kotlinx.serialization.Serializable
import org.jetbrains.compose.web.css.Color
import tools.UITheme

/**
 * The stylesheet must be serialized into the document body, maybe as the special block.
 * If there is no stylesheet, the default constructor must be used.
 *
 * Document's stylesheet must be user when rendering paragraphs (blocks, cells in table, etc.),
 * by calling [prepare] and use its returned [PreparedStyle] as described in it.
 *
 * See also [ParagraphStyle.Predefined] for predefined styles.
 */
@Serializable
class Stylesheet(
    @Suppress("unused")
    val customStyles: Map<String, ParagraphStyle> = mutableMapOf()
) {

    /**
     * Prepared style includes [paragraphStyle] and possibly empty list of [classNames],
     * css class names, to apply _before any CSS style attributes from [paragraphStyle]_.
     *
     * It must be used in rendering cycle.
     */
    data class PreparedStyle(
        val paragraphStyle: ParagraphStyle,
        val classNames: List<String>
    ) {
        companion object {
            operator fun invoke(style: ParagraphStyle, vararg classNames: String) =
                PreparedStyle(style, classNames.toList())
        }
    }

    /**
     * Create style for rendering a paragraph by combining a given [style] with stylesheet rules.
     * Must be used when rendering paragraphs.
     */
    fun prepare(style: ParagraphStyle): PreparedStyle =
        when (style.name) {
            null, Predefined.Normal.styleName -> {
                if (style.marginBefore == null)
                    PreparedStyle(style, "mt-2")
                else
                    PreparedStyle(style)
            }

            Predefined.Title.styleName -> PreparedStyle(style, "display-4")
            Predefined.Subtitle.styleName ->
                PreparedStyle(
                    ParagraphStyle(
                        defaultTextStyle = TextStyle(
                            textColor = if (UITheme.composeState.value.isDark)
                                Color("#b0b0b0")
                            else
                                Color("#666666")
                        )
                    ).combineWith(style),
                    listOf("fs-3")
                )

            Predefined.Heading1.styleName -> PreparedStyle(style, "h1", "mt-4")
            Predefined.Heading2.styleName -> PreparedStyle(style, "h2", "mt-4")
            Predefined.Heading3.styleName -> PreparedStyle(style, "h3", "mt-3")
            Predefined.Heading4.styleName -> PreparedStyle(style, "h4", "mt-3")
            Predefined.Heading5.styleName -> PreparedStyle(style, "h5", "mt-3")
            else -> PreparedStyle(style)
        }

    fun getNextStyle(style: ParagraphStyle?): ParagraphStyle? {
        val nextStyle = when (style?.name) {
            Predefined.Title.styleName -> Predefined.Subtitle.style
            Predefined.Subtitle.styleName,
            Predefined.Heading1.styleName,
            Predefined.Heading2.styleName,
            Predefined.Heading3.styleName,
            Predefined.Heading4.styleName,
            Predefined.Heading5.styleName -> Predefined.Normal.style

            else -> style
        }

        return nextStyle
    }
}