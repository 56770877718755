package tools

import views.docsAndFolders

fun pluralize(count: Int, one: String, few: String, many: String=few): String {
    return when( count % 100) {
        1, 21, 31, 41, 51, 61, 71, 81, 91 -> one
        in 2..4, in 22..24, in 32..34, in 42..44, in 52..54,
        in 62..64, in 72..74, in 82..84, in 92..94-> few
        else -> many
    }
}

fun pluralizeDocWord(count: Int) = "$count ${pluralize(count, "документ", "документа", "документов")}"


/**
 * Именительный падеж "папка", "папки", "папок"
 */
fun pluralizeFolderWord1(count: Int) = "$count ${pluralize(count, "папкa", "папки", "папок")}"

/**
 * Винительный/родительный падеж "папку"...
 */
fun pluralizeFolderWord2(count: Int) = "$count ${pluralize(count, "папку", "папки", "папок")}"
