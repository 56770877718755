package document

import Mergable
import MergeUnit
import MergedFragment
import editor.*
import editor.operations.TableCell
import editor.views.ImageFormat
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import registerFabricator
import restoreFragments
import tools.randomId

operator fun Fragment.StyledSpan.contains(caret: Caret): Boolean {
    return caret.spanId == guid
}

@Serializable
sealed class FrameFormat {
    @Serializable
    @SerialName("document.FrameFormat.Position")
    data class Position(
        val width: String = "auto",
        val height: String = "auto",
        val isWrapped: Boolean = true,
        val align: String = "left", // left right center
        val margin: String = "1rem",
    ): FrameFormat()
}

enum class TableAxis {
    ROW,
    COL
}

/**
 * Fragment is a platform-independent document logical item (e.g. paragraph, styled text and whatever else a document
 * can hold. It should be kept platform-agnostic to share code
 */
@Serializable
sealed class Fragment {

    abstract val guid: String

    abstract fun find(byGuid: String): Fragment?

    open val lastOffset: Int = 0

    open val isEmpty get() = lastOffset < 1
    open val isNotEmpty get() = lastOffset > 0

    open val isBlank = false

    abstract val isVisible: Boolean

    open fun all(): Sequence<Fragment> = sequence { yield(this@Fragment) }

    override fun equals(other: Any?): Boolean {
        return other is Fragment && other.guid == guid
    }

    override fun hashCode(): Int {
        return guid.hashCode()
    }

    fun asIParagraph(): IParagraph {
        try {
            return this as IParagraph
        } catch(e: ClassCastException) {
            throw BugException("fragment cannot be casted to IParagraph")
        }
    }

    @Serializable
    @SerialName("span")
    data class StyledSpan(
        val text: String,
        val textStyle: TextStyle? = null,
        val href: String? = null,
        override val guid: String = randomId(11),
        override val isVisible: Boolean = true
    ) : Fragment(), Mergable {


        override fun find(byGuid: String): Fragment? = null

        override val lastOffset: Int
            get() = text.length

        fun canJoin(other: StyledSpan): Boolean {
            return textStyle == other.textStyle && href == other.href && isVisible == other.isVisible
        }

        override fun toString(): String = "Sp:${guid.truncateEnd(5)}:[${text.truncateEnd(50)}]"

        override fun equals(other: Any?): Boolean {
            return other is StyledSpan && other.guid == guid && other.text == text && other.textStyle == textStyle && other.href == href
        }

        fun isLink(): Boolean {
            return href != null
        }

        // FIXME: remove invisible space from document
        fun isTextEmpty(): Boolean {
            return text.replace(Regex(" "), "").replace(Regex("${invisibleNBSP}"), "").isEmpty()
        }

        fun textLeft(c: Caret): String {
            return text.substring(0, c.offset)
        }

        fun textRight(c: Caret): String {
            return text.substring(c.offset, text.length)
        }

        override fun hashCode(): Int {
            var result = super.hashCode()
            result = 31 * result + text.hashCode()
            result = 31 * result + (textStyle?.hashCode() ?: 0)
            result = 31 * result + guid.hashCode()
            return result
        }

        override val isBlank: Boolean by lazy {
            // special check for invisible spaces, etc
            for( ch in text ) {
                when(ch) {
                    ' ', '\t', Typography.nbsp, invisibleNBSP -> {}
                    else -> return@lazy false
                }
            }
            true
        }

        override fun toMergeUnits(version: String?): List<MergeUnit> {
            var list = text.map { MergeUnit.StyledSpanChar(it, this) }

            if (text.length == 0) list = listOf(MergeUnit.StyledSpanChar(null, this))

            return list + MergeUnit.StyledSpanStyle(textStyle, this)
        }

        companion object {
            fun fromMergeUnits(units: List<MergeUnit>): MergedFragment? {
                if (units.isEmpty()) return null

                val textPart = units.takeWhile { it is MergeUnit.StyledSpanChar }
                        as List<MergeUnit.StyledSpanChar>

                if (textPart.isEmpty()) return null

                val stylePart = units
                    .slice(textPart.size until units.size)
                    .takeWhile { it is MergeUnit.StyledSpanStyle }
                        as List<MergeUnit.StyledSpanStyle>

                val text = textPart.map { it.char ?: "" }.joinToString("")
                if (stylePart.isEmpty()) {
                    println("Styled span: broken merge unit sequence: char part = '${text}' but no style after")
                    return null
                }

                var style = TextStyle()
                stylePart.forEach {
                    style = TextStyle.computeStyle(style, it.style)
                }

                return MergedFragment(
                    StyledSpan(text, style),
                    textPart + stylePart
                )
            }
        }
    }

    @Serializable
    @SerialName("img")
    data class LinkedImage(
        val url: String,
        override val guid: String = randomId(12), override val isVisible: Boolean = true
    ) : Fragment(), Mergable {
        override fun find(byGuid: String): Fragment? = null

        override fun toMergeUnits(version: String?): List<MergeUnit> {
            return listOf(MergeUnit.LinkedImageUnit(url, this))
        }

        companion object {
            fun fromMergeUnits(units: List<MergeUnit>): MergedFragment? {
                if (units.isEmpty()) return null
                val unit = units.first()

                if (unit !is MergeUnit.LinkedImageUnit) return null

                return MergedFragment(
                    LinkedImage(unit.url),
                    listOf(unit)
                )
            }
        }
    }

    // Deprecated, so it's not mergable
    @Serializable
    @SerialName("simg")
    data class StoredImage(
        val bin: ByteArray,
        val format: ImageFormat,
        override val guid: String = randomId(12), override val isVisible: Boolean = true
    ): Fragment() {
        override fun equals(other: Any?): Boolean {
            return other is StoredImage && other.guid == guid && bin.hashCode() == other.bin.hashCode() && format == other.format
        }

        override fun hashCode(): Int {
            return bin.contentHashCode()
        }

        override fun find(byGuid: String): Fragment? = null
    }
/*
*
*
* */
    @Serializable
    @SerialName("frame")
    data class Frame(
        val type: String,
        val bin: ByteArray,

        val width: String = "auto", // посмотреть и выписать css примеры
        val height: String = "auto",
        val isWrapped: Boolean = true,
        val align: String = "left", // left right center
        val margin: String = "0rem 1rem 0rem 0rem",

        override val guid: String = randomId(12),
        override val isVisible: Boolean = true
    ): Fragment(), Mergable {
        override fun equals(other: Any?): Boolean {
            return other is Frame && other.guid == guid && bin.hashCode() == other.bin.hashCode()
                    && width == other.width && height == other.height && isWrapped == other.isWrapped
                    && align == other.align && margin == other.margin
        }

        override fun hashCode(): Int {
            return bin.contentHashCode()
        }

        override fun find(byGuid: String): Fragment? = null

        override fun toMergeUnits(version: String?): List<MergeUnit> {
            return listOf(
                MergeUnit.FramePosition(type, FrameFormat.Position(width, height, isWrapped, align, margin), this, null, version),
                MergeUnit.FrameBin(bin, this, null, version)
            )
        }

        companion object {
            fun fromMergeUnits(units: List<MergeUnit>): MergedFragment? {
                if (units.isEmpty()) return null

                val framePart = units.takeWhile { it is MergeUnit.FramePosition }
                        as List<MergeUnit.FramePosition>

                if (framePart.isEmpty()) return null

                val contentPart = units
                    .slice(framePart.size until units.size)
                    .takeWhile { it is MergeUnit.FrameBin }
                        as List<MergeUnit.FrameBin>

                if (contentPart.isEmpty()) {
                    println("Frame: broken merge unit sequence: no content")
                    return null
                }

                val pos = framePart.find { it.version == "our" } ?: framePart.first()
                val content = contentPart.find { it.version == "our" } ?: contentPart.first()

                var frame = pos.fragment as Frame
                frame = frame.copy(bin = content.bin)

                return MergedFragment(
                    frame,
                    framePart + contentPart
                )
            }
        }
    }

    @Serializable
    @SerialName("para")
    data class Paragraph(
        override val elements: List<Fragment> = listOf(),
        override val paragraphStyle: ParagraphStyle? = null,
        override val guid: String = randomId(11),
        override val isVisible: Boolean = true
    ) : Fragment(), Mergable, IParagraph {

        override val plainText by lazy {
            getPlainText(this)
        }

        override val children: Map<String, Fragment> by lazy { elements.map { it.guid to it }.toMap() }

        override fun find(byGuid: String): Fragment? {
            if (guid == byGuid) return this

            var value = children.get(byGuid)
            if (value != null) return value

            val paragraphs = elements.filter { it is IParagraph }
            var i = 0

            while (value == null && i < paragraphs.size) {
                val p = paragraphs[i] as IParagraph
                value = p.find(byGuid)
                i++
            }

            return value
        }

        override fun all(): Sequence<Fragment> = sequence {
            for (element in elements) {
                if( element is Paragraph ) yieldAll(element.all())
                else yield(element)
            }
        }

        override fun nextFragment(from: Fragment): Fragment? {
            val i = elements.indexOf(from)
            if (i < 0) {
                console.error("nextFragment not from us", this, from)
                return null
            }
            // todo: proceed to the next
            if (i + 1 >= elements.size) {
                return null
            }
            return elements[i + 1]
        }

        override fun prevFragment(from: Fragment): Fragment? {
            val i = elements.indexOf(from)
            if (i < 0) {
                console.error("nextFragment not from us", this, from)
                return null
            }
            // todo: proceed to the next
            if (i == 0) return null
            return elements[i - 1]
        }

        @Transient
        var listAddress: List<Int> = emptyList()

        override fun calculateViewProperties(blocks: List<Block>): List<String>? {
            listAddress = emptyList()
            // if it's not list paragraph, return
            if (paragraphStyle?.listStyle == null) return null

            var paragraphs = blocks.map { it.paragraph }
            val isBlock = paragraphs.find { it.guid == guid } != null

            if (!isBlock) {
                // It's not root level paragraph, get neighbours in mutual parent
                val parentBlock = blocks.firstOrNull {
                    it.find(guid) != null
                } ?: return null

                val path = parentBlock.pathToFragment(guid)
                val pathToParent = path.dropLast(1)
                val parent = pathToParent.lastOrNull() ?: return null

                if (parent !is IParagraph) return null
                paragraphs = parent.elements.filter { it is IParagraph } as List<IParagraph>
            }

            val reversed = paragraphs.reversed()
            val level = paragraphStyle.indentLevel ?: 0
            var address = listOf<Int>()
            val currentIndex = reversed.indexOfFirst { it.guid == guid }

//            val searchList = reversed.slice(currentIndex + 1 until reversed.size)

            val beforePart = reversed.slice(currentIndex + 1 until reversed.size).takeWhile { it.paragraphStyle?.listStyle != null }
            val afterPart = reversed.slice(0 until currentIndex).reversed().takeWhile { it.paragraphStyle?.listStyle != null }
//            var currentLevel = level
//
//            while (currentLevel >= 0) {
//                val
//                val previous = beforePart.firstOrNull {
//                    (it.paragraphStyle?.indentLevel ?: 0) == currentLevel
//                            && it.paragraphStyle?.listStyle != null
//                            && it is Paragraph
//                } as Paragraph?
//
//                previous?.let {
//                    val neighbourIndex = it.listAddress.get(currentLevel)
//                    if (currentLevel == level) address.add(neighbourIndex + 1)
//                    else address.add(neighbourIndex)
//                } ?: run {
//                    address.add(0)
//                }
//
//                currentLevel -= 1
//            }

            if (beforePart.size == 0) {
                (0..level).forEach { address += 0 }
//                address = listOf(0)
            }
            else {
                val neighbour = beforePart.first()
                if (neighbour is Paragraph) {
                    val neighbourIndent = neighbour.paragraphStyle?.indentLevel ?: 0
                    if (neighbourIndent == level) {
                        val samePart = neighbour.listAddress.dropLast(1)
                        val num = neighbour.listAddress.last() + 1

                        address = samePart + listOf(num)
                    } else if (neighbourIndent > level) {
                        val realNeighbourAddress = neighbour.listAddress.slice(0..level)
                        val samePart = realNeighbourAddress.dropLast(1)
                        val num = realNeighbourAddress.last() + 1
                        address = samePart + listOf(num)

                        //     2.3.2
                        //       2.3.2.1
                        //     2.3.3
                    } else {
                        //     2.3.2
                        //       2.3.2.1
                        //         2.3.2.1.1
                        address = neighbour.listAddress

                        var diff = (level - neighbourIndent)
                        var i = 0
                        while (i < diff) {
                            address += 0
                            i++
                        }
                    }
                }
            }

            val newValue = address
            val isValueUpdated = listAddress != newValue

            listAddress = newValue

            if (isValueUpdated) {
                if (isBlock) return (beforePart + listOf(this) + afterPart).map { it.guid }
                return listOf(guid)
            } else return null
        }

        override fun caretAtEnd(): Caret {
            val last: StyledSpan = lastTextFragment()
            val path = pathToFragment(last).map { it.guid }

            return Caret(path, last.lastOffset).withParent(this)
        }

        override fun caretAtStart(): Caret {
            val last: StyledSpan = firstTextFragment()
            val path = pathToFragment(last).map { it.guid }

            return Caret(path, 0).withParent(this)
        }

        override operator fun plus(other: Fragment): Paragraph =
            if( other is Paragraph )
                copy(elements = elements + other.elements)
            else
                copy(elements = elements + other)

        override val lastOffset by lazy {
            elements.map { it.lastOffset }.sum()
        }

        override fun equals(other: Any?): Boolean {
            return other is Paragraph && other.guid == guid
                    && other.elements == elements
                    && paragraphStyle == other.paragraphStyle
            // WARNING: NEED TO TEST. Why it was here?
//                    && listAddress == other.listAddress
        }

        override fun hashCode(): Int {
            var result = super.hashCode()
            result = 31 * result + elements.hashCode()
            result = 31 * result + (paragraphStyle?.hashCode() ?: 0)
            result = 31 * result + guid.hashCode()
            return result
        }

        override val isBlank: Boolean by lazy {
//            println("para elements -----------------")
//        for( e in elements) {
//            println("e: ${e::class.simpleName}:${e.isBlank}: $e")
//        }
//            println("---- -------- -----------------")
            elements.all { it.isBlank }
        }

        override fun makeCopy(
            elements: List<Fragment>,
            paragraphStyle: ParagraphStyle?,
            guid: String,
            isVisible: Boolean
        ): Paragraph {
            return this.copy(elements, paragraphStyle, guid, isVisible)
        }

//        override fun toMergeUnits(version: String?): List<MergeUnit> {
//            return listOf(
//                MergeUnit.FramePosition(type, FrameFormat.Position(width, height, isWrapped, align, margin), this, version),
//                MergeUnit.FrameBin(bin, this, version)
//            )
//        }
//
//        companion object {
//            fun fromMergeUnits()
//        }

        override fun toMergeUnits(version: String?): List<MergeUnit> {
            val headPart = MergeUnit.ParagraphHead(guid, paragraphStyle, this, null, version)
            val endPart = MergeUnit.ParagraphEnd(guid, this, null, version)

            val mergeElements = mutableListOf<MergeUnit>()

            elements.forEach {
                if (it is Mergable) mergeElements += it.toMergeUnits(version)
                else throw BugException("Trying to merge not mergable fragment $it")
            }

            return listOf(headPart) + mergeElements + endPart
        }

        companion object {
            fun fromMergeUnits(units: List<MergeUnit>): MergedFragment? {
                if (units.isEmpty()) return null

                var headPart = units.takeWhile { it is MergeUnit.ParagraphHead }
                        as List<MergeUnit.ParagraphHead>

                if (headPart.isEmpty()) return null
                var firstHead = headPart.first()
                headPart = headPart.filter { it.fragment.guid == firstHead.guid }

                val contentPart = units
                    .slice(headPart.size until units.size)
                    .takeWhile { it !is MergeUnit.ParagraphEnd || it.fragment.guid != firstHead.guid }

                val endPart = units[headPart.size + contentPart.size]

                if (contentPart.isEmpty()) {
                    println("Paragraph: broken paragraph merge unit sequence: no child elements")
                    return null
                }

                var style = headPart.first().paragraphStyle
                if (headPart.size == 2) {
                    val second = headPart.last()
                    if (style != null) {
                        if (second.paragraphStyle != null) {
                            val ourStyle = headPart.firstOrNull { it.version == "our" }?.paragraphStyle
                            if (ourStyle != null) {
                                val theirStyle = headPart.firstOrNull { it.version != "our" }?.paragraphStyle
                                style = ourStyle.combineWith(theirStyle!!)
                            } else style = style.combineWith(second.paragraphStyle)
                        }
                    }
                    else style = second.paragraphStyle
                }
                if (headPart.size > 2)
                    throw BugException("Paragraph: there are more than 2 paragraph head parts, merge broken")

                val elements = restoreFragments(contentPart)
                val p = Paragraph(
                    elements, style, headPart.first().guid
                )

                return MergedFragment(
                    p,
                    headPart + contentPart + endPart
                )
            }
        }
    }

    @Serializable
    @SerialName("tpara")
    data class TableParagraph(
        override val elements: List<Fragment> = listOf<Paragraph>(),
        override val paragraphStyle: ParagraphStyle? = null,
        override val guid: String = randomId(11),
        override val isVisible: Boolean = true,
        val rowsCount: Int = 2,
        val colsCount: Int = 2,
        val hasHeader: Boolean = false
    ): Fragment(), IParagraph {
        constructor(
            rowsCount: Int,
            colsCount: Int,
            hasHeader: Boolean
        ) : this(
            elements = (1..rowsCount * colsCount).map { emptyCell() },
            rowsCount = rowsCount,
            colsCount = colsCount,
            hasHeader = hasHeader
        )

        override val plainText by lazy {
            getPlainText(this)
        }

        override val children: Map<String, Fragment> by lazy { elements.map { it.guid to it }.toMap() }

        override fun find(byGuid: String): Fragment? {
            var value = children.get(byGuid)
            if (value != null) return value

            val paragraphs = elements.filter { it is IParagraph }
            var isFound = false
            var i = 0

            while (value == null && i < paragraphs.size) {
                val p = paragraphs[i] as IParagraph
                value = p.find(byGuid)
                i++
            }

            return value
        }

        override fun calculateViewProperties(blocks: List<Block>): List<String>? {
            return null
        }

        override fun all(): Sequence<Fragment> = sequence {
            for (element in elements) {
                if (element is IParagraph) yieldAll(element.all())
                else yield(element)
            }
        }

        override fun nextFragment(from: Fragment): Fragment? {
            return nextTerminalFragment(from)
        }

        override fun prevFragment(from: Fragment): Fragment? {
            return prevTerminalFragment(from)
        }

        override fun caretAtEnd(): Caret {
            val last = lastTextFragment()
            return Caret(pathToFragment(last).map { it.guid }, last.lastOffset).withParent(this)
        }

        override fun caretAtStart(): Caret {
            val last: StyledSpan = firstTextFragment()
            return Caret(pathToFragment(last).map { it.guid }, 0).withParent(this)
        }

        fun getClosestCell(point: Point): IParagraph? {
            val cells = elements as List<IParagraph>
            val yDistances = mutableMapOf<String, Double>()
            cells.map { cell ->
                ParentBlockBox(cell)?.let {
                    yDistances[cell.guid] = it.yDistanceTo(point)
                }
            }
            val closestYDistance = yDistances.minOfOrNull { it.value }
            val closestYCells = cells.filter { yDistances[it.guid] == closestYDistance }
            val xDistances = mutableMapOf<String, Double>()
            closestYCells.map { cell ->
                ParentBlockBox(cell)?.let {
                    xDistances[cell.guid] = it.distanceTo(point)
                }
            }

            val closest = xDistances.minByOrNull { it.value }

            return closest?.let { c ->  cells.find { it.guid == c.key } }
        }

        override fun makeCopy(
            elements: List<Fragment>,
            paragraphStyle: ParagraphStyle?,
            guid: String,
            isVisible: Boolean
        ): TableParagraph {
            return this.copy(elements, paragraphStyle, guid, isVisible, rowsCount, colsCount, hasHeader)
        }

        val rows: List<List<Fragment>> by lazy {
            elements.chunked(colsCount)
        }

        val cols: List<List<Fragment>> by lazy {
            elements.withIndex().groupBy { it.index % colsCount }.map { it -> it.value.map { it.value } }
        }

        fun colsToRows(cols: List<List<Fragment>>): List<List<Fragment>> {
            return (0 until rowsCount).map { i ->
                cols.map { col -> col[i] }
            }
        }

        fun rowsToElements(rows: List<List<Fragment>>): List<Fragment> {
            return rows.flatten()
        }

        fun colsToElements(cols: List<List<Fragment>>): List<Fragment> {
            val rows = colsToRows(cols)
            return rowsToElements(rows)
        }

        fun getRows(): List<List<TableCell>> {
            return rows.mapIndexed { row, fragments ->
                fragments.mapIndexed { col, fragment ->
                    val p = fragment as Paragraph
                    val previous = if (row == 0) null else rows[row - 1][col].guid
                    val next = if (row == rows.size - 1) null else rows[row + 1][col].guid

                    TableCell(row, col, p, p.guid, previous, next)
                }
            }
        }

        fun getCols(): List<List<TableCell>> {
            return cols.mapIndexed { col, fragments ->
                fragments.mapIndexed { row, fragment ->
                    val p = fragment as Paragraph
                    val previous = if (col == 0) null else cols[col - 1][row].guid
                    val next = if (col == cols.size - 1) null else cols[col + 1][row].guid

                    TableCell(row, col, p, p.guid, previous, next)
                }
            }
        }

        // insertAfter 0 - add empty row at first position
        fun addRow(insertAfter: Int, newRow: List<Paragraph>): TableParagraph {
            val before = elements.slice(0 until colsCount * insertAfter)
            val after = elements.slice((colsCount * insertAfter) until elements.size)

            return copy(elements = before + newRow + after, rowsCount = rowsCount + 1)
        }

        fun addEmptyRow(insertAfter: Int): TableParagraph {
            val row = (0 until colsCount).map { emptyCell() }

            return addRow(insertAfter, row)
        }

        fun deleteRow(rowIndex: Int): TableParagraph {
            val before = elements.slice(0 until colsCount * rowIndex)
            val after = elements.slice((colsCount * (rowIndex + 1)) until elements.size)

            return copy(elements = before + after, rowsCount = rowsCount - 1)
        }

        // insertAfter 0 - add empty col at first position
        fun addCol(insertAfter: Int, newCol: List<Fragment>): TableParagraph {
            val before = cols.slice(0 until insertAfter)
            val after = cols.slice(insertAfter until cols.size)

            val resultCols = before + listOf(newCol) + after
            val resultElements = colsToElements(resultCols)

            console.log("ADD COL ${colsCount} > ${colsCount + 1}",)

            return copy(elements = resultElements, colsCount = colsCount + 1)
        }

        fun addEmptyCol(insertAfter: Int): TableParagraph {
            val col = (0 until rowsCount).map { emptyCell() }

            return addCol(insertAfter, col)
        }

        fun deleteCol(colIndex: Int): TableParagraph {
            val before = cols.slice(0 until colIndex)
            val after = cols.slice(colIndex + 1 until cols.size)

            val resultCols = before + after
            val resultElements = colsToElements(resultCols)

            return copy(elements = resultElements, colsCount = colsCount - 1)
        }

        fun getCaretCoords(caret: Caret?): Pair<Int, Int>? {
//            console.log("get caret coords")
            if (caret == null) return null

            val caretPath = caret.path
//            console.log("caret path: ${caretPath}")
            var rowIndex = 0
            var colIndex = 0

            while (rowIndex < rowsCount) {
                val row = rows[rowIndex]
                colIndex = 0
                while (colIndex < colsCount) {
                    val col = row[colIndex]
                    if (caretPath.contains(col.guid)) return Pair(rowIndex, colIndex)
                    colIndex++
                }
                rowIndex++
            }

            return null
        }

        fun getCell(rowIndex: Int, colIndex: Int): Paragraph {
            return rows[rowIndex][colIndex] as Paragraph
        }

        fun caretAtCellEnd(rowIndex: Int, colIndex: Int): Caret {
            val cell = rows[rowIndex][colIndex] as Paragraph
            return cell.caretAtEnd().withParent(this)
        }

        fun caretAtCellEnd(elementIndex: Int): Caret {
            return (elements[elementIndex] as Paragraph).caretAtEnd().withParent(this)
        }

        fun caretToNextCell(c: Caret): Caret? {
            val coords = getCaretCoords(c) ?: return null
            val index = (coords.first * colsCount) + coords.second + 1
            if (index < elements.size) return caretAtCellEnd(index)
            return null
        }

        fun caretToPrevCell(c: Caret): Caret? {
            val coords = getCaretCoords(c) ?: return null
            val index = (coords.first * colsCount) + coords.second - 1
            if (index >= 0) return caretAtCellEnd(index)
            return null
        }

        fun getSlice(alignment: TableAxis, index: Int): List<Fragment> {
            return getAlignment(alignment)[index]
        }

        fun getAlignment(alignment: TableAxis): List<List<Fragment>> {
            if (alignment == TableAxis.ROW) return rows
            return cols
        }

        companion object {
            fun emptyCell(): Paragraph {
                return Paragraph(
                    listOf(
                        Paragraph(listOf(StyledSpan("")))
                    )
                )
            }
        }
    }
}

fun registerFabricators() {
    registerFabricator { Fragment.StyledSpan.fromMergeUnits(it) }
    registerFabricator { Fragment.Frame.fromMergeUnits(it) }
    registerFabricator { Fragment.LinkedImage.fromMergeUnits(it) }
    registerFabricator { Fragment.Paragraph.fromMergeUnits(it) }
}




