import androidx.compose.runtime.*
import controls.Di
import controls.Icon
import controls.Toaster
import controls.WaitMessage
import document.Block
import document.EventManager
import editor.DebugList
import editor.Editor
import editor.RenderDocContent
import editor.RenderMode
import editor.operations.ExportContainer
import editor.operations.applyIncomingChanges
import editor.operations.saveLogger
import editor.operations.setState
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.toList
import kotlinx.coroutines.launch
import net.sergeych.intecowork.LocalEvent
import net.sergeych.intecowork.api.ApiEvent
import net.sergeych.intecowork.doc.IcwkDocument
import net.sergeych.intecowork.tools.Debouncer
import net.sergeych.mp_tools.globalLaunch
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import views.EditHeading
import views.HelpPanel
import views.TextStyleToolbar
import kotlin.time.Duration.Companion.milliseconds
import kotlin.time.Duration.Companion.seconds

val FORCE_SAVE_TIMEOUT = 20.seconds
val PAUSE_SAVE_TIMEOUT = 5.seconds
val SPELLCHECKER_TIMEOUT = 100.milliseconds

@Composable
fun DocumentEditor(dc: Editor, renderMode: RenderMode = RenderMode.EDITOR, document: IcwkDocument? = null) {
    var exportMode by remember { mutableStateOf(RenderMode.READONLY) }

    LaunchedEffect(true) {
        launch {
            dc.exporter.exportMode.collect {
                exportMode = it
            }
        }
    }

    if (renderMode.isInteractive) HelpPanel()
    var showWaitSaving by remember { mutableStateOf(false) }

    if (renderMode.isInteractive) DisposableEffect(document) {
        val h = Router.quitHandler {
            if( dc.eventManager.docIsDirty.value ) {
                showWaitSaving = true
                globalLaunch {
                    dc.forceSave {
                        disconnect()
                        retry()
                        showWaitSaving = false
                    }
                }
                false
            }
            else {
                // could be empty
                globalLaunch {
                    println("checking empty:")
                    if (dc.eventManager.isBlank()) {
                        println("-- doc isEmpty: [${dc.document?.title}")
                        dc.document?.let { d ->
                            if (d.title == null) {
                                println("no title")
                                if (!d.isShared()) {
                                    println("not shared: we will destroy it")
                                    Toaster.launchCatching {
                                        d.erase()
                                        info("Пустой документ удален")
                                    }
                                }
                            }
                        }
                    }
                }
                true
            }
        }
        onDispose {
            h.disconnect()
            if( dc.eventManager.docIsDirty.value ) {
                println("-- document is still not saved - schedule it")
                showWaitSaving = true
                globalLaunch {
                    println("sceduled save after editor closed")
//                    dc.save(document!!)
                    dc.forceSave {
                        showWaitSaving = false
                    }
                }
            }
            dc.spellChecker?.terminate()

        }
    }

    if( showWaitSaving )
        WaitMessage("сохраняю документ")
    else {
        if (renderMode == RenderMode.PRINT) {
            ExportContainer(dc, RenderMode.PRINT)
        } else {
            Div({ classes("container-fluid") }) {
                document?.let { EditHeading(it, dc, renderMode) }

                Di("row", {
                    style {
                        paddingTop(4.8.em)
                    }
                }) {
                    Di("col-1") {
                        // This is a place  for left float menu/doc structure/etc
                    }
                    Di("col") {
                        RenderDocContent(dc.eventManager, dc, renderMode)
                    }
                    Di("col-1") {
                        // This is a place for comments
                    }
                }
            }

            if (renderMode != RenderMode.READONLY) Di("position-fixed bottom-0 end-0 p-2 border d-none d-sm-block", {
                style {
                    property("z-index", 1000)
                    cursor("pointer")
                }

                attr("data-bs-toggle", "offcanvas")
                attr("data-bs-target", "#helpKeys")
            }) {
                Icon.Keyboard.render({ style { fontSize(1.5.em) } })
            }

            if (renderMode.isInteractive) Di("position-fixed", {
                style {
                    display(DisplayStyle.Flex)
                    property("justify-content", "center")
//                    property("left", 0)
                    property("right", 0)
                    property("z-index", 100)
                    property("top", "4.8em")
                    paddingBottom(10.px)
                }
            }) {
                TextStyleToolbar(document, dc.eventManager, dc)
            }

            ExportContainer(dc, exportMode)
//            ExportContainer(dc, RenderMode.DOCX)
        }
    }
}

fun isPrintMode(): Boolean {
    return Router.queryParam("print") == "true"
}

@Composable
fun DocumentPage() {
    val docId = Router.param(0)
    var cloudDocument: IcwkDocument? by remember { mutableStateOf(null) }
    var renderMode by remember { mutableStateOf(RenderMode.EDITOR) }
    val isPrint = isPrintMode()

    LaunchedEffect(docId) {
        if (docId != null) {
            if (renderMode == RenderMode.EDITOR) Browser.init()

            cloudDocument = IcwkDocument.openById(client, docId.toLong())!!
            renderMode = if (cloudDocument?.role?.canWrite == false) RenderMode.READONLY else renderMode
            if (!isPrint) cloudDocument?.events()?.collect {
                when(it) {
                    is ApiEvent.Doc.Erased -> {
                        Router.back()
                        Toaster.info("доступ к документу невозможен")
                    }
                    is ApiEvent.Doc.ShareChanged -> {
                        cloudDocument?.sharedWith()?.firstOrNull { it.user.id == client.currentUser?.id }?.let {
                            println("updating readonly status to $it")
                            renderMode = if (!it.role.canWrite) RenderMode.READONLY else renderMode
                        }
                    }
                    else -> {}
                }
            } ?: run {
                Router.back()
                Toaster.info("доступ к документу невозможен")
            }
        }
    }

    cloudDocument?.let {
        DocumentPage(it, renderMode)
    } ?: WaitMessage("открываю документ")
}

@Composable
fun DocumentPage(doc: IcwkDocument, renderMode: RenderMode = RenderMode.EDITOR) {
    var editor: Editor? by remember { mutableStateOf(null) }
    var hasLimit: Boolean? by remember { mutableStateOf(client.storageState.value?.let { it.available > 0}) }
    val mode = if (isPrintMode()) RenderMode.PRINT else renderMode
    val shouldSave = MutableStateFlow(false)

    LaunchedEffect(doc.docId) {
//        val userBlock = client.
        val bodyBlocks = doc.bodyBlocks().toList().map { it.decode<Block>() }.filterNotNull()
//        val metaBlock = doc.metaBlock
        val eventManager = EventManager()
        eventManager.initializeWithBlocks(bodyBlocks)
        eventManager.calculateViewProperties()
        val ctx = Editor(eventManager, doc)

        if (mode.isInteractive) {
            ctx.eventManager.withLock("dc.setState") {
                ctx.setState(doc.state?.decode())
            }
        }

        editor = ctx

        if (mode.isInteractive) {
            // Watch the dirty state and translate it into clouduc events
            launch {
                ctx.eventManager.docIsDirty.collect {
                    if (DebugList.docEvents) console.log("doc is dirty, send dirty event")
                    if (it) doc.push(LocalEvent.Doc.Dirty(doc.docId))
                }
            }

            val checkers = mutableMapOf<String, Debouncer?>()
            var onPauseSaver: Debouncer? = null
            var forceSaver: Debouncer? = null
            val scope = this

            fun handleChecker(e: EventManager.Event.UpdateBlock) {
//                console.log("handle check for ${e.block.guid}")
                val guid = e.block.guid

                checkers[guid]?.cancel()
//                dc?.spellChecker?.mark(guid)
                checkers[guid] = Debouncer(scope, SPELLCHECKER_TIMEOUT) {
                    Toaster.launchCatching {
                        globalLaunch {
                            editor?.spellChecker?.
                            dc?.spellChecker?.runChecker(listOf(guid))
                        }
                    }
                }
                checkers[guid]?.schedule()
            }

            fun scheduleForceSaver() {
                forceSaver?.cancel()
                forceSaver = Debouncer(this, FORCE_SAVE_TIMEOUT) {
                    saveLogger.log("forced run")
//                    dc?.save(cloudDocument)
                    shouldSave.value = true
                    forceSaver?.schedule()
                }
                forceSaver?.schedule()
            }

            fun schedulePauseSaver() {
                onPauseSaver?.cancel()
                onPauseSaver = Debouncer(this, PAUSE_SAVE_TIMEOUT) {
                    saveLogger.log("on-pause run")
//                    dc?.save(cloudDocument)
                    shouldSave.value = true
                    scheduleForceSaver()
                }
                onPauseSaver?.schedule()
            }

            scheduleForceSaver()

//            dc?.spellChecker?.scheduleAll()

            // REFACTOR
            ctx.eventManager.events.collect {
                if (it !is EventManager.Event.RedrawBlock) {
                    schedulePauseSaver()

                    // REFACTOR
                    if (it is EventManager.Event.UpdateBlock) handleChecker(it)
                }
            }
        }
    }

    LaunchedEffect(doc.docId) {
        shouldSave.collect { value ->
            if (shouldSave.value) editor?.save { shouldSave.value = false }
        }
    }

    if (mode.isInteractive) LaunchedEffect(doc.docId) {
        doc.events().collect {
            when(it) {
                is ApiEvent.Doc.BodyChanged -> {
                    if (it.lastSerial > doc.lastSerial) {
                        Toaster.launchCatching {
                            editor?.applyIncomingChanges(doc)
                            editor?.titleManager?.update()
                        }
                    }
                }
                is ApiEvent.Doc.MetaChanged -> {
                    editor?.titleManager?.update()
                }
                else -> {
                    if (DebugList.docEvents) console.log("Received ApiEvent.Doc", it.toString())
                }
            }
        }
    }

    if (mode.isInteractive) LaunchedEffect(doc.docId) {
        client.storageState.collect {
            hasLimit = it?.let { it.available > 0 }
        }
    }

    // Copy current values: sources can be changed outside
    val documentContext = editor
    val limitOk = if (mode.isInteractive) hasLimit else true

    if( documentContext == null || limitOk == null ) {
        WaitMessage("загружаю документ")
    }
    else {
        if (limitOk) {
            DocumentEditor(documentContext, mode, doc)
        }
        else {
            DocumentEditor(documentContext, RenderMode.READONLY, doc)
            Toaster.warning("лимит хранения исчерпан, изменения невозможны")
        }
    }
}