@file:Suppress("unused")

package net.sergeych.intecowork.api

import kotlinx.serialization.Serializable
import net.sergeych.intecowork.IcwkClient
import net.sergeych.parsec3.CommandHost
import net.sergeych.parsec3.WithAdapter
import net.sergeych.tools.Opt
import net.sergeych.unikrypto.PublicKey

//
@Serializable
data class VersionInfo(
    val version: String,
    val service: String,
    val status: String,
)

@Serializable
class SetPublicKeyPayload(
    val publicKey: PublicKey,
    val encryptedPrivateKey: ByteArray,
)

/**
 * Important notes:
 *
 * - use either [sinceSerial] or [sinceId], or leave it to default.
 * - when [sinceSerial] is set, the order of blocks is by serial (smaller first, e.g. least
 *      recently changed first), otherwise order is by id (e.g. in order of creation)
 */
@Serializable
class ApiGetBlocksArgs(
    val docId: Long?=null,
    val type: BlockType? = null,
    val utag: String? = null,
    val tag: String? = null,
    val prev: String? = null,
    val next: String? = null,
    val limit: Int = 100,
    val offset: Int = 0,
    val accessHandle: String? = null,
    val newerThanSerial: Long? = null,
    val sinceId: Long? = null,
    val sinceSerial: Long? = null,
)

/**
 * User block: a version single-block user data storage identified by unique per user [utag].
 * User blocks have an isolated [utag] name space, different from other block types,
 * it is possible to have the same utag for document block and user block safely.
 * Use [IcwkClient.userBlockCreate], [IcwkClient.userBlockUpdate],
 * [IcwkClient.userBlockGet], and [IcwkClient.userBlockDelete]
 * to manipulate user blocks.
 *
 * @param utag unique per block tag
 * @param data any user data
 * @param serial assigned by the server version code. Every [IcwkClient.userBlockUpdate]
 *      increases it to some arbitrary positive number.
 */
@Serializable
class ApiUserBlock(
    val utag: String,
    val data: ByteArray,
    val serial: Long = -1,
) {
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other !is ApiUserBlock) return false

        if (utag != other.utag) return false
        if (!data.contentEquals(other.data)) return false
        if (serial != other.serial) return false

        return true
    }

    override fun hashCode(): Int {
        var result = utag.hashCode()
        result = 31 * result + data.contentHashCode()
        result = 31 * result + serial.hashCode()
        return result
    }

    /**
     * Create a copy with updated data. This is important as [serial] must be kept
     * for [IcwkClient.userBlockUpdate] to allow modifications. See it for more info
     * on user block versioning
     */
    fun withData(newData: ByteArray): ApiUserBlock = ApiUserBlock(utag,newData,serial)
}

@Serializable
data class ApiDocMoveArgs(val docIds: List<Long>, val toDocId: Long?)

class ServerApi<T : WithAdapter> : CommandHost<T>() {
    val version by command<Unit, VersionInfo>()

    // Login extras (most is handled by superlogin library)
    val isLoginNameAvailable by command<String, Boolean>()
    val getAvailableNick by command<String, String>()

    // Public key processing
    val getMyPublicKey by command<Unit, PublicKey>()
    val setMyPublicKey by command<ByteArray, Unit>()
    val getPublicKey by command<Long, PublicKey>()
    val getMyMainKey by command<Unit, ByteArray>()

    // USe unique blocks
    val userGetBlock by command<String,ApiUserBlock?>()
    val userCreateBlock by command<ApiUserBlock,ApiUserBlock?>()
    val userUpdateBlock by command<ApiUserBlock,ApiUserBlock?>()
    val userDeleteBlock by command<String,Unit>()

    // documents
    val docCreate by command<ApiCreateDocArgs, ApiDocAccess>()
    val docGetAccess by command<Long, ApiDocAccess>()
    val docGetBlocks by command<ApiGetBlocksArgs, List<ApiBlock>>()
    val docOpenByHandle by command<String,Opt<ApiDocAccess>>()
    val docGetBody by command<ApiGetDocBodyArgs, List<ApiBlock>>()
    val docUpdateBlocks by command<UpdateBlocksArgs,ApiDocUpdateResult>()
    val docGetLastSerial by command<Long,Long>()
    val docEnumerate by command<ApiEnumerateDocsArgs,List<ApiDocHeader>>()
    val docGetHeader by command<Long,ApiDocHeader?>()
    val docDelete by command<Long, Unit>()
    val docUndelete by command<Long, Unit>()
    val docErase by command<Long, Unit>()
    val docDeleteTest by command<Long, Unit>()
    val docGetPublicHandle by command<Long,Opt<ApiPublicHandle>>()
    val docCreatePublicHandle by command<ApiCreateHandleArgs,ApiPublicHandle>()
    val docRemovePublicHandle by command<Long,Unit>()

    val docMoveTo by command<ApiDocMoveArgs,List<ApiDoc>>()

    /**
     * Create or update existing share
     */
    val docUpdateShare by command<ApiDocUpdateShareArgs,ApiMember>()

    /**
     * Delete existing share
     */
    val docDeleteShare by command<ApiDocDeleteShareArgs,Unit>()

    /**
     * List existing shares
     */
    val docGetShares by command<Long,List<ApiMember>>()

    /**
     * fast check doc has at least one share
     */
    val docIsShared by command<Long,Boolean>()

    // Service codes
    val activateSecretCode by command<String,ApiCodeActivationResult>()
    val getCodeInfo by command<Long,ApiCodeActivation?>()
    val getCodes by command<Boolean,List<ApiCodeActivation>>()

    // Set registration or like code prior to the privileged operation
    // this operation does not check the code at all
    val setSecretCode by command<String,Unit>()

    // ----------- Agreements ---------------
    val acptList by command<Unit,List<ApiAcceptance>>()
    val acptUpdate by command<String,UpdateAcceptanceArgs>()
    val acptGetHtml by command<String,String>()

    // ----------- User management ----------
    val userGetStorage by command<Unit,ApiStorage>()
    val userSetName by command<String,ApiUserDetails>()

    // ----------- User lis commands --------
    val userFind by command<UserFindArgs,List<ApiUser>>()
    val userGet by command<Long,ApiUser?>()
//    val contactFind by command<String,List<ApiContact>>()
//    val deleteContact by command<ApiContact,Unit>()
//    val updateContact by command<ApiContact,ApiContact>()
//    val blockedContacts by command<Unit,List<ApiContact>>()
//    val createContact by command<ApiUser,ApiContact>()

    // ----------- debug tools --------------
    val deleteTestUser by command<String, Unit>()

    val deleteAccount by command<Unit, Unit>()
}

val IcwkApi = ServerApi<WithAdapter>()

class ClientApi<T : WithAdapter> : CommandHost<T>() {

    val push by command<ApiEvent,Unit>()
}

val CLientApi = ClientApi<WithAdapter>()

