package editor.operations

import document.*
import editor.*
import editor.plugins.SpellCheckerDocumentState
import editor.plugins.TitleManager
import kotlinx.coroutines.CompletableDeferred
import kotlinx.coroutines.flow.toList
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import net.sergeych.boss_serialization_mp.BossEncoder
import net.sergeych.intecowork.api.ApiDocUpdateResult
import net.sergeych.intecowork.api.BlockType
import net.sergeych.intecowork.api.DocState
import net.sergeych.intecowork.api.ModificationFailedException
import net.sergeych.intecowork.doc.DocBlock
import net.sergeych.intecowork.doc.IcwkDocument

val saveLogger = PackageLogger("save", "Indigo", DebugList.save)

fun Editor.getState(): EditorState {
    val scState = spellChecker?.getDocumentState() ?: lastState?.spellchecker

    return EditorState(
        caret?.position(),
        spellchecker = scState
    )
}

enum class SaveState {
    INIT,
    SAVE,
    FINISHED,
    RESAVE,
    INCOMING,
    IDLE
}

class SaveManager(
    val eventManager: EventManager,
    val getChain: () -> DocChain,
    val icwkDoc: IcwkDocument? = null,
    val titleManager: TitleManager? = null,
    val onFinish: suspend (changesToApply: AUDTransaction<Block>) -> Unit
    ) {
    var iteration = 0
    var state = SaveState.IDLE
    var snapshot = DocChain.empty()
    var previousSnapshot = DocChain.empty()
    var sourceSnapshot = DocChain.empty()
    var lastSavedChain = DocChain.empty()
    var editorChainUpdate: AUDTransaction<Block>? = null
    var shouldProcessIncoming = false
    var currentCycle: CompletableDeferred<Boolean>? = null

    fun finish() {
        iteration = 0
        editorChainUpdate = null
        previousSnapshot = DocChain.empty()
        state = SaveState.IDLE
    }

    suspend fun sourceToMergedRemote(
        source: List<Block>,
        localChanges: AUDTransaction<Block>
    ): AUDTransaction<Block> {
        val d = icwkDoc

        if (d == null) return localChanges
        else return d.merge { docBlockFlow ->
            val theirBlocks = docBlockFlow.toList()
                .filter { it.type == BlockType.Body }
                .mapNotNull { it.decode<Block>() }

            if (theirBlocks.isNotEmpty()) {
                val transaction = getSourceToMerged(source, theirBlocks, localChanges)

                return@merge transaction
            } else {
                return@merge localChanges
            }
        }
    }

    suspend fun checkSnapshot() {
        iteration += 1

        saveLogger.log("run snapshot check #$iteration")

        eventManager.withLock("save: check chain snapshot") {
            val editorChain = getChain()
            snapshot = editorChain.copy("snapshot #$iteration")
            val snapshotChanges = snapshot.getAUDTransaction()
            val hasNewChanges = previousSnapshot.isEmpty() || previousSnapshot.getAUDTransaction() != snapshotChanges

            if (state != SaveState.INCOMING) {
                if (state == SaveState.INIT) {
                    if (snapshotChanges.isEmpty()) state = SaveState.FINISHED
                    else if (!hasNewChanges) state = SaveState.SAVE
                    else { /* try again */
                    }
                } else {
                    if (hasNewChanges) state = SaveState.RESAVE
                    else {
                        saveLogger.log("Apply results to editor")
                        editorChainUpdate?.let { changes ->
                            onFinish(changes)
                        }
                        // everything is ok, apply remote merge transaction
                        state = SaveState.FINISHED
                    }
                }
            } else {
                state = SaveState.SAVE
            }
        }
    }

    suspend fun saveChanges(
        source: List<Block>,
        localChanges: AUDTransaction<Block>,
        attempt: Int = 1
    ): AUDTransaction<Block> {
        val logger = AlgoLogger(saveLogger, "write attempt #$attempt", "purple")
        var shouldRunAgain = true
        var changes = localChanges

        try {
            logger.log("begin")

            titleManager?.updateTitle()
            icwkDoc.let { document ->
                localChanges.printCase("localChanges")
                changes = sourceToMergedRemote(source, localChanges)
                changes.printCase("mergedChanges")
//                document.save(changes)
                if (!changes.isEmpty()) {
                    if (!localChanges.isEmpty()) document?.save(changes)
                }

                shouldRunAgain = false
//                dc.saveState(document)
                logger.log("save attempt #$attempt succeeded")
            }
        } catch(e: ModificationFailedException) {
            if (e.result !is ApiDocUpdateResult.MergeRequired) {
                logger.error("MODIFY FAILED")
                logger.error(e)

                throw BugException("Save error")
            } else {
                logger.log("Merge required, trying again..")
            }
        } catch(e: Throwable) {
            if (e !is ModificationFailedException) {
                logger.error("SAVE FAILED")
                logger.error(e)
            }
        }

        logger.log("done")

        return if (!shouldRunAgain) changes
        else saveChanges(source, localChanges, attempt + 1)
    }

    suspend fun processInit() {
        val logger = AlgoLogger(saveLogger, "init")
//        logger.log("begin")

//        logger.log("done")
    }

    suspend fun processSave() {
        val logger = AlgoLogger(saveLogger, "save")
        logger.log("begin")

        val localChanges = snapshot.getAUDTransaction()
        localChanges.printCase("localChanges")

        val savedChanges = saveChanges(snapshot.initialElements, localChanges)
        savedChanges.printCase("savedChanges")

        editorChainUpdate = savedChanges - localChanges
        editorChainUpdate?.printCase("editorChainUpdate")

        val savedChain = snapshot.copyInitial()
        savedChain.applyAUDTransaction(savedChanges)

        lastSavedChain = savedChain

        sourceSnapshot = snapshot.copy()

        logger.log("done")
    }

    suspend fun processResave() {
        val logger = AlgoLogger(saveLogger, "resave")
        logger.log("begin")

        val localChanges = snapshot.getAUDTransaction()
        localChanges.printCase("localChanges")

        val sourceSnapshotToMerged = merge(
            sourceSnapshot.elements,
            snapshot.elements,
            lastSavedChain.elements,
            snapshot.caret
        )

        sourceSnapshot.printCase("sourceSnapshot")
        snapshot.printCase("snapshot")
        lastSavedChain.printCase("lastSavedChain")
        sourceSnapshotToMerged.printCase("sourceSnapshotToMerged")

        val lastSavedChanges = lastSavedChain.getAUDTransaction()
        lastSavedChanges.printCase("lastSavedChanges")
        val changesToSave = sourceSnapshotToMerged - lastSavedChanges
        changesToSave.printCase("changesToSave")

        val savedChanges = saveChanges(lastSavedChain.elements, changesToSave)
        savedChanges.printCase("savedChanges")

        lastSavedChain.applyAUDTransaction(savedChanges) // actualize last saved chain
        sourceSnapshot = snapshot.copy()

        val sourceToSaved = lastSavedChanges + savedChanges
        editorChainUpdate = sourceToSaved - localChanges
        editorChainUpdate?.printCase("editorChainUpdate")

        logger.log("done")
    }

    suspend fun processFinished() {
        eventManager.withLock("save finished") {
            finish()

            if (shouldProcessIncoming) {
                saveLogger.log("process incoming")
                shouldProcessIncoming = false
                state = SaveState.INCOMING
            }
        }
    }

    suspend fun runProcessor() {
        while (state != SaveState.IDLE) {
//            saveLogger.log("state = ${state} wait 1 sec before snapshot check #$iteration")
//            delay(1000)
            previousSnapshot = snapshot
            checkSnapshot()

            when(state) {
                SaveState.INIT -> processInit()
                SaveState.SAVE -> processSave()
                SaveState.RESAVE -> processResave()
                SaveState.FINISHED -> processFinished()
                SaveState.IDLE -> { /* do nothing */ }
                SaveState.INCOMING -> { /* do nothing */ }
            }
        }
    }

    suspend fun save(onDone: suspend () -> Unit) {
        if (state != SaveState.IDLE) {
            saveLogger.log("Nothing to save, exit")
            return onDone()
        }

        currentCycle = CompletableDeferred<Boolean>()
        state = SaveState.INIT

        runProcessor()

        saveLogger.success("done")
        currentCycle?.complete(true)
        onDone()
    }

    suspend fun forceSave(onDone: suspend () -> Unit) {
        currentCycle?.await()

        save(onDone)
    }
}

suspend fun IcwkDocument.save(changes: AUDTransaction<Block>) {
    val bodyBlocks = bodyBlocks().toList()

    eventManagerLogger.log("save begin")

    bodyBlocks.forEach {
        eventManagerLogger.log("icwk before: ${it.debugSimplified()}")
    }

    modify {
        eventManagerLogger.log("modify begin")
        changes.add.forEach {
            eventManagerLogger.log("add ${it.debugSimplified()}")

            put(
                DocBlock.pack(
                BlockType.Body,
                prev = it.prevBlockGuid,
                next = it.nextBlockGuid,
                guid = it.guid,
                docId = docId
            ) { it })
        }
        changes.delete.forEach {
            eventManagerLogger.log("delete ${it.debugSimplified()}")

            remove(it.guid)
        }

        changes.update.forEach { b ->
            val block = b

            val docBlock = bodyBlocks.find { it.guid == block.guid }
                ?: throw BugException("SAVE: trying to update block, that not existed")

            eventManagerLogger.log("update ${block.debugSimplified()}")

            put(
                DocBlock.pack(
                docBlock.type,
                docBlock.utag,
                docBlock.tag,
                prev = block.prevBlockGuid,
                next = block.nextBlockGuid,
                serial = docBlock.serial,
                guid = b.guid,
                docId = docBlock.docId
            ) { block })
        }

        eventManagerLogger.success("modify done")
    }

    eventManagerLogger.success("save done")
}

suspend fun Editor.saveState(cd: IcwkDocument? = document) {
    if (cd == null) return

    val actualState = getState()

    if (lastState != actualState) {
        cd.saveState(DocState.pack(actualState))

        lastState = actualState

        if (lastMove.position != actualState.caretPosition)
            lastMove = lastMove.copy(position = actualState.caretPosition)
    }
}

fun Editor.getInitialCaret(pos: Position?): Caret? {
    if (pos == null) return caretToHome()

    val cBlock = eventManager.allBlocks.find { it.guid == pos.blockId } ?: return caretToHome()

    val cSpan = cBlock.find(pos.fragmentId) ?: return cBlock.caretAtStart()

    val offset = if (pos.offset > cSpan.lastOffset) cSpan.lastOffset else pos.offset

    return cBlock.caretAt(cSpan.guid, offset)
}

fun ensureSC(exclude: Set<String>?): ByteArray {
    val spellcheckerState = SpellCheckerDocumentState.V1(exclude ?: emptySet())
    val encoded = BossEncoder.encode(spellcheckerState)

    return encoded
}

@Serializable
@SerialName("document.EditorState")
data class EditorState(
    val caretPosition: Position?,
    val isSpellCheckOn: Boolean? = false, // deprecated
    val spellCheckExclude: Set<String>? = setOf(), // deprecated
    val spellchecker: ByteArray? = ensureSC(spellCheckExclude),
)

suspend fun Editor.setState(state: EditorState?) {
    val actual = state
    lastState = actual
    val initialCaret = getInitialCaret(actual?.caretPosition)

    lastMove = lastMove.copy(position = initialCaret?.position())
    caret = initialCaret
    editorChain = DocChain(eventManager.allBlocksCopy, initialCaret, name="editorChain")

    // TODO: load and set stylesheet
    // stylesheet = Stylesheet()

    replay.init(eventManager.allBlocksCopy, initialCaret)

    spellChecker?.setDocState(actual?.spellchecker)

    titleManager.update()

    runMigrations()
}


