package tools

import androidx.compose.runtime.Composable
import controls.classNames
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Img
import org.w3c.dom.HTMLImageElement

@Composable
fun ThemedSvg(imageBaseName: String, classes: String? = null, attrs: AttrBuilderContext<HTMLImageElement>? = null) {
    Img(
        if (UITheme.composeState.value.isDark) "$imageBaseName-dark.svg" else "$imageBaseName-light.svg",
        attrs ={
            attrs?.invoke(this)
            classes?.let { classNames(it) }
        }
    )
}
