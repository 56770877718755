package views

import androidx.compose.runtime.Composable
import controls.Di
import controls.RouterLink
import controls.Template
import org.jetbrains.compose.web.dom.*
import tools.Mascot

@Composable
fun ErrorDocNotFound() {
    Template {
        ComfortableWidth {

            Di("text-center mt-5 mb3") {
                Mascot("error")
            }
            H1 { +"Документ недоступен!" }

            Di("my-3") {
                +"Документ, который вы хотите открыть, недоступен. Это может иметь множество причин, например:"
            }

            Ul {
                Li { +"У вас недостаточно прав, чтобы его открыть." }
                Li { +"Документ был удален." }
                Li { +"Неверная ссылка." }
            }
            Hr {}
            RouterLink("/", "Вернуться на главную страницу")
        }
    }
}