package net.sergeych.intecowork.doc

import kotlinx.coroutines.flow.*
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import net.sergeych.intecowork.IcwkClient
import net.sergeych.intecowork.LocalEvent
import net.sergeych.intecowork.api.*
import net.sergeych.intecowork.tools.CachedNullableExpression
import net.sergeych.intecowork.tools.decodeBase64Url
import net.sergeych.intecowork.tools.smartEmit
import net.sergeych.mp_logger.*
import net.sergeych.mptools.CachedExpression
import net.sergeych.superlogin.BackgroundKeyGenerator
import net.sergeych.unikrypto.Container
import net.sergeych.unikrypto.SymmetricKey
import net.sergeych.unikrypto.SymmetricKeys
import net.sergeych.unikrypto.randomId

/**
 * The cloud document connector for the Intecowork project.
 *
 */
class IcwkDocument private constructor(
    val client: IcwkClient,
    val docKey: SymmetricKey,
    private val docAccess: ApiDocAccess,
    private val accessHandle: String? = null,
) {

    val docId = docAccess.doc.id


    /**
     * Events that occurs to the document on the cloud side. Could happen asynchronously
     * by push events or from the synchronous calls to some methods.
     */

    private var apiDoc: ApiDoc
        get() = docAccess.doc
        set(value) {
            docAccess.doc = value
        }

    var isDeleted = false
        private set

    var lastSerial = docAccess.doc.lastSerial
        private set

    val lastCloudSize: Long get() = apiDoc.size

    var state: DocState? = docAccess.stateBlock?.decodeOrNull<DocState>(docKey)
        private set

    var stateGuid = docAccess.stateBlock?.guid ?: randomId(18)
        private set

    private suspend fun reloadMeta() {
        metaBlock = getBlocks(BlockType.Meta).toList().first().block!!
    }

    fun events(): Flow<ApiEvent.Doc> = flow {
        client.events.collect {
            if (it is ApiEvent.Doc && it.docId == docId) {
                when (it) {
                    is ApiEvent.Doc.MetaChanged -> reloadMeta()
                    is ApiEvent.Doc.ShareChanged -> {
                        println("share changed for $docId")
                        cachedCollaborators.clearCache()
                        cachedPublicShare.clearCache()
                    }

                    else -> {}
                }
                emit(it)
            }
        }
    }

//    /**
//     * Could be used to track document "busy" state
//     */
//    val readyState = _readyState.asStateFlow()


    var metaBlock = docAccess.metaBlock?.let {
        DocBlock(it, it.decrypt(docKey))
    } ?: throw IcwkException("нет метаблока")
        private set(value) {
            if (!(field.plainData contentEquals value.plainData))
                meta = value.decode<ApiDocMeta>()!!
            field = value
        }

    val updatedAt: Instant get() = apiDoc.updatedAt
    val trashedAt: Instant? get() = apiDoc.trashedAt

    val isTrashed: Boolean get() = apiDoc.trashedAt != null

    var meta: ApiDocMeta = metaBlock.decode()!!
        private set

    val title: String? get() = meta.title

    var role = docAccess.role
        private set

    val type by lazy { docAccess.doc.type }

    val parentId by lazy { docAccess.doc.parentId }

    /**
     * Perform modifications and return result, not throwing errors. See [modify] for more.
     */
    suspend fun tryModification(modifier: DocModificationContext.() -> Unit): ApiDocUpdateResult {
        push(LocalEvent.Doc.Saving(docId))
        val r = DocModificationContext(this).apply(modifier).commit()
        if (r is ApiDocUpdateResult.Success) {
            lastSerial = r.modifications.maxOf { it.serial }
            push(LocalEvent.Doc.Saved(docId))
        } else
            push(LocalEvent.Doc.SaveFailed(docId))
        return r
    }

    @Suppress("UNCHECKED_CAST")
    fun localEvents(): Flow<LocalEvent.Doc> =
        client.localEvents.filter { it is LocalEvent.Doc && it.docId == docId } as Flow<LocalEvent.Doc>

    /**
     * Try to modification of the cloud version of this document and return the list of
     * changed block revisions. See also [tryModification] for non-throwing version.
     *
     * Caller-supplied [modifier] collects all the changes that should be atomically applied,
     * then changes are encrypted and transimtted as a transaction to the cloud. The cloud
     * attempts to apply changes in the tranaction, and either apply all of them or reject
     * with a proper error code.
     *
     * Normally, client software should merge the document before trying to modify it.
     *
     * @throws ModificationFailedException if modification could not be performed
     */
    suspend fun modify(modifier: DocModificationContext.() -> Unit): List<ApiBlockSerial> {
        val r = tryModification(modifier)
        if (r !is ApiDocUpdateResult.Success)
            throw ModificationFailedException(r)
        return r.modifications
    }

    /**
     * Updates local viewer/editor state per document.
     * @return true if the state is saved on the server, false on some error.
     */
    suspend fun saveState(newState: DocState): Boolean {
        val r = tryModification {
            put(DocBlock.pack(BlockType.State, guid = stateGuid) { newState })
        }
        return if (r is ApiDocUpdateResult.Success) {
            state = newState
            true
        } else {
            warning { "не удалось сохранить состояние редактора: $r" }
            false
        }
    }

    suspend fun saveMeta(newMeta: ApiDocMeta): Boolean {
        val r = tryModification {
            put(metaBlock.withPayload(newMeta))
        }
        return if (r is ApiDocUpdateResult.Success) {
            meta = newMeta
            true
        } else {
            warning { "не удалось сохранить метаданные: $r" }
            false
        }
    }

    suspend fun setTitle(newTitle: String): Boolean =
        saveMeta(meta.copy(title = newTitle))

    data class BlockResult(val block: DocBlock?, val serial: Long)

    fun getBlocks(
        type: BlockType? = null,
        utag: String? = null,
        tag: String? = null,
        prev: String? = null,
        next: String? = null,
        sinceSerial: Long? = null,
        chunkSize: Int = 50,
    ): Flow<BlockResult> = client.getBlocks(
        docId, type, utag, tag, prev, next, sinceSerial, chunkSize, accessHandle = accessHandle
    ).map { block ->
        BlockResult(block.decryptOrNull(docKey)?.let { DocBlock(block, it) }, block.serial)
    }

    /**
     * Retrieve document [BlockType.Body] blocks _in a correct order_ since a given guid.
     * Cancel collector to stop retrieving.
     * @param fromGuid block to start with, defaults to null meaning the first block
     * @param chunkSize how many blocks to download at a time. left it to the default.
     *
     */
    fun bodyBlocks(fromGuid: String? = null, chunkSize: Int = 50): Flow<DocBlock> {
        if (chunkSize < 1)
            throw IllegalArgumentException("chunkSize должен быть >= 1")
        var g = fromGuid
        return flow {
            do {
                val part = client.call(
                    IcwkApi.docGetBody,
                    ApiGetDocBodyArgs(
                        docId, fromGuid = g, count = chunkSize,
                        accessHandle = accessHandle
                    )
                )
                if (part.isEmpty()) break
                for (b in part) emit(DocBlock(b, b.decryptOrNull(docKey)))
                g = part.last().nextGuid
            } while (g != null)
        }
    }

    /**
     * Get the cloud's last serial. As the document in the cloud could be updated
     * asynchronously, this methods call the cloud to get the serial of most recently changed
     * block in the document. If it is bigger than our []
     */
    suspend fun requestLastSerial(): Long =
        client.call(IcwkApi.docGetLastSerial, docId).also {
            if (it > lastSerial) client._events.emit(ApiEvent.Doc.BodyChanged(docId, it))
        }

    /**
     * Checks the cloud state of the document (see [requestLastSerial]) and
     * returns true if the cloud document was updated since opening/last merge.
     */
    suspend fun isMergeRequired(): Boolean = requestLastSerial() > lastSerial

    /**
     * Merge cloud changes. The argument callback receives a flow of changed blocks,
     * which it should collect as much as possible, and update local data as needed.
     *
     * It is allowed to collect only some blocks, it will not be an errorm but the document
     * merge flag [isMergeRequired] could still be true then.
     *
     * __Every collected block from the flow is considered merged if the callback returns
     * withut exceptions__. If exeption is thrown, merge state of the document is not
     * changed whatever blocks were collected.
     *
     * @param f merging callback
     * @return whatever [f] returns
     */
    suspend fun <R> merge(f: suspend (Flow<DocBlock>) -> R): R {
        var lastProcessed: Long? = null
        return f(flow {
            getBlocks(sinceSerial = lastSerial).collect {
                it.block?.let { emit(it) }
                lastProcessed = it.serial
                if (it.block?.type == BlockType.Meta) metaBlock = it.block
            }
        }).also {
            lastProcessed?.let {
                info { "обработчик слияний отработал до сериника ${it}" }
                lastSerial = it
            } ?: warning { "обработчик слияний не выбрал ни одного блока" }
        }
    }

    /**
     * Accept all changes from could without processing it (very dangerous). Usually
     * only for test.
     */
    suspend fun mergeAll() {
        merge { it.collect() }
    }

    suspend fun moveTo(newParentId: Long?) {
        apiDoc = client.call(IcwkApi.docMoveTo, ApiDocMoveArgs(listOf(docId), newParentId)).first()
    }

    suspend fun moveToTrash() {
        if (apiDoc.trashedAt != null)
            throw IllegalArgumentException("попытка повторного удаления")
        client.call(IcwkApi.docDelete, docId)
        val now = Clock.System.now()
        // todo: get state from the server
        apiDoc = apiDoc.copy(trashedAt = now, updatedAt = now)
    }

    suspend fun restoreFromTrash() {
        if (apiDoc.trashedAt == null)
            throw IllegalArgumentException("попытка повторного восстановления")
        client.call(IcwkApi.docUndelete, docId)
        apiDoc = apiDoc.copy(trashedAt = null, updatedAt = Clock.System.now())
    }

    suspend fun erase() {
        client.call(IcwkApi.docErase, docId)
        isDeleted = true
    }

    private val cachedPublicShare = CachedNullableExpression<String?>()
    private val cachedCollaborators = CachedExpression<List<ApiMember>>()

    /**
     * Get a public share: a string that allows anybody who has it to read the document. Note
     * that the server can't read it, this string is generated in the client app.
     *
     * Secret share is a URL without host and protocol which should be added by the application, like "751TRGEF#fdkjhlk"
     * which can be used to read document by anybody who has it. Normally you prepend a required domain to it
     * before usage. The link could not be read by the browser, it depends special processing by this library.
     */
    suspend fun getPublicShare(): String? =
        cachedPublicShare.get {
            client.call(IcwkApi.docGetPublicHandle, docId).value?.publicShare(docKey)
        }

    /**
     * Collaborators can read. write, view of comment the document. Includes document owner.
     * See [sharedWith].
     */
    suspend fun collaborators(): List<ApiMember> =
        cachedCollaborators.get {
            client.call(IcwkApi.docGetShares, docId)
        }

    /**
     * collaborators not including the owner.
     */
    suspend fun sharedWith(): List<ApiMember> = collaborators().filter { !it.role.isOwner }

    /**
     * Create or update collaboration allowing/restricting [user] to a [role]. Note that
     * a role should be allowing some type of the access and can't be [DocRole.Owner], e.g.
     * the following roles are good:
     * - [DocRole.Writer]
     * - [DocRole.Reader]
     * - [DocRole.Commenter]
     *
     * To completely remove user access to this document use [deleteCollaboration].
     *
     * Adding roles are always allowed to owner and usually is allowed to everybody
     * with write access.
     *
     * Changing ownership will be implemented as a separate call.
     */
    suspend fun updateCollaboration(user: ApiUser, role: DocRole): ApiMember =
        client.call(
            IcwkApi.docUpdateShare, ApiDocUpdateShareArgs(
                docId,
                user.id, role,
                Container.encrypt(docKey, client.call(IcwkApi.getPublicKey, user.id))
            )
        ).also { cachedCollaborators.clearCache() }

    /**
     * Update membership, same as [updateCollaboration] but using [ApiMember] instance.
     */
    suspend fun updateMember(member: ApiMember): ApiMember {
        if (member.docId != docId) throw IllegalArgumentException("участник не принадлежит к документу")
        return updateCollaboration(member.user, member.role)
    }

    /**
     * Delete collaboration (Remove [user] from collaborators). Does nothing if the user
     * has no access to this document.
     */
    suspend fun deleteCollaboration(user: ApiUser) {
        client.call(IcwkApi.docDeleteShare, ApiDocDeleteShareArgs(docId, user.id))
        cachedCollaborators.clearCache()
    }

    /**
     * Create a public share (URL with no host/protocol), or return existing one. See
     * [getPublicShare] for details.
     */
    suspend fun createPublicShare(): String {
        cachedPublicShare.clearCache()
        return client.call(
            IcwkApi.docCreatePublicHandle, ApiCreateHandleArgs(
                docId,
                ApiPublicHandle.createRandomContainer(docKey)
            )
        ).publicShare(docKey) ?: throw InternalError("ручка документа не расшифровывается его ключом")
    }

    suspend fun deletePublicShare() {
        client.call(IcwkApi.docRemovePublicHandle, docId)
        cachedPublicShare.clearCache()
    }

    /**
     * Check the document has at least share that allow seeing its content (e.g. public
     * or collaboration)
     */
    suspend fun isShared(): Boolean =
        getPublicShare() != null || collaborators().size > 1


    fun push(event: LocalEvent.Doc) {
        client.localEvents.smartEmit(event)
    }

    companion object : LogTag("CLDOC") {

        suspend fun create(
            client: IcwkClient, docType: DocType = DocType.Text,
            parendId: Long?,
            docState: DocState = DocState(),
            vararg otherBlocks: DocBlock,
        ): IcwkDocument {
            val docKey = BackgroundKeyGenerator.randomSymmetricKey()
            val metaBlock = ApiBlock.encrypt(BlockType.Meta, docKey, ApiDocMeta())
            val stateBlock = ApiBlock.encrypt(BlockType.State, docKey, docState)
            val container = Container.encrypt(docKey, client.dataKey)
            val access = client.call(
                IcwkApi.docCreate, ApiCreateDocArgs(
                    docType, container,parendId,
                    metaBlock, stateBlock,
                    *otherBlocks.map {
                        val x = it.block
                        ApiBlock.encrypt(
                            x.type,
                            docKey,
                            it.plainData,
                            nextGuid = x.nextGuid,
                            prevGuid = x.prevGuid,
                            tag = x.tag,
                            utag = x.utag,
                            guid = x.guid
                        )
                    }.toTypedArray()
                )
            )
            if (access.role != DocRole.Owner)
                throw InternalError("ошибка создания документа (получена роль ${access.role})")

            return IcwkDocument(client, docKey, access)
        }

        suspend fun create(
            client: IcwkClient, docType: DocType,
            paretId: Long?,
            title: String,
            vararg otherBlocks: DocBlock,
        ): IcwkDocument {
            val docKey = BackgroundKeyGenerator.randomSymmetricKey()
            val metaBlock = ApiBlock.encrypt(BlockType.Meta, docKey, ApiDocMeta(title = title))
            val container = Container.encrypt(docKey, client.dataKey)
            val dca = ApiCreateDocArgs(
                docType, container, paretId,
                metaBlock,
                *otherBlocks.map {
                    val x = it.block
                    ApiBlock.encrypt(
                        x.type,
                        docKey,
                        it.plainData,
                        nextGuid = x.nextGuid,
                        prevGuid = x.prevGuid,
                        tag = x.tag,
                        utag = x.utag,
                        guid = x.guid
                    )
                }.toTypedArray()
            )
            val access = client.call(IcwkApi.docCreate, dca)
            if (access.role != DocRole.Owner)
                throw InternalError("ошибка создания документа (получена роль ${access.role})")

            return IcwkDocument(client, docKey, access)
        }

        suspend fun openById(client: IcwkClient, id: Long): IcwkDocument? = try {
            val access = client.call(IcwkApi.docGetAccess, id)
            if (access.role.isInaccessible) {
                debug { "нет доступа по ролик к докумнету $id" }
                null
            } else if (access.keyContainer == null) {
                error { "сервер не предоставил ключ-контейнер" }
                null
            } else {
                Container.decrypt<SymmetricKey>(access.keyContainer, client.mainRing())?.let { key ->
                    IcwkDocument(client, key, access)
                } ?: run {
                    error { "нет доступа к документу, не удается расшифровать ключ-контенер" }
                    null
                }
            }
        } catch (x: NotFoundException) {
            null
        }

        suspend fun openByShare(client: IcwkClient, share: String): IcwkDocument {
            val (handle, stringKey) = share.split('#').also {
                if (it.size != 2) throw IllegalArgumentException("суперссылка: неверный формат, нет хештега")
            }
            val outerKey = runCatching { SymmetricKeys.create(stringKey.decodeBase64Url()) }.getOrElse {
                throw IllegalArgumentException("суперссылка: неправильный формат ключа")
            }
            debug { "открываем по супер-ссылке: $handle" }
            return client.call(IcwkApi.docOpenByHandle, handle).value?.let { access ->
                if (access.keyContainer == null)
                    throw IllegalArgumentException("неправильная ссылка: нет ключ-контейнера")
                debug { "расшифровываем контейнер" }
                val x = ApiPublicHandle.decryptContainer(access.keyContainer, outerKey)
                debug { "контейнер расшифрован: $x" }
                val docKey = ApiPublicHandle.decryptContainer(access.keyContainer, outerKey)?.secretKey
                    ?: throw IllegalArgumentException("суперссылка не подходит к документу")
                debug { "ключ документа получен" }
                IcwkDocument(client, docKey, access, handle)
            }
                ?: throw IllegalArgumentException("суперссылка не существует, возможно, удалена")
        }
    }

}

