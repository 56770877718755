package editor.plugins

import editor.DebugList
import editor.Editor
import tools.trimToEllipsis

val DEFAULT_TITLE_SIZE = 64

class TitleManager(val dc: Editor) {
    var isDefined = isUserDefined()

    fun log(msg: String, isErr: Boolean = false) {
        if (isErr) console.error("[TitleManager]: ${msg}")
        else if (DebugList.title) {
            console.warn("[TitleManager]: ${msg}")
        }
    }

    fun getDefaultTitle(): String {
        var defaultTitle = dc.eventManager.firstBlock.plainText.trim().trimToEllipsis(DEFAULT_TITLE_SIZE)
        // remove dots at the end
        defaultTitle = defaultTitle.replace(Regex("\\.*$"), "")

        return defaultTitle
    }

    fun isUserDefined(): Boolean {
        val docTitle = dc.document?.title ?: ""
        val defaultTitle = getDefaultTitle()
        val isIt = docTitle != defaultTitle
        log("set user defined title to $isIt. doc='$docTitle' def='$defaultTitle'")
        return isIt
    }

    fun update() {
        isDefined = isUserDefined()
    }

    suspend fun updateTitle() {
        if (!isDefined) {
            val title = getDefaultTitle()
            log("set auto title: '$title'")
            dc.document?.setTitle(title)
        }
    }
}