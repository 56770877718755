package editor.plugins

import editor.operations.CRange
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.Job
import kotlinx.coroutines.yield
import kotlinx.datetime.Clock
import net.sergeych.mp_tools.globalLaunch
import org.w3c.dom.DOMRect
import org.w3c.dom.Element
import org.w3c.dom.asList
import tools.randomId
import kotlin.time.DurationUnit

enum class MarkerContext(val cssClass: String) {
    SPELLCHECKER("spellchecker"),
    SEARCH("search"),
    COMMENT("comment"),
}

val GENERAL_CSS = "mark-common"

abstract class Mark(
    open val range: CRange,
    open val extraCssClass: String = ""
)

fun filterIntersectingDOMRect(rects: List<DOMRect>): List<DOMRect> {
    val filtered = rects.toMutableList()
    fun isInnerIntersection(targetRect: DOMRect, possibleContainer: DOMRect): Boolean {
        return targetRect.left >= possibleContainer.left
                && targetRect.right <= possibleContainer.right
                && targetRect.top <= possibleContainer.top
                && targetRect.bottom >= possibleContainer.bottom
    }

    rects.forEachIndexed { j, target ->
//        var target = it
        var isIntersted = false
        var i = 0
        while (i < rects.size && !isIntersted) {
            if (i != j) isIntersted = isIntersted || isInnerIntersection(target, rects[i])
            i++
        }
        if (isIntersted) filtered -= target
    }

    return filtered
}

class Marker<T: Mark>(val ctx: MarkerContext) {
    val marks = mutableMapOf<String, T>()
//    val marks = mutableMapOf<CRange, String>()
    val markerClassName = "mark-${ctx.cssClass}"

    fun clear() {
        val existing = getAllElements()
        existing.forEach { it.remove() }
        marks.clear()
    }

    fun clear(mark: T) {
        val elements = getElements(mark)
        elements?.forEach { it.remove() }
    }

    fun getElements(mark: T): List<Element>? {
        val markId = marks.entries.find { it.value == mark }?.key ?: return null
        val list = document.querySelectorAll("[data-mark-id='${markId}']").asList().filterIsInstance<Element>()

        return list
    }

    fun getAllElements(): List<Element> {
        return document.querySelectorAll(".$markerClassName").asList().filterIsInstance<Element>()
    }

    fun getMarksAtPoint(elementsAtPoint: List<Element>): List<T> {
        val marksAtPoint = mutableSetOf<T>()

        val related = elementsAtPoint.filter { it.classList.contains(markerClassName) }
        related.forEach { element ->
            val markerId = element.getAttribute("data-mark-id")
            marks[markerId]?.let { marksAtPoint += it }
        }

        return marksAtPoint.toList()
    }

    fun removeClassName(classString: String) {
        val elements = getAllElements()

        elements.let { list ->
            list.forEach {
                if (it.classList.contains(classString)) it.classList.remove(classString)
            }
        }
    }

    fun addClassName(mark: T, classString: String) {
        val elements = getElements(mark)

        elements?.let { list ->
            list.forEach {
                if (!it.classList.contains(classString)) it.classList.add(classString)
            }
        }
    }

    fun removeClassName(mark: T, classString: String) {
        val elements = getElements(mark)

        elements?.let { list ->
            list.forEach {
                if (it.classList.contains(classString)) it.classList.remove(classString)
            }
        }
    }

    fun mark(mark: T, filterIntersecting: Boolean = false) {
        val css = if (mark.extraCssClass != "") " ${mark.extraCssClass}" else ""
        val id = randomId(6)
        marks[id] = mark

        var rects = mark.range.getDOMRects()

        // Remove caret rect if caret is in range
        if (filterIntersecting) rects = filterIntersectingDOMRect(rects)

        rects.forEach { r ->
            val attributes = listOf(
                "left: ${r.left}px",
                "top: ${r.top + window.scrollY}px",
                "width: ${r.width}px",
                "height: ${r.height}px",
            ).joinToString("; ")

            val div = document.createElement("div")
            div.className = "$GENERAL_CSS $markerClassName$css"
            div.setAttribute("style", attributes)
            div.setAttribute("data-mark-id", id)
            document.body?.appendChild(div)
        }
    }

    fun markAll(marksToAdd: List<T>, filterIntersecting: Boolean = false): Job {
        return globalLaunch {
            marksToAdd.forEach {
                mark(it, filterIntersecting)
                yield()
            }
        }
    }

    fun getByRange(range: CRange): T? {
        return marks.values.find { it.range == range }
    }

    companion object {
        fun clear() {
            val all = document.querySelectorAll(".$GENERAL_CSS").asList().filterIsInstance<Element>()
            all.forEach { it.remove() }
        }
    }
}