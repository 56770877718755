package views

import androidx.compose.runtime.*
import androidx.compose.web.events.SyntheticMouseEvent
import booleanField
import controls.Di
import controls.Icon
import controls.classNames
import controls.textField
import document.*
import editor.Editor
import editor.PackageLogger
import editor.operations.*
import editor.readFile
import editor.views.ACCEPT
import editor.views.Image
import editor.views.ImageFormat
import editor.views.resizeImage
import isLocalTest
import kotlinx.browser.window
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import modalDialog
import net.sergeych.boss_serialization_mp.BossEncoder
import net.sergeych.intecowork.api.ApiEvent
import net.sergeych.intecowork.doc.IcwkDocument
import org.jetbrains.compose.web.attributes.*
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.*
import org.w3c.dom.events.Event
import org.w3c.dom.url.URL
import org.w3c.files.File
import tools.randomId

val TOOLBAR_ID = "context-toolbar"

val toolbarLogger = PackageLogger("toolbar", "gray", false)

enum class ParagraphTextStyle(val title: String, val style: TextStyle) {
    HEADING("Заголовок", TextStyle.heading),
    SUBHEADING("Подзаголовок", TextStyle.subheading),
    HEADING1("Заголовок 1", TextStyle.heading1),
    HEADING2("Заголовок 2", TextStyle.heading2),
    HEADING3("Заголовок 3", TextStyle.heading3),
    HEADING4("Заголовок 4", TextStyle.heading4),
    NORMAL("Обычный", TextStyle.default)
}

enum class TextStyleMixin(
    val title: @Composable () -> Unit,
    val enable: (t: TextStyle) -> TextStyle,
    val disable: (t: TextStyle) -> TextStyle,
    val isEnabled: (t: TextStyle) -> Boolean,
) {
    BOLD(
        { B { Text("Ж") } },
        { t -> t.copy(fontWeight = TextStyle.FontWeight.Bold) },
        { t -> t.copy(fontWeight = null) },
        { it.fontWeight == TextStyle.FontWeight.Bold }
    ),
    ITALICS(
        { I { Text("К") } },
        { t -> t.copy(italics = true) },
        { t -> t.copy(italics = null) },
        { it.italics == true }
    ),
    UNDERLINE(
        { Span(attrs = { style { textDecoration("underline") } }) { Text("П") } },
        { t -> t.copy(underline = true) },
        { t -> t.copy(underline = null) },
        { it.underline == true }
    );

    fun toggle(t: TextStyle): TextStyle {
        return if (isEnabled(t)) disable(t) else enable(t)
    }
}


@Composable
fun StyleMixinButton(
    currentStyle: TextStyle,
    mixin: TextStyleMixin,
    onChange: suspend (e: SyntheticMouseEvent) -> Unit
) {
    val scope = rememberCoroutineScope()
    var classNames = "btn btn-sm"
    if (mixin.isEnabled(currentStyle)) classNames += " active"

    Button(attrs = {
        onClick { scope.launch { onChange(it) } }
        tooltip(
            when (mixin) {
                TextStyleMixin.BOLD -> "переключить жирный"
                TextStyleMixin.ITALICS -> "переключить курсив"
                TextStyleMixin.UNDERLINE -> "переключить подчеркивание"
            }
        )
        classes("btn-toolbar")
        classes(*classNames.split(' ').toTypedArray())
    }) {
        Di("w-100") {
            mixin.title()
        }
    }
}

@Composable
fun ListStyleButton(
    currentStyle: ParagraphStyle?,
    listStyle: ParagraphStyle.List,
    onChange: suspend (e: SyntheticMouseEvent) -> Unit
) {
    val scope = rememberCoroutineScope()
    var classNames = "btn btn-sm"
    val isActive = currentStyle?.listStyle == listStyle
    if (isActive) classNames += " active"

    Button(attrs = {
        onClick { scope.launch { onChange(it) } }
        classes(*classNames.split(' ').toTypedArray())
        classes("btn-toolbar")
        tooltip(
            when (listStyle) {
                ParagraphStyle.List.Bullets -> "список"
                ParagraphStyle.List.Numbers -> "нумерованный список"
            }
        )
    }) {
        when (listStyle) {
            ParagraphStyle.List.Bullets -> {
                Icon.ListBulleted.renderInToolbar({})
            }

            ParagraphStyle.List.Numbers -> {
                Icon.ListNumbered.renderInToolbar({})
            }
        }
    }
}

@Composable
fun AddImageButton(dc: Editor) {
    var imageFile: File? by remember { mutableStateOf(null) }
    var imageFormat: ImageFormat.Geometry? by remember { mutableStateOf(null) }
    val ctrlId = remember { randomId(11) }
    val imageId = remember { randomId(11) }
    val scope = rememberCoroutineScope()

    Button(attrs = {
        onClick {
            window.document.getElementById(ctrlId)?.let {
                (it as HTMLInputElement).click()
            }
        }
        classes("btn", "btn-toolbar", "btn-sm")
        tooltip("добавить изображение")
    }) { Icon.Image.renderInToolbar({}) }

    Input(InputType.File) {
        attr("id", ctrlId)
        accept(ACCEPT.joinToString(", "))
        style { display(DisplayStyle.None) }
        // FIXME: need to set value
        onChange {
            val file = it.target.files?.asList()?.firstOrNull()

            imageFile = file
        }
    }

    fun resetInput() {
        window.document.getElementById(ctrlId)?.let {
            val fileInput = it as HTMLInputElement
            fileInput.value = ""
        }
    }

    imageFile?.let {
        modalDialog("Добавить изображение") {
            body {
                Div(attrs = { style { width(100.percent) } }) {
                    Img(URL.createObjectURL(it), attrs = {
                        style {
                            width(100.percent)
                            maxWidth(100.percent)
                            display(DisplayStyle.Block)
                        }
                        id(imageId)
                        ref {

                            fun jsCropper(@Suppress("UNUSED_PARAMETER") img: Element) {
                                js("new Cropper(img, { zoomable: false })")
                            }

                            fun onCrop(e: Event) {
                                val ce = e as CustomEvent
                                val d = ce.detail.asDynamic()

                                val rotate = 0.0 // FIXME: when portrait image was added - cropper js sends rotate=90

                                imageFormat = ImageFormat.Geometry(
                                    d.width as Float,
                                    d.height as Float,
                                    d.x as Float,
                                    d.y as Float,
                                    d.scaleX as Float,
                                    d.scaleY as Float,
                                    rotate as Float
                                )
                            }

                            it.addEventListener("crop", ::onCrop)

                            scope.launch {
                                delay(200)
                                jsCropper(it)
                            }

                            onDispose {
                                it.removeEventListener("crop", ::onCrop)
                            }
                        }
                    })
                }
            }
            footer {
                Button(attrs = {
                    type(ButtonType.Button)
                    classes("btn", "btn-secondary")
                    attr("data-bs-dismiss", "modal")
                    onClick {
                        imageFile = null
                        resetInput()
                        close()
                    }
                }) { Text("Отменить") }
                Button(attrs = {
                    type(ButtonType.Button)
                    classes("btn", "btn-primary")
                    onClick {
                        scope.launch {
                            imageFile?.let { file ->
                                imageFormat?.let { format ->
//                                    val adjustedFormat = adjustGeometry(format) as ImageFormat.Geometry
//                                    console.warn("w=${format.x} h=${format.y} aw=${adjustedFormat.x} ah=${adjustedFormat.y}")
//                                    console.warn("w=${format.width} h=${format.height} aw=${adjustedFormat.width} ah=${adjustedFormat.height}")
                                    val imageBinOriginal = readFile(file)
                                    val resized = resizeImage(imageBinOriginal, format)
                                    val image = Image(resized.bin, resized.format)
                                    val frame = Fragment.Frame("image", BossEncoder.encode(image))

                                    dc.insertTerminalFragmentAtCaret(frame)
                                }
                            }
                            imageFile = null
                            resetInput()
                            imageFormat = null
                            close()
                        }
                    }
                }) { Text("Добавить") }
            }
        }
    }
}

@Composable
fun AddTableButton(@Suppress("UNUSED_PARAMETER") eventManager: EventManager, dc: Editor, isCaretInTable: Boolean) {
    val scope = rememberCoroutineScope()
    var showModal by remember { mutableStateOf(false) }
    var rows: Int? by remember { mutableStateOf(2) }
    var cols: Int? by remember { mutableStateOf(2) }
    var hasHeader by remember { mutableStateOf(false) }

    Button(attrs = {
        onClick {
            showModal = true
        }
        classes("btn", "btn-toolbar", "btn-sm")
        tooltip("добавить таблицу")
        if (isCaretInTable) disabled()
    }) { Icon.Table.renderInToolbar({}) }

    if (showModal) {
        dc.setIsActive(false)
        modalDialog("Добавить таблицу") {
            body {
                Div(attrs = {
                    style { width(100.percent) }
                    ref {
                        window.blur()
                        it.focus()
                        onDispose { it.blur() }
                    }
                }) {
                    booleanField(hasHeader, "Сделать первую строку заголовком", isSwitch = true) {
                        hasHeader = it
                    }
                    textField(
                        rows?.toString() ?: "",
                        "Количество строк"
                    ) {
                        console.log("rows changed", it)
                        rows = if (it != "") it.toInt() else null
                    }
                    textField(
                        cols?.toString() ?: "",
                        "Количество столбцов"
                    ) {
                        cols = if (it != "") it.toInt() else null
                    }
                }
            }
            footer {
                Button(attrs = {
                    type(ButtonType.Button)
                    classes("btn", "btn-secondary")
                    attr("data-bs-dismiss", "modal")
                    onClick {
                        dc.setIsActive(true)
                        showModal = false
                        close()
                    }
                }) { Text("Отменить") }
                Button(attrs = {
                    type(ButtonType.Button)
                    if (rows == null || rows == 0 || cols == 0 || cols == null)
                        disabled()
                    classes("btn", "btn-primary")
                    onClick {
                        scope.launch {
                            dc.insertTable(rows ?: 0, cols ?: 0, hasHeader)
                            dc.setIsActive(true)
                            showModal = false
                            close()
                        }
                    }
                }) { Text("Добавить") }
            }
        }
    }
}

fun AttrsScope<HTMLButtonElement>.tooltip(text: String, position: String = "left") {
    attr("data-bs-toggle", "tooltip")
    attr("data-bs-placement", position)
    attr("data-bs-title", text)
}


@Composable
fun TextStyleToolbar(idoc: IcwkDocument?, eventManager: EventManager, dc: Editor) {
    var initialParagraphStyle: ParagraphStyle? = null
    var initialCaretStyle = TextStyle()
    var isCaretInTable by remember { mutableStateOf(false) }
    var isSpellCheckOn by remember { mutableStateOf(dc.spellChecker?.isOn ?: false) }
    var hasShare by remember { mutableStateOf<Boolean?>(null) }

    idoc?.let {
        LaunchedEffect(true) {
            idoc.events().collect {
                when (it) {
                    is ApiEvent.Doc.ShareChanged -> hasShare = it.isShared
                    else -> {}
                }
            }
        }
        LaunchedEffect(idoc.docId) {
            hasShare = idoc.getPublicShare() != null || idoc.sharedWith().isNotEmpty()
        }
    }

    dc.caret?.let {
        val caretContainer = dc.caretContainer(it)
        initialParagraphStyle = caretContainer.paragraphStyle
        initialCaretStyle = dc.getStyle(it)
        isCaretInTable = it.rootType == CaretRoot.TABLE
    }

    var paragraphStyle by remember { mutableStateOf(initialParagraphStyle) }
    var computedStyle by remember { mutableStateOf(initialCaretStyle) }
    val scope = rememberCoroutineScope()

    fun updateStyles(b: Block, c: Caret?) {
        if (c == null) return
        val container = b.find(c.path[c.path.size - 2]) ?: return
        paragraphStyle = (container as Fragment.Paragraph).paragraphStyle
        isCaretInTable = c.rootType == CaretRoot.TABLE
        try {
            computedStyle = dc.selection.range?.let {
                dc.getStyle(it)
            } ?: run {
                dc.getStyle(c, b)
            }
        } catch (e: Exception) {
            dc.replay.onError("TOOLBAR: ${e}", e)
        }
    }

    LaunchedEffect("toolbarListeners") {
        eventManager.events.collect { e ->
            scope.launch {
                when (e) {
                    is EventManager.Event.UpdateCaretBlock -> {
                        dc.eventManager.withLock("toolbar.updateStyles") {
                            toolbarLogger.log("update caret styles")
                            e.block.getTextFragments().forEach {
//                                console.log("${it.guid} -> '${it.text}'")
//                                console.log(e.caret?.toFullString())
                            }
                            toolbarLogger.log("update caret styles begin")
                            updateStyles(e.block, e.caret)
                            toolbarLogger.log("update caret styles done")
                        }
                    }

                    else -> {}
                }
            }
        }
    }

    Div(attrs = {
        classes("btn-group-vertical", "me-3", "mt-2")
        attr("role", "group")
        attr("aria-label", "")
        id(TOOLBAR_ID)
    }) {
        Button(attrs = {
            onClick {
                (it.target as HTMLElement).blur()
                scope.launch { dc.exportDOCX() }
            }
            classes("btn", "btn-toolbar", "btn-sm")
            tooltip("загрузить как .docx")
        }) {
            Icon.Download.renderInToolbar()
        }

        Button(attrs = {
            onClick {
                (it.target as HTMLElement).blur()
                scope.launch { dc.print() }
            }
            classes("btn", "btn-toolbar", "btn-sm")
            tooltip("печатать")
        }) {
            Icon.Printer.renderInToolbar({})
        }

//        idoc?.let {
//            Button(attrs = {
//                onClick {
//                    (it.target as HTMLElement).blur()
//                    scope.launch { collaboratorsDialog(idoc) }
//                }
//                classes("btn", "btn-toolbar","btn-sm")
//            }) {
//                (if (hasShare == true) Icon.People else Icon.PeesonAdd).render({})
//            }
//        }
//

        AddTableButton(eventManager, dc, isCaretInTable)
        AddImageButton(dc)

        if (dc.spellChecker != null) {
            Button(attrs = {
                onClick {
                    (it.target as HTMLElement).blur()
                    scope.launch {
                        if (isSpellCheckOn) {
                            dc.eventManager.withLock("spellchecker.toggle") {
                                dc.spellChecker.turnOff()
                                isSpellCheckOn = !isSpellCheckOn
                            }
                        } else {
                            dc.spellChecker.load().invokeOnCompletion {
                                scope.launch {
                                    dc.eventManager.withLock("spellchecker.toggle") {
                                        dc.spellChecker.turnOn()
                                        isSpellCheckOn = !isSpellCheckOn
                                    }
                                }
                            }
                        }
                    }
                }
                var css = "btn btn-sm"
                if (isSpellCheckOn) css += " active"
                tooltip("проверять орфографию")
                classNames(css)
            }) {
                Icon.SpellCheck.renderInToolbar({})
            }
        }
        else {
            console.warn("dc.spellChecker is null")
        }

        Button(attrs = {
            onClick {
                (it.target as HTMLElement).blur()
                scope.launch { dc.cycleParagraphTextAlign() }
            }
            classes("btn", "btn-toolbar", "btn-sm")
            tooltip("изменить выравнивание текста")

        }) {
            when (paragraphStyle?.textAlign) {
                ParagraphStyle.TextAlign.Left -> {
                    Icon.TextLeft.renderInToolbar({ style { fontSize(1.5.em) } })
                }

                ParagraphStyle.TextAlign.Right -> {
                    Icon.TextRight.renderInToolbar({ style { fontSize(1.5.em) } })
                }

                ParagraphStyle.TextAlign.Center -> {
                    Icon.TextCenter.renderInToolbar({ style { fontSize(1.5.em) } })
                }

                ParagraphStyle.TextAlign.Justify -> {
                    Icon.TextJustify.renderInToolbar({ style { fontSize(1.5.em) } })
                }

                null -> {
                    Icon.TextLeft.renderInToolbar({})
                }
            }
        }

        Button(attrs = {
            onClick {
                (it.target as HTMLElement).blur()
                scope.launch { dc.changeIndent(-1) }
            }
            classes("btn", "btn-toolbar", "btn-sm")
            tooltip("уменьшить отступ")
        }) { Icon.ArrowBarLeft.renderInToolbar({}) }
        Button(attrs = {
            onClick {
                (it.target as HTMLElement).blur()
                scope.launch { dc.changeIndent(+1) }
            }
            classes("btn", "btn-toolbar", "btn-sm")
            tooltip("увеличить отступ")
        }) { Icon.ArrowBarRight.renderInToolbar({}) }

        ParagraphStyle.List.entries.forEach { listStyle ->
            ListStyleButton(paragraphStyle, listStyle) {
                (it.target as HTMLElement).blur()
                dc.toggleListStyle(listStyle)
            }
        }

        TextStyleMixin.entries.forEach { mixin ->
            StyleMixinButton(computedStyle, mixin) {
                (it.target as HTMLElement).blur()
                dc.eventManager.withLock("toolbar.toggleTextStyleMixin") {
                    dc.toggleTextStyleMixin(mixin)
                    computedStyle = mixin.toggle(computedStyle)
                }
            }
        }

        if (isLocalTest) {
            Button(attrs = {
                onClick {
                    (it.target as HTMLElement).blur()
                    scope.launch { dc.replay.saveCase() }
                }
                classes("btn", "btn-toolbar", "btn-sm")
                tooltip("скачать отчет")
            }) { Icon.FileLock2.renderInToolbar({}) }
        }

    }
    LaunchedEffect(idoc?.docId) {
        resetTooltips()
    }
}

external fun resetTooltips()

@Composable
fun Icon.renderInToolbar(attrs: AttrBuilderContext<HTMLElement>? = null) {
    render {
        classes("toolbar-icon", "m-0", "p-1")
        attrs?.invoke(this)
    }
}