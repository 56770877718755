package tools

import androidx.compose.runtime.Composable
import controls.classNames
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Img
import org.w3c.dom.HTMLImageElement

@Composable
fun ThemedMascot(mascotName: String, classes: String? = null, attrs: AttrBuilderContext<HTMLImageElement>? = null) {
    Mascot(
        if (UITheme.composeState.value.isDark) "$mascotName-dark" else "$mascotName-light",
    )
}

@Composable
fun Mascot(mascotName: String, classes: String? = null, attrs: AttrBuilderContext<HTMLImageElement>? = null) {
    Img(
        "/mascots/$mascotName.svg",
        attrs = {
            style {
                height(11.em)
            }
            attrs?.invoke(this)
            classes?.let { classNames(it) }
        }
    )
}
