package editor.plugins

import Browser
import controls.*
import document.*
import editor.DebugList
import editor.Editor
import editor.PackageLogger
import editor.Point
import editor.operations.*
import kotlinx.browser.window
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.datetime.internal.JSJoda.DateTimeFormatter
import kotlinx.datetime.toJSDate
import net.sergeych.boss_serialization_mp.BossEncoder
import net.sergeych.mp_tools.decodeBase64
import net.sergeych.mp_tools.encodeToBase64Compact
import org.jetbrains.compose.web.dom.Text
import views.TextStyleMixin

const val editorName = "editor"

val replayLogger = PackageLogger("replay", "Maroon", DebugList.replay)

fun Caret.replay(): String {
    val spath = path.map { "\"${it}\"" }.joinToString(", ")
    return "Caret(listOf($spath), ${offset})"
}

fun CRange.replay(): String {
    return "CRange(${left.replay()}, ${right.replay()})"
}

fun Point.replay(): String {
    return "Point(\"${x}\".toDouble(), \"${y}\".toDouble())"
}

fun MouseCaret.replay(): String {
    return "MouseCaret(${point.replay()}, ${caret?.replay()})"
}

fun MouseUpEvent.replay(): String {
    return "MouseUpEvent(${isDouble}, ${previous?.replay()}, ${next.replay()}, ${down.replay()})"
}

fun randomText(size: Int): String {
    val range = 'a' ..'z'
    val letter = range.random()

    return letter.toString().repeat(size)
}

val blackPixel = "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII=".decodeBase64()

fun getMaskedText(text: String): String {
    val parts = text.split("\n")
    val masked = parts.map { randomText(it.length) }
    return masked.joinToString("\n")
}

fun Fragment.anonymousCopy(): Fragment {
    return when(this) {
        is Fragment.StyledSpan -> copy(text = getMaskedText(text))
        is Fragment.LinkedImage -> copy()
        is Fragment.Frame -> {
            when (type) {
                "image" -> copy(bin = blackPixel)
                else -> {
                    console.error("unsupported type of frame")
                    copy()
                }
            }
        }

        is Fragment.StoredImage -> copy(bin = blackPixel)
        is IParagraph -> (this as IParagraph).anonymousCopy().toFragment()
    }
}

fun IParagraph.anonymousCopy(): IParagraph {
    val anonymousElements = elements.map {
        when(it) {
            is IParagraph -> (it as IParagraph).anonymousCopy().toFragment()
            else -> (it as Fragment).anonymousCopy()
        }
    }

    return makeCopy(anonymousElements)
}

fun Block.anonymousCopy(): Block {
    return copy(paragraph.anonymousCopy())
}

data class Operation(
    val command: String,
    val transaction: String? = null,
    val time: Instant = Clock.System.now(),
) {
    override fun toString(): String {
        var content = ""
        val space = "     "
//        val format = "HH:mm:ss sss"

//        val formatter = DateTimeFormatter.ofPattern(format)
        if (transaction != null) {
            content += "${space}// /* ${time} */ ${command}\n"
            content += "${space}   /* ${time} */ ${transaction}\n"
        } else {
            content += "${space}   /* ${time} */ ${command}\n"
        }

        return content
    }
}

suspend fun Editor.replayAUD(aud: AUDTransaction<Block>) {
    runTransaction("replay transaction") { chain, style ->
        chain.applyAUDTransaction(aud)
    }
}

class Replay(val dc: Editor, val isReplay: Boolean = false) {
    var initialBlocks: List<Block> = emptyList()
    var initialBlockVars: List<String> = emptyList()
    var initialCaret: Caret? = null

    var arguments: List<String> = emptyList()
    var operations = mutableListOf<Operation>()

    fun init(blocks: List<Block>, caret: Caret?) {
        if (isReplay) return

        initialBlocks = blocks
        initialCaret = caret
        arguments = emptyList()
        operations.clear()

        initialBlockVars = blocks.map { addArgument(it) }
    }

    fun insertTerminalFragmentAtCaret(f: Fragment) {
        if (isReplay) return
        val replay = addArgument(f)

        operations += Operation("$editorName.insertTerminalFragmentAtCaret(${replay})")
    }

    fun updateTerminalFragment(f: Fragment) {
        if (isReplay) return
        val fReplay = addArgument(f)

        operations += Operation("$editorName.updateTerminalFragment(${fReplay})")
    }

    fun deleteTerminalFragment(guid: String) {
        if (isReplay) return
        val fReplay = addArgument(guid)

        operations += Operation("$editorName.deleteTerminalFragment(${fReplay})")
    }

    fun delete(r: CRange) {
        if (isReplay) return
        operations += Operation("$editorName.delete(${r.replay()})")
    }

    fun onBackspace(isCtrl: Boolean) {
        if (isReplay) return
        operations += Operation("$editorName.onBackspace(${isCtrl})")
    }

    fun fixV1() {
        if (isReplay) return
        operations += Operation("$editorName.fixV1()")
    }

    fun runMergeTransaction(theirBlocks: List<Block>) {
        if (isReplay) return
        val blocks = theirBlocks.map { addArgument(it) }.joinToString(", ")
        operations += Operation("$editorName.runMergeTransaction(listOf($blocks))")
    }

    fun setParagraphStyle(style: ParagraphStyle) {
        if (isReplay) return
        val replay = addArgument(style)
        operations += Operation("$editorName.setParagraphStyle($replay)")
    }

    fun setParagraphTextStyle(style: TextStyle) {
        if (isReplay) return
        val replay = addArgument(style)
        operations += Operation("$editorName.setParagraphTextStyle($replay)")
    }

    fun setNamedParagraphTextStyle(name: String, style: TextStyle) {
        if (isReplay) return
        val replay = addArgument(style)
        operations += Operation("$editorName.setNamedParagraphTextStyle(\"$name\", $replay)")
    }

    fun changeIndent(step: Int) {
        if (isReplay) return
        operations += Operation("$editorName.changeIndent($step)")
    }

    fun cycleParagraphTextAlign() {
        if (isReplay) return
        operations += Operation("$editorName.cycleParagraphTextAlign()")
    }

    fun cutSelection() {
        if (isReplay) return
        operations += Operation("$editorName.cutSelection()")
    }

    fun copy() {
        if (isReplay) return
        operations += Operation("$editorName.copy()")
    }

    fun paste(text: String, isPlain: Boolean) {
        if (isReplay) return
        val textA = addArgument(text)
        operations += Operation("$editorName.paste($textA, $isPlain)")
    }

    fun replace(range: CRange, replaceWith: String) {
        if (isReplay) return
        val rangeA = range.replay()
        val replaceA = addArgument(replaceWith)

        operations += Operation("$editorName.replace($rangeA, $replaceA)")
    }

    fun setStyle(range: CRange, mixin: TextStyleMixin, shouldEnable: Boolean) {
        if (isReplay) return
        val mixinA = "TextStyleMixin.${mixin.name}"

        operations += Operation("$editorName.setStyle(${range.replay()}, ${mixinA}, ${shouldEnable})")
    }

    fun splitBlockAtCaret() {
        if (isReplay) return
        operations += Operation("$editorName.splitBlockAtCaret()")
    }

    fun tableAddRow(insertAfter: Int, tableId: String, row: List<Fragment.Paragraph>?) {
        if (isReplay) return
        val rowA = if (row == null) null else {
            val ps = row.map { addArgument(it) }.joinToString(", ")
            "listOf($ps)"
        }

        operations += Operation("$editorName.tableAddRow($insertAfter, \"$tableId\", $rowA)")
    }

    fun tableAddCol(insertAfter: Int, tableId: String, col: List<Fragment.Paragraph>?) {
        if (isReplay) return
        val colA = if (col == null) null else {
            val ps = col.map { addArgument(it) }.joinToString(", ")
            "listOf($ps)"
        }

        operations += Operation("$editorName.tableAddCol($insertAfter, \"$tableId\", $colA)")
    }

    fun tableDeleteRow(rowIndex: Int, tableId: String) {
        if (isReplay) return
        operations += Operation("$editorName.tableDeleteRow($rowIndex, \"$tableId\")")
    }

    fun tableDeleteCol(colIndex: Int, tableId: String) {
        if (isReplay) return
        operations += Operation("$editorName.tableDeleteCol($colIndex, \"$tableId\")")
    }

    fun insertTable(rows: Int, cols: Int, hasHeader: Boolean) {
        if (isReplay) return
        operations += Operation("$editorName.insertTable($rows, $cols, $hasHeader)")
    }

    fun insertChar(ch: String) {
        if (isReplay) return
        operations += Operation("$editorName.insertChar(\"$ch\")")
    }

    fun forceUndo() {
        if (isReplay) return
        if (operations.size > 0) {
            val lastOperation = operations.last()
            var withoutLast = operations.dropLast(1)

            withoutLast += Operation("$editorName.replayUndoForce = true")

            operations = (withoutLast + lastOperation).toMutableList()
        }
    }

    fun undo() {
        if (isReplay) return
        operations += Operation("$editorName.undo()")
    }

    fun redo() {
        if (isReplay) return
        operations += Operation("$editorName.redo()")
    }

    fun onMouseUp(ev: MouseUpEvent) {
        if (isReplay) return
        operations += Operation("$editorName.onMouseUp(${ev.replay()})")
    }


    fun onArrowLeft(isShift: Boolean) { operations += Operation("$editorName.onArrowLeft($isShift)") }
    fun onArrowLeftAlt(isShift: Boolean) { operations += Operation("$editorName.onArrowLeftAlt($isShift)") }
    fun onArrowLeftCtrl(isShift: Boolean) { operations += Operation("$editorName.onArrowLeftCtrl($isShift)") }
    fun onArrowRight(isShift: Boolean) { operations += Operation("$editorName.onArrowRight($isShift)") }
    fun onArrowRightAlt(isShift: Boolean) { operations += Operation("$editorName.onArrowRightAlt($isShift)") }
    fun onArrowRightCtrl(isShift: Boolean) { operations += Operation("$editorName.onArrowRightCtrl($isShift)") }
    fun onArrowUp(isShift: Boolean, isCtrl: Boolean) { operations += Operation("$editorName.onArrowUp($isShift, $isCtrl)") }
    fun onArrowDown(isShift: Boolean, isCtrl: Boolean) { operations += Operation("$editorName.onArrowDown($isShift, $isCtrl)") }

    fun addArgument(a: Fragment.Paragraph): String {
        val stringValue = BossEncoder.encode(a).encodeToBase64Compact()
        val replayValue = "\"${stringValue}\".decodeBase64Compact().decodeBoss<Paragraph>()"
        return addArg(replayValue)
    }

    fun addArgument(a: ParagraphStyle): String {
        val stringValue = BossEncoder.encode(a).encodeToBase64Compact()
        val replayValue = "\"${stringValue}\".decodeBase64Compact().decodeBoss<ParagraphStyle>()"
        return addArg(replayValue)
    }

    fun addArgument(a: TextStyle): String {
        val stringValue = BossEncoder.encode(a).encodeToBase64Compact()
        val replayValue = "\"${stringValue}\".decodeBase64Compact().decodeBoss<TextStyle>()"
        return addArg(replayValue)
    }

    fun addArgument(b: Block): String {
        val stringValue = BossEncoder.encode(b.anonymousCopy()).encodeToBase64Compact()
        val replayValue = "\"${stringValue}\".decodeBase64Compact().decodeBoss<Block>()"
        return addArg(replayValue)
    }

    fun addArgument(aud: AUDTransaction<Block>): String {
        val anonymousTransaction = aud.copy(
            add = aud.add.map { it.anonymousCopy() },
            update = aud.update.map { it.anonymousCopy() },
            delete = aud.delete.map { it.anonymousCopy() }
        )
        val stringValue = BossEncoder.encode(anonymousTransaction).encodeToBase64Compact()
        val replayValue = "\"${stringValue}\".decodeBase64Compact().decodeBoss<AUDTransaction<Block>>()"

        return addArg(replayValue);
    }

    fun addArgument(a: Fragment): String {
        val stringValue = BossEncoder.encode(a.anonymousCopy()).encodeToBase64Compact()
        val replayValue = "\"${stringValue}\".decodeBase64Compact().decodeBoss<Fragment>()"
        return addArg(replayValue)
    }

    fun addArgument(s: String): String {
        val replayValue = "\"${randomText(s.length)}\""
        return addArg(replayValue)
    }

    fun addArg(replayValue: String): String {
        val name = "arg${arguments.size}"

        val row = "val ${name} = ${replayValue}"

        arguments += row

        return name
    }

    fun finishOperation(aud: AUDTransaction<Block>) {
        var lastOperation = operations.lastOrNull() ?: return

        val audVal = addArgument(aud)

        lastOperation = lastOperation.copy(transaction = "$editorName.replayAUD($audVal)")

        operations[operations.size - 1] = lastOperation
    }

    fun getCase(name: String): String {
        var case = ""
        val space = "        "

        arguments.forEach {
            case += "${space}$it\n"
        }

        val blockList = initialBlockVars.joinToString(", ")

        case += "${space}val eventManager = EventManager()\n"
        case += "${space}eventManager.initializeWithBlocks(listOf($blockList))\n"
        case += "${space}val $editorName = Editor(eventManager, isReplay=true)\n"
        case += "${space}$editorName.caret = ${initialCaret?.replay()}\n"

        operations.forEach {
            case += it
        }

        val template = """
package editor

import document.*
import editor.operations.*
import editor.plugins.*
import net.sergeych.boss_serialization_mp.decodeBoss
import net.sergeych.mp_tools.decodeBase64Compact
import kotlin.test.Test

class ${name} {
    @Test
    fun run() = coTest {
${case}    }
}
"""

        return template
    }

    fun saveCase(err: String? = null) {
        if (isReplay) return
        var content = ""

        err?.let {
            content += "/*\n$err\n*/\n"
        }

        val browser = Browser.browser.name
        val now = Clock.System.now().toEpochMilliseconds()
        val caseName = "Replay.${browser}.${now}.kt"
        val testName = "Replay${browser}${now}"
        content += getCase(testName)

        val blob = js("new Blob([content], {type: \"text/plain;charset=utf-8\"});")
        js("saveAs(blob, caseName)")
    }

    fun onError(err: String, e: Throwable?) {
        replayLogger.log(err)
        e?.let { replayLogger.error(it) }
        if (isReplay) return

        dc.setIsActive(false)

        Router.pushModal { doClose ->
            Dialog("Ошибка во время выполнения операции") {
                staticBackdrop()

                body {
                    Di("container-fluid") {
                        Row {
                            Di("col") { Text("Вы можете скачать отчет об ошибке на компьютер, и вернуться к работе, обновив страницу") }
                        }
                    }
                }
                footer {
                    Bn({
                        classes(Variant.Primary.buttonClass)
                        onClick {
                            saveCase(err)
                        }
                    }) {
                        Text("Скачать отчет")
                    }
                    Bn({
                        classes(Variant.Secondary.buttonClass)
                        onClick {
                            window.location.reload()
                        }
                    }) {
                        Text("Обновить страницу")
                    }
                }
                onClose {
                    window.location.reload()
                }
            }
        }
    }
}