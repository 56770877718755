package document.fts

import net.sergeych.TokenMatch
import net.sergeych.intecowork.doc.IcwkDocument

/**
 * The full text search entry.
 */
sealed class FtsMatch {
    abstract val document: IcwkDocument

    abstract val score: TokenMatch.Score

    data class NameMatch(override val document: IcwkDocument, override val score: TokenMatch.Score) : FtsMatch()

    data class KeywordMatch(
        override val document: IcwkDocument,
        override val score: TokenMatch.Score,
        val keywords: List<String>
    ) : FtsMatch()

    data class TextMatch(
        override val document: IcwkDocument,
        override val score: TokenMatch.Score,
        val textFragment: String
    ) : FtsMatch()

}

/**
 * The full text search result for [textSearch] (global function). It is used to report partial and full
 * result.
 */
data class FtsResult(
    /**
     * The currently found matches.
     */
    val matches: List<FtsMatch>,
    /**
     * show that the first pass of the search is in progress. First pass means not all known docs were
     * searched. After all documents were checked, [textSearch] continues responding to changes in
     * documents.
     */
    val inProgress: Boolean
)