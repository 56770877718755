package controls

enum class Variant {
    Primary,
    Secondary,
    Info,
    Success,
    Warning,
    Danger,
    Light,
    Dark,
    Link;



    fun buttonClassFor(outline: Boolean) = if( !outline ) buttonClass else outlineButtonClass

    val classSuffix by lazy {
        when(this) {
            Primary -> "primary"
            Secondary -> "secondary"
            Info -> "info"
            Success -> "success"
            Warning -> "warning"
            Danger -> "danger"
            Light -> "light"
            Dark -> "dark"
            Link -> "link"
        }
    }

    val textClass by lazy { "text-$classSuffix" }

    @Suppress("unused")
    val bgClass by lazy { "bg-$classSuffix" }

    @Suppress("unused")
    val bgSubtleClass by lazy { "bg-$classSuffix-sublte" }

    val buttonClass by lazy { "btn-$classSuffix" }

    val outlineButtonClass by lazy {
        buttonClass.replace("btn-", "btn-outline-")
    }

    val alertClass by lazy { "alert-$classSuffix" }
}
