package tools

import androidx.compose.runtime.State
import androidx.compose.runtime.mutableStateOf
import kotlinx.browser.window
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import net.sergeych.mp_tools.globalLaunch
import org.w3c.dom.get
import tools.UITheme.Mode

/*
Important.

Most of the logic is implemented in Javascript that synchronously loads with page to
prevent white-dark flickering on start. We don't know how to prevent it (e.g. how to
run code before the empty page appears in potentially wrong colour), so we
implement in javascript.

The following functions abd vars should never be accessed directly, it could be
and will be changed; use UITheme instead.
 */

external fun setDefaultTheme(mode: String)
external val defaultTheme: String
external val currentSystemTheme: String
external var onThemeChanged: (Boolean) -> Unit

/*
End of auxiliary interface not to be accessed directly
 */


/**
 * Manage the white-dark theme for the application.
 *
 * [UITheme.mode] allows to use system default or override the mode.
 *
 * [UITheme.state], [UITheme.current] and [UITheme.isDark] represent
 * current state depending on the OS settings and requested [Mode].
 *
 * Note that it reacts on system setting changes, so collecting [UITheme.state]
 * let react to mode changes also caused by external OS events.
 */
sealed class UITheme {
    /**
     * Dark theme. Place any data that is appropriate to the dark theme here
     */
    data object Dark : UITheme()

    /**
     * Light theme. Place any data that is appropriate to the light theme here
     */
    data object Light : UITheme()

    /**
     * The way application reacts to the OS settings change.
     */
    enum class Mode(val code: String, val title: String) {
        AlwaysDark("dark", "всегда тёмная"),
        AlwaysLight("light", "всегда светлая"),
        UseSystem("auto", "как в системе");

        companion object {
            fun withCodeOrNull(code: String): Mode? = entries.find { it.code == code }

            fun withCodeOrThrow(code: String): Mode =
                withCodeOrNull(code)
                    ?: throw IllegalArgumentException("UITheme.Mode.forCode: invalid code: $defaultTheme")
        }
    }

    val isDark by lazy { this is Dark }

    companion object {

        /**
         * Current UI theme policy, e.g. how UITheme changes with OS theme.
         *
         * It is not the current UI theme, use [current], [state] and [isDark] to access the theme.
         *
         * To receive events on actual UI theme change, not the policy, subscribe
         * to [state].
         */
        var mode: Mode
            get() {
                val d = defaultTheme
                return Mode.entries.find { it.code == d }
                    ?: run {
                        console.error("UITheme invalid mode reported: $defaultTheme")
                        Mode.UseSystem
                    }
            }
            set(value) {
                setDefaultTheme(value.code)
            }

        // actual state flow of app theme
        private val _state = MutableStateFlow<UITheme>(
            if (window.document.body?.dataset?.get("bsTheme") == "dark") Dark else Light
        )

        /**
         * Flow of changes in UI theme. To change it, assign new value to [mode].
         */
        val state = _state.asStateFlow()

        private val composeState_ = mutableStateOf(_state.value)

        /**
         * Compose-compatible state of UI theme. Use it to switch compose parameters that
         * depend on UI theme, and it will redraw on theme change.
         *
         * To change theme, assign it to [mode].
         */
        val composeState: State<UITheme> = composeState_

        /**
         * Active UITheme, same as `state.value`. To change it, assign value to [mode],
         */
        val current get() = state.value

        /**
         * Check currently active theme  is dark
         */
        val isDark get() = current == Dark

        /**
         * Get current system theme. Actual theme set in application should be checked with
         * [state] and [isDark]
         */
        private var systemTheme: UITheme =
            when (currentSystemTheme) {
                "dark" -> Dark
                else -> Light
            }

        init {
            println("system theme detected as $systemTheme")
            println("current UI mode is $mode")
            onThemeChanged = {
                _state.value = if (it) Dark else Light
                println("theme change signal received: $current")
            }
            globalLaunch {
                _state.collect {
                    composeState_.value = it
                }
            }
        }
    }
}