package editor.operations

import document.*
import editor.*
import kotlinx.coroutines.flow.toList
import net.sergeych.intecowork.api.BlockType
import net.sergeych.intecowork.doc.IcwkDocument
import net.sergeych.merge3.merge3
import restoreFragments
import kotlin.math.min

val mergeLogger = PackageLogger("merge", "purple", DebugList.transaction)

enum class MergeVariant {
    SOURCE,
    OUR,
    THEIR
}

data class MergeConflict<T>(
    val source: T,
    val our: T,
    val their: T
)

data class MergeResolution<T>(
    val element: T?,
    val caret: Caret?
)

data class MergeUpdatedResult<T>(
    val updated: List<T>,
    val conflicted: List<MergeConflict<T>>
)

data class AddResult<T>(
    val theirUpdated: List<T>,
    val addMerged: List<T>
)

fun <T: L2Element<T>> mergeAdded(ourAdd: List<T>, theirAdd: List<T>, theirUpdate: List<T>): AddResult<T> {
    val ourChains = PartialChain.getChains(ourAdd)
    val theirChains = PartialChain.getChains(theirAdd).toMutableSet()
    val merged = mutableListOf<T>()
    var theirU = theirUpdate.toMutableList()

    ourChains.forEach { ourChain ->
        if (ourChain.prevGuid == null) throw BugException("Found our ADD chain with prev = null")
        val theirChain = theirChains.find { it.prevGuid == ourChain.prevGuid }

//        console.log("our chain>")
//        ourChain.printCase("our")

        if (theirChain != null)  {
            theirChains -= theirChain

//            console.log("their chain>")
//            theirChain.printCase("their")
            val theirChainPrevId = theirChain.prevGuid
            // we both added blocks after same source block. Need to resolve order. First goes our chain
            ourChain.updateAndSetLinks(ourChain.last().copyListElement(next = theirChain.first().guid))
            theirChain.updateAndSetLinks(theirChain.first().copyListElement(prev = ourChain.last().guid))
            merged += ourChain.elements
            merged += theirChain.elements

            val updatedIndex = theirU.indexOfFirst { it.guid == theirChainPrevId }
            if (updatedIndex == -1) {
                // it's our
            } else {
                theirU[updatedIndex] = theirU[updatedIndex].copyListElement(prev = ourChain.last().guid)
            }
        } else merged += ourChain.elements
    }

    theirChains.forEach { merged += it.elements }

    return AddResult(theirU, merged)
}

fun <T: L2Element<T>> mergeDeleted(
    ourDeleted: List<T>,
    ourUpdated: List<T>,
    theirDeleted: List<T>,
    theirUpdated: List<T>
): List<T> {
    fun has(list: List<T>, b: T): Boolean {
        return list.find { it.guid == b.guid } != null
    }

    val our = ourDeleted.filter { !has(theirUpdated, it) } // don't delete block if they updated it

    return (our.toSet() + theirDeleted.toSet()).toList()
}

fun <T: L2Element<T>> mergeUpdated(
    source: List<T>,
    ourUpdated: List<T>,
    theirUpdated: List<T>
): MergeUpdatedResult<T> {
    val conflicts = mutableListOf<MergeConflict<T>>()
    val updateAsIs = mutableListOf<T>()

    ourUpdated.forEach { our ->
        val their = theirUpdated.find { it.guid == our.guid }

        if (their != null) {
            val sourceBlock = source.find { it.guid == our.guid }
                ?: throw BugException("Can't find source block to update")
            conflicts.add(MergeConflict(sourceBlock, our, their))
        }
        else updateAsIs.add(our)
    }

    theirUpdated.forEach { their ->
        if (ourUpdated.find { it.guid == their.guid } == null) updateAsIs.add(their)
    }

    return MergeUpdatedResult(
        updateAsIs,
        conflicts
    )
}

fun resolveParagraph(
    source: Fragment.Paragraph,
    our: Fragment.Paragraph,
    their: Fragment.Paragraph,
    caret: Caret?
): MergeResolution<Fragment.Paragraph> {
    var updatedParagraph = their

    // it can be just incoming update
    if (our != source) {
        val result = merge3(
            source.toMergeUnits("source"),
            their.toMergeUnits("their"),
            our.toMergeUnits("our")
        )

        val restored = restoreFragments(result.merged)

        if (restored.size != 1 || restored.first() !is Fragment.Paragraph)
            throw BugException("Incorrect merge result for Paragraph ${source.guid}")

        updatedParagraph = restored.first() as Fragment.Paragraph
    }

    var newCaret: Caret? = null

    if (caret != null && our.contains(caret)) {
        val offsetBefore = our.getOffset(caret)
        val correctOffset = min(updatedParagraph.lastOffset, offsetBefore)

        newCaret = updatedParagraph.caretAt(correctOffset)
    }

    return MergeResolution(updatedParagraph, newCaret)
}

enum class TableAxis {
    ROW,
    COL
}

fun isCellPresent(cell: TableCell, searchIn: TableVector): Boolean {
    return searchIn.vector.find { it.guid == cell.guid } != null
}

fun getVectorCompanion(
    vector: TableVector,
    searchIn: List<TableVector>
): TableVector? {
    var i = 0
    var isCellFound = false
    var companion: TableVector? = null
    while (i < searchIn.size && !isCellFound) {
        isCellFound = vector.vector.find { isCellPresent(it, searchIn[i]) } != null
        if (isCellFound) companion = searchIn[i]
        i++
    }

    return companion
}

data class TableVector(
    val axis: TableAxis,
    val vector: List<TableCell>,
    val variant: MergeVariant,
    override val guid: String,
    override val prevGuid: String?,
    override val nextGuid: String?,
) : L2Caret<TableVector> {
    override fun caretAt(guid: String, offset: Int): Caret {
        return caretAtEnd()
    }

    override fun caretAt(offset: Int, preferRight: Boolean): Caret {
        return caretAtEnd()
    }

    override fun caretAtEnd(): Caret {
        console.log("Call CARET AT END ${guid}")

        return vector.last().caretAtEnd().withParent(guid)
    }

    override fun getOffset(c: Caret): Int {
        return 0
    }

    override fun copyListElement(prev: String?, next: String?): TableVector {
        return copy(prevGuid = prev, nextGuid = next)
    }

    override fun contentEquals(other: TableVector): Boolean {
        if (vector.size != other.vector.size) return false
        var isEqual = true
        var i = 0

        while (isEqual && i < vector.size) {
            isEqual = isEqual && vector[i].contentEquals(other.vector[i])
            i++
        }

        return isEqual
    }

    fun printTest() {
        console.log("    TableVector guid=${guid} prev=${prevGuid} next=${nextGuid} variant=${variant.name} axis=${axis.name}")
        vector.forEach {
            val text = it.p.plainText.replace("\n", "")
            console.log("        TableCell guid=${it.guid} row=${it.row} col=${it.col} text=${text}")
        }
        console.log("    TableVector guid=${guid} END")
    }

    override fun contains(c: Caret?): Boolean {
        return vector.find { it.contains(c) } != null
    }
}

fun vectorId(name: String, index: Int): String {
    return "${name}-${index}"
}

fun getVectors(
    axis: TableAxis,
    variant: MergeVariant,
    data: List<List<TableCell>>
): List<TableVector> {
    val name = variant.name

    return data.mapIndexed { index, tableCells ->
        val guid = vectorId(name, index)
        val prevGuid = if (index == 0) null else vectorId(name, index - 1)
        val nextGuid = if (index == data.size - 1) null else vectorId(name, index + 1)

        TableVector(axis, tableCells, variant, guid, prevGuid, nextGuid)
    }
}

fun getSourceVectors(
    axis: TableAxis,
    sourceTable: Fragment.TableParagraph
): List<TableVector> {
    val dataSource = if (axis == TableAxis.ROW) sourceTable.getRows() else sourceTable.getCols()

    return getVectors(axis, MergeVariant.SOURCE, dataSource)
}

fun getVectors(
    axis: TableAxis,
    source: List<TableVector>,
    actualTable: Fragment.TableParagraph,
    actualVariant: MergeVariant
): List<TableVector> {
//    val dataSource = if (axis == TableAxis.ROW) sourceTable.getRows() else sourceTable.getCols()
    val dataActual = if (axis == TableAxis.ROW) actualTable.getRows() else actualTable.getCols()

//    val source = getVectors(axis, MergeVariant.SOURCE, dataSource)
    var actual = getVectors(axis, actualVariant, dataActual)

    actual = actual.map { a ->
        val sourceCompanion = getVectorCompanion(a, source)
        if (sourceCompanion != null) a.copy(guid = sourceCompanion.guid)
        else a
    }

    return PartialChain.fixLinks(actual, false)
}

fun getTableAUD(
    source: List<TableVector>,
    actual: List<TableVector>,
    caret: Caret? = null
): AUDTransaction<TableVector> {
    val added = actual.filter { a -> source.find { it.guid == a.guid } == null }
    val updated = actual.filter { a ->
        val sourceVector = source.find { it.guid == a.guid }

        if (sourceVector == null) false
        else {
            !sourceVector.contentEquals(a)
        }
    }
    val deleted = source.filter { s -> actual.find { it.guid == s.guid } == null }

    return AUDTransaction(
        caret,
        caret,
        added,
        updated,
        deleted
    )
}

fun resolveTable(
    source: Fragment.TableParagraph,
    our: Fragment.TableParagraph,
    their: Fragment.TableParagraph,
    caretBefore: Caret? = null
): MergeResolution<Fragment.TableParagraph> {
    val c = if (caretBefore?.blockId == source.guid) caretBefore else null
    val caretMap = mutableMapOf<TableAxis, Caret?>()
    val mergedMap = mutableMapOf<TableAxis, List<TableVector>>()

    val sourceMap = mutableMapOf<TableAxis, List<TableVector>>()
    val ourMap = mutableMapOf<TableAxis, List<TableVector>>()
    val theirMap = mutableMapOf<TableAxis, List<TableVector>>()
    var i = 0
    var isEmpty = false

    while (i < TableAxis.entries.size && !isEmpty) {
        val axis = TableAxis.entries[i]
        console.log("CALCULATED AXIS ${axis.name}")
        val sourceList = getSourceVectors(axis, source)

        console.log("SOURCE BEGIN")
        sourceList.forEach { it.printTest() }
        console.log("SOURCE END")

        val ourList = getVectors(axis, sourceList, our, MergeVariant.OUR)
        val theirList = getVectors(axis, sourceList, their, MergeVariant.THEIR)

        console.log("OUR BEGIN")
        ourList.forEach { it.printTest() }
        console.log("OUR END")

        console.log("THEIR BEGIN")
        theirList.forEach { it.printTest() }
        console.log("THEIR END")

        sourceMap[axis] = sourceList
        ourMap[axis] = ourList
        theirMap[axis] = theirList

        val axisCaret = c?.let { caret ->
            val cell = our.find(caret.path[1]) ?: throw BugException("can't find cell")
            val offset = (cell as Fragment.Paragraph).getOffset(caret)

            ourList.find { it.contains(caret) }?.let {
                caret.copy(path = listOf(it.guid) + caret.path, offset)
            }
        }

        val ourAUD = getTableAUD(sourceList, ourList, axisCaret)
        val theirAUD = getTableAUD(sourceList, theirList)

        val merged = merge(sourceList, ourAUD, theirAUD)
        val chain = Chain(sourceList)
        chain.applyAUDTransaction(merged)

        if (chain.elements.isEmpty()) isEmpty = true
        console.log("CALCULATED CARET", chain.caret?.toFullString())
        caretMap[axis] = chain.caret
        mergedMap[axis] = chain.elements
        i++
    }

    if (isEmpty) return MergeResolution(null, null)

    val rowMap = mergedMap[TableAxis.ROW] ?: throw BugException("Can't get row map")
    val colMap = mergedMap[TableAxis.COL] ?: throw BugException("Can't get col map")

    console.log("MERGED MAP: ROW")
    rowMap.forEach { it.printTest() }
    console.log("MERGED MAP: ROW END")
    console.log("MERGED MAP: COL")
    colMap.forEach { it.printTest() }
    console.log("MERGED MAP: COL END")

    /**
     *      row, column -> SOURCE, SOURCE  source    row <AND> source    column -> may be conflict
     *      row, column -> SOURCE, OUR     source    row <AND> our   NEW column -> our cell
     *      row, column -> SOURCE, THEIR   source    row <AND> their NEW column -> their cell
     *
     *      row, column -> OUR, SOURCE     our   NEW row <AND> source    column -> our cell
     *      row, column -> OUR, OUR        our   NEW row <AND> our   NEW column -> our cell
     *      row, column -> OUR, THEIR      our   NEW row <AND> their NEW column -> empty cell
     *
     *      row, column -> THEIR, SOURCE   their NEW row <AND> source    column -> their cell
     *      row, column -> THEIR, OUR      their NEW row <AND> our   NEW column -> empty cell
     *      row, column -> THEIR, THEIR    their NEW row <AND> their NEW column -> their cell
     */

    fun getColIndex(guid: String, map: MutableMap<TableAxis, List<TableVector>>): Int {
        val rows = map[TableAxis.COL] ?: throw BugException("no row data")

        return rows.indexOfFirst { it.guid == guid }
    }

    fun getRowIndex(guid: String, map: MutableMap<TableAxis, List<TableVector>>): Int {
        val cols = map[TableAxis.ROW] ?: throw BugException("no col data")

        return cols.indexOfFirst { it.guid == guid }
    }

    fun getCell(row: Int, col: Int, map: MutableMap<TableAxis, List<TableVector>>): TableCell {
        val rows = map[TableAxis.ROW] ?: throw BugException("no row data")
        return rows[row].vector[col]
    }

    fun getCell(row: String, col: String, map: MutableMap<TableAxis, List<TableVector>>): TableCell {
        val rowIndex = getRowIndex(row, map)
        val colIndex = getColIndex(col, map)

        console.log("GET CELL row=${row} col=${col} found indexes row=${rowIndex} col=${colIndex}")

        return getCell(rowIndex, colIndex, map)
    }

    var localCaret: Caret? = null

    val updatedCells = rowMap.map { row ->
        colMap.map { col ->
            when(row.variant) {
                MergeVariant.SOURCE -> {
                    when(col.variant) {
                        MergeVariant.SOURCE -> {
                            val ourCell = getCell(row.guid, col.guid, ourMap)
                            val theirCell = getCell(row.guid, col.guid, theirMap)
                            val sourceCell = getCell(row.guid, col.guid, sourceMap)

                            val result = resolveParagraph(sourceCell.p, ourCell.p, theirCell.p, c)

                            localCaret = localCaret ?: result.caret

                            result.element ?: Fragment.TableParagraph.emptyCell()
                        }
                        MergeVariant.OUR -> getCell(row.guid, col.guid, ourMap).p
                        MergeVariant.THEIR -> getCell(row.guid, col.guid, theirMap).p
                    }
                }
                MergeVariant.OUR -> {
                    when(col.variant) {
                        MergeVariant.SOURCE -> getCell(row.guid, col.guid, ourMap).p
                        MergeVariant.OUR -> getCell(row.guid, col.guid, ourMap).p
                        MergeVariant.THEIR -> Fragment.TableParagraph.emptyCell()
                    }
                }
                MergeVariant.THEIR -> {
                    when(col.variant) {
                        MergeVariant.SOURCE -> getCell(row.guid, col.guid, theirMap).p
                        MergeVariant.OUR -> Fragment.TableParagraph.emptyCell()
                        MergeVariant.THEIR -> getCell(row.guid, col.guid, theirMap).p
                    }
                }
            }
        }
    }

    val updated = source.copy(
        elements = updatedCells.flatten(),
        rowsCount = rowMap.size,
        colsCount = colMap.size
    )

    val lc = localCaret
    val newCaret = if (lc != null) updated.caretAt(lc.spanId, lc.offset) else {
        val rowCaret = caretMap.get(TableAxis.ROW)
        val colCaret = caretMap.get(TableAxis.COL)
        val rowPath = rowCaret?.path
        val colPath = colCaret?.path
        val rowVectorId = rowPath?.get(0)
        val colVectorId = colPath?.get(0)

        if (rowVectorId == null || colVectorId == null || c == null) null
        else {
            val cell = getCell(rowVectorId, colVectorId, mergedMap).p
            val actualCell = updated.find(cell.guid) as? Fragment.Paragraph ?: throw BugException("caret not in table")

            console.log("SET CARET!!!!!!!!!!!!!!!!! offset=${rowCaret.offset} cell=${actualCell.lastOffset} cellc = '${actualCell.plainText}'")
            val offset = min(rowCaret.offset, actualCell.lastOffset)
            actualCell.caretAt(offset)?.withParent(source.guid)
        }
    }

    return MergeResolution(
        updated,
        newCaret
    )
}

fun <T> resolve(conflict: MergeConflict<T>, caret: Caret?): MergeResolution<T> {
    return when(conflict.source) {
        is TableVector -> return resolveTableVector(conflict as MergeConflict<TableVector>, caret) as MergeResolution<T>
        is Block -> return resolveBlock(conflict as MergeConflict<Block>, caret) as MergeResolution<T>
        else -> {
            console.warn("Merging something abnormal")
            return MergeResolution(conflict.our, caret)
        }
    }
}

fun resolveTableVector(conflict: MergeConflict<TableVector>, caret: Caret?): MergeResolution<TableVector> {
    return MergeResolution(conflict.source, caret)
}

fun resolveBlock(conflict: MergeConflict<Block>, caret: Caret?): MergeResolution<Block> {
    val resolution = when(conflict.source.paragraph) {
        is Fragment.Paragraph -> resolveParagraph(
            conflict.source.paragraph,
            conflict.our.paragraph as Fragment.Paragraph,
            conflict.their.paragraph as Fragment.Paragraph,
            caret
        )
        is Fragment.TableParagraph -> resolveTable(
            conflict.source.paragraph,
            conflict.our.paragraph as Fragment.TableParagraph,
            conflict.their.paragraph as Fragment.TableParagraph,
            caret
        )
        else -> { throw BugException("Can't merge this IParagraph") }
    }

    val updated = resolution.element?.let { conflict.source.copy(it, revision = conflict.their.revision) }

    return MergeResolution(updated, resolution.caret)
}

fun <T: L2Caret<T>> merge(
    source: List<T>,
    our: List<T>,
    their: List<T>,
    ourCaret: Caret?
): AUDTransaction<T> {
    fun getTransaction(list: List<T>): AUDTransaction<T> {
        return AUDTransaction(
            null,
            ourCaret,
            list.filter { !source.map { it.guid }.contains(it.guid) },
            list.filter { provided ->
                val existing = source.find { it.guid == provided.guid }
                if (existing == null) false
                else {
                    !existing.contentEquals(provided) || existing.nextGuid != provided.nextGuid || existing.prevGuid != provided.prevGuid
                }
//                source.map { it.guid }.contains(provided.guid)

                        },
            source.filter { !list.map { it.guid }.contains(it.guid) }
        )
    }

    val ourTransaction = getTransaction(our)
    val theirTransaction = getTransaction(their)

    return merge(source, ourTransaction, theirTransaction)
}

fun <T: L2Caret<T>> merge(
    sourceList: List<T>,
    our: AUDTransaction<T>,
    their: AUDTransaction<T>
): AUDTransaction<T> {
    val caretAfter = our.caretAfter
    var caretNew: Caret? = null

    val addResult = mergeAdded(our.add, their.add, their.update)
    val mergedAdd = addResult.addMerged
    val theirUpdated = addResult.theirUpdated
    val mergedDelete = mergeDeleted(our.delete, our.update, their.delete, theirUpdated).toMutableList()
    val result = mergeUpdated(sourceList, our.update, theirUpdated)

    val updated = result.updated.filter { !mergedDelete.map { it.guid }.contains(it.guid) }.toMutableList()
    val conflicts = result.conflicted

    updated.filter { u -> our.update.find { it.guid == u.guid } == null }.forEach { u ->
        if (u.contains(caretAfter)) {
            val source = sourceList.find { it.guid == u.guid }

            source?.let { container ->
                caretAfter?.let { c ->
                    val offset = container.getOffset(c)
                    caretNew = u.caretAt(offset, c.offset == 0)
                }
            }
        }
    }

    conflicts.forEach {
        val resolution = resolve(it, caretAfter)
        if (resolution.element != null) {
            updated += resolution.element // here can be broken next/prev link from source element
            caretNew = caretNew ?: resolution.caret
        } else {
            mergedDelete += it.our
        }
    }

    fun caretToEnd(guid: String): Caret {
        val container = getFirstById(guid, listOf(updated, mergedAdd, sourceList))

        return container.caretAtEnd()
    }

    // it means old caret was in delete block. Find closest block to it and set caret at end
    if (caretAfter != null && caretNew == null) {
        val deleteChains = PartialChain.getChains(mergedDelete)
        val deleteChain = deleteChains.find { it.containerIndex(caretAfter) != null }
        if (deleteChain != null) {
            val escapeToId = deleteChain.prevGuid ?: deleteChain.nextGuid

            escapeToId?.let {
                val addChains = PartialChain.getChains(mergedAdd)
                val addChain = addChains.find { it.nextGuid == escapeToId }

                caretNew = if (addChain == null) caretToEnd(escapeToId)
                else {
                    val oldContainerIndex = deleteChain.containerIndex(caretAfter) ?:
                    throw BugException("Can't find caret in deleted chain")

                    val addChainIndex = min(oldContainerIndex, addChain.elements.size - 1)
                    val addChainGuid = addChain.elements[addChainIndex].guid
                    caretToEnd(addChainGuid)
                }
            }
        }
    }

    val tempChain = Chain(sourceList, our.caretBefore)
    val addChains = PartialChain.getChains(mergedAdd)
    addChains.forEach {
        tempChain.insertChain(it, it.first().prevGuid!!)
    }
    mergedDelete.forEach { tempChain.delete(it.guid) }
    val fixedUpdated = updated.map {
        val existing = tempChain.get(it.guid) ?: throw BugException("merge: update non-existing block")
        it.copyListElement(existing.prevGuid, existing.nextGuid)
    }

    val merged = AUDTransaction(
        our.caretBefore,
        caretNew ?: caretAfter,
        mergedAdd,
        fixedUpdated,
        mergedDelete
    )

    return merged
}

suspend fun Editor.applyIncomingChanges(document: IcwkDocument) {
    eventManager.withLock("dc.merge") {
        if (
            transactionMode != TransactionMode.INSTANT ||
            !isActive.value
            ) {

            return@withLock
        } else {
            if (saveManager.state != SaveState.IDLE) {
                saveManager.shouldProcessIncoming = true
                transactionLogger.log("save in progress, set flag")
                return@withLock
            } else {
                transactionLogger.log("apply incoming changes")
            }
        }

        document.merge { docBlockFlow ->
            val theirBlocks = docBlockFlow.toList()
                .filter { it.type == BlockType.Body }
                .mapNotNull { it.decode<Block>() }

            transactionLogger.log("INCOMING BLOCKS total=${theirBlocks.size}")

            runMergeTransaction(theirBlocks)
        }
    }
}

suspend fun Editor.runMergeTransaction(theirBlocks: List<Block>) {
    if (theirBlocks.isEmpty()) return

    val ourChain = editorChain ?: throw BugException("last saved chain not initialized")
    val ourTransaction = ourChain.getAUDTransaction()
    val isIncoming = ourTransaction.isEmpty()
    val name = if (!isIncoming) "INCOMING MERGE UPDATE" else "INCOMING SILENT UPDATE"

    replay.runMergeTransaction(theirBlocks)

    runTransaction(
        name,
        shouldSetDirty = !isIncoming,
        shouldResetChain = isIncoming
    ) { chain, transactionStyle ->
        val ourChain = editorChain
        val mergedChanges = getMergeTransaction(ourChain, theirBlocks)

        mergedChanges.printCase(name)
        val localChanges = editorChain.getAUDTransaction()
        localChanges.printCase("localChanges")
        val diff = mergedChanges - localChanges
        diff.printCase("diff")

        chain.applyAUDTransaction(diff)
    }
}

fun getTheirTransaction(source: List<Block>, incoming: List<Block>): AUDTransaction<Block> {
    val incomingAdd = incoming.filter { i -> source.find { it.guid == i.guid } == null }
    val incomingUpdate = incoming.filter { i -> source.find { it.guid == i.guid } != null }
    val incomingDelete = getIncomingDeleted(incoming, source)

    return AUDTransaction(
        null,
        null,

        incomingAdd,
        incomingUpdate,
        incomingDelete
    )
}

fun getIncomingDeleted(their: List<Block>, source: List<Block>): List<Block> {
    val theirChains = PartialChain.getChains(their)
    val sourceChain = Chain(source)
    val deleted = mutableListOf<Block>()

    theirChains.forEach { theirChain ->
        val prev = theirChain.prevGuid // source id or null
        val next = theirChain.nextGuid // source id or null

        // check in chain
        val sourcePart = sourceChain.getIn(prev, next)

        deleted += sourcePart.filter { s -> theirChain.elements.indexOfFirst { it.guid == s.guid } == -1 }
    }

    return deleted
}

fun getSourceToMerged(
    sourceBlocks: List<Block>,
    incomingBlocks: List<Block>,
    sourceToOur: AUDTransaction<Block>
): AUDTransaction<Block> {
    val sourceToTheir = getTheirTransaction(sourceBlocks, incomingBlocks)
    val merged = merge(sourceBlocks, sourceToOur, sourceToTheir)

    return merged
}

fun getMergeTransaction(ourChain: Chain<Block>, theirBlocks: List<Block>): AUDTransaction<Block> {
    val sourceBlocks = ourChain.initialElements
    val sourceToOur = ourChain.getAUDTransaction()

    return getSourceToMerged(sourceBlocks, theirBlocks, sourceToOur)
}

fun printDoc(source: List<Block>) {

    val lElements = source.map {
        var text = it.plainText
        if (text.endsWith('\n')) text = text.substring(0, text.length - 1)
        "DummyL2(prevGuid = \"${it.prevGuid}\", guid = \"${it.guid}\", nextGuid = \"${it.nextGuid}\", content=\"${text}\")"
    }.joinToString(",\n            ")

    console.log(""""
    // INITIAL DOC: 
    val source = Chain(listOf(
        ${lElements}
    ))
    """)
}