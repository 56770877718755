package controls

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.remember
import io.ktor.utils.io.*
import kotlinx.browser.window
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.SharedFlow
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import net.sergeych.mp_tools.globalDefer
import net.sergeych.mp_tools.globalLaunch
import org.jetbrains.compose.web.attributes.AttrsScope
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tools.UITheme
import tools.randomId
import kotlin.time.Duration.Companion.seconds


@Suppress("unused", "UNUSED_EXPRESSION")
object Toaster {
    @Suppress("unused")
    enum class Type(val cssClasses: String?, val cssDarkClasses: String? = null) {
        DEFAULT(null),
        WARNING("bg-warning-subtle text-danger", "bg-warning-subtle text-warning"),
        ERROR("bg-danger-subtle text-danger"),
        INFO("bg-info-subtle text-dark", "bg-info-subtle text-info"),
        SUCCESS("bg-success-subtle text-success")
        ;

        fun applyStyles(scope: AttrsScope<HTMLDivElement>) {
            val activeSet = if (UITheme.composeState.value.isDark)
                cssDarkClasses ?: cssClasses
            else
                cssClasses
            activeSet?.let {
                scope.classes(it.split(" "))
            }
        }

    }

    data class Item(
        val text: String,
        val title: String? = null,
        val type: Type = Type.DEFAULT,
        val stack: Boolean = true,
        val createdAt: Instant = Clock.System.now(),
        val id: String = randomId(5),
    ) {
        val isVisible: Boolean
            get() = Clock.System.now() < createdAt + 5.seconds
    }

    private val wflow = MutableSharedFlow<Item>(10)
    val flow: SharedFlow<Item> = wflow

    fun push(item: Item) {
        globalLaunch {
            wflow.emit(item)
        }
    }

    fun success(text: String, title: String? = null, stack: Boolean = true): Item =
        Item(text, title, Type.SUCCESS, stack).also { push(it) }

    fun info(text: String, title: String? = null, stack: Boolean = true): Item =
        Item(text, title, Type.INFO, stack).also { push(it) }

    fun error(text: String, title: String? = null, stack: Boolean = true): Item =
        Item(text, title, Type.ERROR, stack).also { push(it) }

    fun warning(text: String, title: String? = null, stack: Boolean = true): Item =
        Item(text, title, Type.WARNING, stack).also { push(it) }

    fun hide(id: String) {
        window.document.getElementById(id)?.setAttribute("style", "display: none;")
    }

    @Composable
    fun contents() {
        val toasts = remember { mutableStateListOf<Item>() }

        if (toasts.isNotEmpty()) {
            Div({
                classes("toast-container", "position-fixed", "bottom-0", "start-0", "p-3")
                style { property("z-index", 11000) }
            }) {
                for (item in toasts) {
                    Div({
                        id(item.id)
                        classes("toast", "show")
                    }) {
                        item.title?.let {
                            Div({
                                classes("toast-header", "fw-bold", "fs-6")
                                // seems to ignore parent classes
                                item.type.applyStyles(this)
                            }) { Text(it) }
                        }
                        Div({
                            classes("toast-body")
                            // so we apply it twice :(
                            item.type.applyStyles(this)
                        }) { Text(item.text) }
                    }
                }
            }
        }

        LaunchedEffect("toasterFlowCollector") {
            flow.collect {
                toasts.removeAll { !it.isVisible || !it.stack }
                toasts.add(it)
            }
        }
        LaunchedEffect("toasterKiller") {
            try {
                while (true) {
                    delay(500)
                    toasts.removeAll { !it.isVisible }

                }
            } catch (_: CancellationException) {
                // just close
            } catch (x: Throwable) {
                println("unexpected exception in toaster killer: $x")
            }
        }
    }

    fun error(t: Throwable) {
        error(t.toString(), "Внутренняя ошибка")
    }

    fun <T> runCatching(f: Toaster.() -> T) = try {
        f()
    } catch (t: Throwable) {
        error("Непредвиденная ошибка: $t")
        t.printStackTrace()
        null
    }

    suspend fun <T> runAsync(f: suspend Toaster.() -> T) = try {
        f()
    } catch (ce: CancellationException) {
        throw ce
    } catch (t: Throwable) {
        error("Непредвиденная ошибка: $t")
        t.printStackTrace()
        null
    }

    fun <T> defer(onFinally: (() -> Unit)? = null, f: suspend Toaster.() -> T): Deferred<T?> =
        globalDefer {
            try {
                f()
            } catch (ce: CancellationException) {
                throw ce
            } catch (t: Throwable) {
                error("Непредвиденная ошибка: $t")
                t.printStackTrace()
                null
            } finally {
                onFinally?.invoke()
            }
        }

    fun <T> launchCatching(onFinally: (() -> Unit)? = null, f: suspend Toaster.() -> T): Job =
        globalLaunch {
            try {
                f()
            } catch (ce: CancellationException) {
                throw ce
            } catch (t: Throwable) {
                error("Непредвиденная ошибка: $t")
                null
            } finally {
                onFinally?.invoke()
            }
        }
}