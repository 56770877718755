package views

import DocsCache
import androidx.compose.runtime.*
import controls.*
import kotlinx.coroutines.launch
import modalDialog
import net.sergeych.intecowork.api.DocType
import net.sergeych.intecowork.doc.IcwkDocument
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Label
import org.w3c.dom.HTMLDivElement

interface SelectFolderScope {
    val selectedFolderId: Long?
    val isCancelled: Boolean
    fun close()
    fun showError(message: String)
}

/**
 * UI to select folder
 *
 */
fun selectFolder(
    initialFolderId: Long?,
    prompt: String = "выберите папку",
    @Suppress("UNUSED_PARAMETER") attrs: AttrBuilderContext<HTMLDivElement>? = null,
    onSelected: SelectFolderScope.() -> Unit
) {
    modalDialog {
        body {
            var errorMessage by remember { mutableStateOf<String?>(null) }
            val path = remember { mutableStateListOf<IcwkDocument?>() }
            val currentFolderContent = remember { mutableStateListOf<IcwkDocument>() }
            var ready by remember { mutableStateOf(false) }
            val scope = rememberCoroutineScope()
            var currentId by remember { mutableStateOf<Long?>(initialFolderId) }
            var selectedId by remember { mutableStateOf<Long?>(initialFolderId) }

            LaunchedEffect(currentId) {
                path.clear()
                ready = false
                scope.launch {
                    var f = currentId?.let { DocsCache.getDoc(it) }
                    currentId = f?.docId
                    while (f != null) {
                        path.add(0, f)
                        f = f.parentId?.let { DocsCache.getDoc(it) }
                    }
                    path.add(0, null)
                    ready = true
                }
            }
            LaunchedEffect("cf", currentId) {
                launch {
                    DocsCache.openFolder(currentId).collect {
                        currentFolderContent.clear()
                        currentFolderContent.addAll(it)
                    }
                }
            }
            fun callHandler(ok: Boolean) {
                onSelected(object : SelectFolderScope {
                    override val selectedFolderId: Long?
                        get() = selectedId
                    override val isCancelled: Boolean
                        get() = !ok
                    override fun close() {
                        this@modalDialog.close()
                    }
                    override fun showError(message: String) {
                        errorMessage = message
                    }
                })
            }
            Label(attrs={ classes("mb-1")}) { +"$prompt:" }

            Div({
                style {
                    maxHeight(60.vh)
                    overflow("auto")
                }
                classNames("border rounded p-2")
            }) {
                if (!ready) {
                    Icon.ArrowRepeat.render(0.9.em, "d-inline", "me-0", "pe-0", "spin2")
                }
                for ((i, d) in path.withIndex()) {
                    Div({
                        style {
                            paddingLeft(1.em * (i + 1))
                        }
                    }) {
                        val isLast = i == path.lastIndex
                        Di("cursor-pointer", {
                            onClick {
                                currentId = d?.docId
                                selectedId = currentId
                            }
                            onDoubleClick {
                                currentId = d?.docId
                                selectedId = currentId
                                callHandler(true)
                                it.stopPropagation()
                                it.preventDefault()
                            }
                            if (selectedId == d?.docId) {
                                classes("selected-tree")
                            }
                        }) {
                            Icon.FolderOpen.render(1.em, "p-0", "m-0", "me-1")
                            +(d?.titleOrDefault ?: "/")
                        }
                        if (isLast) {
                            for (f in currentFolderContent.filter { it.type == DocType.Folder }) {
                                Div({
                                    style {
                                        paddingLeft(1.em)
                                    }
                                    classes("cursor-pointer")
                                    onClick {
                                        currentId = f.docId
                                        selectedId = currentId
                                    }
                                    onDoubleClick {
                                        currentId = f.docId
                                        selectedId = currentId
                                        callHandler(true)
                                        it.stopPropagation()
                                        it.preventDefault()
                                    }
                                }) {
                                    Icon.FolderFill.render(1.em, "p-0", "m-0", "me-1")
                                    +f.titleOrDefault
                                }
                            }
                        }
                    }
                }
            }
            Di("mt-3") {
                Btn("OK") {
                    callHandler(true)
                }
                Btn("Отмена", variant = Variant.Secondary, cls = "float-end") {
                    callHandler(false)
                }
            }
        }
    }
}