package net.sergeych

import net.sergeych.TokenMatch.score
import kotlin.math.abs


/**
 * Analyze text as token stream fir matches, ranged by number of hits (best if all the tokens present)
 * and distance (the closer the better). See [score]
 */
object TokenMatch {
    private val delimiters = " .,!?:;-/()[]{}".map { it.toString() }.toTypedArray()

    /**
     * Split string to tokens list and return a map of tokens and its sequential numbers.
     * All token entries are numbered from begin to end.
     *
     * Tokens are case-insensitive.
     *
     * @return map of tokens and their sequential numbers, where 0 means the first token found in the text
     */
    fun tokenize(_text: String): Map<String, List<Int>> {
        val text: CharSequence = _text.lowercase()
        val found = mutableMapOf<String, MutableList<Int>>()

        var index = 0
        for (word in text.split(*delimiters)) {
            val key = word.trim()
            if (word != "") {
                found.getOrPut(key) { mutableListOf() } += index
                index++
            }
        }
        return found.toMap()
    }

    /**
     * Match score: the higher, the better. Score is higher if count is bigger then minDistance is smaller.
     *
     * Can be used as a hash key.
     */
    data class Score(val count: Int, val minDistance: Int) : Comparable<Score> {
        override fun compareTo(other: Score): Int {
            val r = count.compareTo(other.count)
            if (r != 0) return r
            else return other.minDistance.compareTo(minDistance)
        }

        override fun equals(other: Any?): Boolean {
            if (this === other) return true
            if (other == null || this::class != other::class) return false

            other as Score

            if (count != other.count) return false
            if (minDistance != other.minDistance) return false

            return true
        }

        override fun hashCode(): Int {
            var result = count
            result = 31 * result + minDistance
            return result
        }

        @Suppress("unused")
        val isEmpty = count == 0

        companion object {
            val Zero = Score(0, 0)
        }
    }

    /**
     * Calculate match score for the text and the list of words. The best score
     *
     * @param text text to match
     * @param words list of words to match
     * @return match score, e.g., the best [Score] for the text and the list of words among
     *          all found combinations.
     */
    fun score(text: String, words: Collection<String>, matchPrefix: Boolean = true): Score {
        val tokens = tokenize(text)
        val indices = mutableListOf<List<Int>>()
        for (word in words) {
            val t = if (matchPrefix) {
                tokens.entries.filter { it.key.startsWith(word) }.map { it.value }.flatten()
                    .let { if (it.isEmpty()) null else it }
            } else
                tokens[word]
            indices += t ?: continue
        }
        when (indices.size) {
            0 -> return Score.Zero
            1 -> return Score(1, 0)
        }
        var minDistance = Int.MAX_VALUE
        val other = indices.drop(1)
        for (i0 in indices.first()) {
            val closest = mutableListOf(i0)
            for (x in other) {
                closest += x.minBy { abs(it - i0) }
            }
//            println("closest: $closest")
            val d = closest.max() - closest.min()
            if (d < minDistance) minDistance = d
        }
        return Score(indices.size, minDistance)
    }
}