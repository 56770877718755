package editor.operations

import document.*
import editor.*
import editor.plugins.replay
import editor.views.CaretId
import kotlinx.serialization.Serializable

val transactionLogger = PackageLogger("transaction", "SteelBlue", DebugList.transaction)

fun <T: L2Element<T>> getById(id: String, list: List<T>): T? {
    return list.find { it.guid == id }
}

fun <T: L2Element<T>> getFirstById(id: String, searchPriority: List<List<T>>): T {
    var found: T? = null

    var i = 0
    while (i < searchPriority.size && found == null) {
        found = searchPriority[i].find { it.guid == id }
        i++
    }

    if (found == null) throw BugException("Can't find block in transaction sum")

    return found
}

fun <T: L2Element<T>> Chain<T>.getTransaction(): RevertableTransaction<T> {
    val redo = getAUDTransaction()
    val undo = getRevertAUDTransaction()

    return RevertableTransaction(redo, undo)
}

fun <T: L2Element<T>> Chain<T>.getAUDTransaction(): AUDTransaction<T> {
    val caretBefore = initialCaret
    val caretAfter = caret

    return AUDTransaction(
        caretBefore,
        caretAfter,

        getInserted(),
        getUpdated(),
        getDeleted()
    )
}

fun <T: L2Element<T>> Chain<T>.getRevertAUDTransaction(): AUDTransaction<T> {
    val caretBefore = initialCaret
    val caretAfter = caret

    val updatedIDs = getUpdated().map { it.guid }
    val initial = initialElements.filter { updatedIDs.contains(it.guid) }

    if (updatedIDs.size != initial.size)
        throw BugException("Can't find update block in initial list")

    return AUDTransaction(
        caretAfter,
        caretBefore,

        getDeleted(),
        initial,
        getInserted()
    )
}

fun <T: L2Element<T>> Chain<T>.apply(other: Chain<T>) {
    applyAUDTransaction(other.getAUDTransaction())
}

fun <T: L2Element<T>> Chain<T>.applyAUDTransaction(t: AUDTransaction<T>) {
    val addChains = PartialChain.getChains(t.add)

    addChains.forEach {
        val prevId = it.elements.first().prevGuid ?: throw BugException("trying to add another root block")
        insertChain(it, prevId)
    }

    t.delete.forEach {
        delete(it.guid)
    }

    t.update.forEach {
        update(it)
    }

    if (t.caretAfter != null) caret = t.caretAfter
    else {
        // escape caret
    }
}

fun String.snakeToCamelCase(): String {
    val pattern = "_[a-z]".toRegex()
    return replace(pattern) { it.value.last().uppercase() }
}

fun AUDTransaction<Block>.printCase(name: String) {
    val varname = name.split(' ').joinToString("_").snakeToCamelCase()

    fun printList(l: List<Block>): String {
        if (l.size == 0) return """
        listOf(),
        """.trimIndent()
        val lElements = l.map {
            var text = it.plainText
            if (text.endsWith('\n')) text = text.substring(0, text.length - 1)
            "DummyL2(prevGuid = \"${it.prevGuid}\", guid = \"${it.guid}\",  nextGuid = \"${it.nextGuid}\", content=\"${text}\")"
        }.joinToString(",\n            ")

        return """listOf(
            ${lElements}
        ),
        """.trimIndent()
    }

//    if (isEmpty()) {
//        transactionLogger.log("    // AUD TRANSACTION [${name}]: EMPTY")
//        return
//    }

    console.log("""
    // AUD TRANSACTION [${name}]: 
    val ${varname} = AUDTransaction.create(
        ${caretBefore?.replay() ?: "null"},
        ${caretAfter?.replay() ?: "null"},
        ${printList(add)}
        ${printList(update)}
        ${printList(delete)}
    )
        """)
}

@Serializable
data class AUDTransaction<T: L2Element<T>>(
    val caretBefore: Caret? = null,
    val caretAfter: Caret? = null,

    val add: List<T>,
    val update: List<T>,
    val delete: List<T>,
) {
    /*
     * A - blocks to add
     * U - blocks to update
     * D - blocks to delete
     *
     * Sum of two AUD transactions T3 = T1 + T2 will be:
     *
     * A3 = (A1 + A2) - (D1 + D2) // version priority: if element both in A1 and U2, take U2 version
     * U3 = (U1 + U2) - (A1 + A2) // version priority: if element both in U1 and U2, take U2 version
     * D3 = (D1 + D2) - (A1 + A2)
     */
    operator fun plus(other: AUDTransaction<T>): AUDTransaction<T> {
        val a1 = add.map { it.guid }.toSet()
        val u1 = update.map { it.guid }.toSet()
        val d1 = delete.map { it.guid }.toSet()
        val a2 = other.add.map { it.guid }.toSet()
        val u2 = other.update.map { it.guid }.toSet()
        val d2 = other.delete.map { it.guid }.toSet()

        val aSum = a1 + a2
        val uSum = u1 + u2
        val dSum = d1 + d2

        val a3 = aSum - dSum
        val u3 = uSum - aSum - dSum
        val d3 = dSum - aSum

        return AUDTransaction<T>(
            caretBefore,
            other.caretAfter,

            a3.map { getFirstById(it, listOf(other.update, other.add, add)) },
            u3.map { getFirstById(it, listOf(other.update, update)) },
            d3.map { getFirstById(it, listOf(other.delete, delete)) }
        )
    }

    /*
     * STATE1 = SOURCE + T1 - saved state
     * STATE2 = SOURCE + T3 - new state after STATE1 is saved
     *
     * T3 = T1 + T2
     *
     * In case we saved STATE1 and then got STATE2 in editor, we need to save
     * calculated difference (T2)
     *
     * A2 = (A3 - A1) + (U3 - D1)
     * U2 = U3 - (U3 - D1) // need to filter U3 elements if U1 contains same element with same version
     * D2 = D3 - D1
     */
    operator fun minus(t1: AUDTransaction<T>): AUDTransaction<T> {
        val logger = AlgoLogger(transactionLogger, "minus", "blue")
        logger.log("begin")

        // blocks that was definitely added in T2
        var definitelyT2Added = add.filter { sumEl -> t1.add.firstOrNull { it.guid == sumEl.guid } == null }
        // blocks that was deleted in T1 but added back in T2 with different content
        val updatedT3DeletedT1 = update.filter { t1.delete.map { it.guid }.contains(it.guid) }

        val updatedT3ExistingT1 = update.filter { !updatedT3DeletedT1.map { it.guid }.contains(it.guid) }

        val addT2 = definitelyT2Added + updatedT3DeletedT1
        var updateT2 = update.filter { updatedT3ExistingT1.map { it.guid }.contains(it.guid) }
        val deletedT2 = delete.filter { sumEl -> t1.delete.firstOrNull { it.guid == sumEl.guid } == null }
        updateT2 = updateT2.filter { candidate ->
            val t1Block = t1.update.find { it.guid == candidate.guid }
            t1Block == null || !t1Block.contentEquals(candidate) || t1Block.prevGuid != candidate.prevGuid || t1Block.nextGuid != candidate.nextGuid
        }

        val result = AUDTransaction(
            t1.caretAfter,
            caretAfter,

            addT2,
            updateT2,
            deletedT2
        )
        result.print("result")
        logger.success("done")

        // T2
        return result
    }

    fun getRevertTransaction(oldBlocks: List<T>): AUDTransaction<T> {
        return AUDTransaction(
            caretAfter,
            caretBefore,

            delete,
            update.map { getById(it.guid, oldBlocks) ?: throw BugException("no updated block in document") },
            add
        )
    }

    fun print(name: String) {
        fun prefixed(m: String) {
            console.log("[AUD ${name}]: ${m}")
        }
        prefixed("BEGIN")
        add.forEach {
            prefixed("INSERT ${it.guid} -> ${it.nextGuid} <- ${it.prevGuid}")
        }

        delete.forEach {
            prefixed("DELETE ${it.guid} -> ${it.nextGuid} <- ${it.prevGuid}")
        }

        update.forEach {
            prefixed("UPDATE ${it.guid} -> ${it.nextGuid} <- ${it.prevGuid}")
        }
        prefixed("END")
    }

    fun printShort(name: String) {
        console.log(""""
            AUD TRANSACTION [${name}]: 
            add=${add.joinToString(", ") { it.guid }}
            update=${update.joinToString(", ") { it.guid }}
            delete=${delete.joinToString(", ") { it.guid }}
            caretBefore=${caretBefore?.toFullString()}
            caretAfter=${caretAfter?.toFullString()}
        """)
    }

    fun isEmpty(): Boolean {
        return add.isEmpty() && update.isEmpty() && delete.isEmpty()
    }
}

data class RevertableTransaction<T: L2Element<T>>(
    val redo: AUDTransaction<T>,
    val undo: AUDTransaction<T>
) {
    operator fun plus(other: RevertableTransaction<T>): RevertableTransaction<T> {
        return RevertableTransaction(
            redo + other.redo,
            other.undo + undo
        )
    }
}

suspend fun EventManager.runTransaction(
    name: String,
    transaction: AUDTransaction<Block>,
    shouldSetDirty: Boolean = true,
    debug: Boolean = false
) {
    val localLogger = AlgoLogger(transactionLogger, "$name [DOC]")
//    fun log(message: String, isWarn: Boolean = false) {
//        if (!debug) return
//        val msg = "=================== [$name][DOC]: $message"
//        if (isWarn) console.warn(msg)
//        else console.log(msg)
//    }

    withLock("doc.runTransaction") {
//        log("BEGIN")
        val caretAfter = transaction.caretAfter ?: throw BugException("no caret after transaction")
        val add = PartialChain.getChains(transaction.add)
        val delete = PartialChain.getChains(transaction.delete)

        add.forEach {
            it.elements.forEach {
//                log("insert block ${it.guid} after ${it.prevBlockGuid}")
//                log("DOC BEFORE S")
                allBlocks.toList().forEach {
//                    log("${it.prevGuid} <- ${it.guid} -> ${it.nextGuid}")
                }
//                log("DOC BEFORE E")
                insertBlockAfter(it.prevBlockGuid, it, shouldSetDirty)
            }
        }

        delete.forEach {
            it.elements.reversed().forEach {
//                log("remove block ${it.guid}")
//                log("DOC BEFORE S")
                allBlocks.toList().forEach {
//                    log("${it.prevGuid} <- ${it.guid} -> ${it.nextGuid}")
                }
//                log("DOC BEFORE E")
                removeBlockSafe(it.guid, shouldSetDirty)
            }
        }

        transaction.update.forEach {
//            log("update block ${it}")
//            log("DOC BEFORE S")
            allBlocks.toList().forEach {
//                log("${it.prevGuid} <- ${it.guid} -> ${it.nextGuid}")
            }
//            log("DOC BEFORE E")
            updateBlockSafe(it, shouldSetDirty)
        }

        val caretBlock = this[caretAfter.blockId]

        localLogger.log("Send caret block update:")
        caretBlock.getTextFragments().forEach {
            localLogger.log("Block ${caretBlock.guid}: ss: ${it.guid} -> '${it.text}'")
        }
        localLogger.log("caret -> ${caretAfter.toFullString()}")

        sendCaretBlockUpdate(caretBlock, caretAfter)

//        log("END")
    }
}

suspend fun Editor.runTransaction(
    name: String = "",
    canUndo: Boolean = true,
    debug: Boolean = DebugList.transaction,
    shouldSetDirty: Boolean = true,
    shouldResetChain: Boolean = false,
    shouldForceRecord: Boolean = false,
    isReplayForcedUndo: Boolean = false,
    fn: suspend (chain: DocChain, transactionStyle: TextStyle?) -> Unit
) {
    val localLogger = AlgoLogger(transactionLogger, name)
//    fun log(message: String, isWarn: Boolean = false) {
//        if (!debug) return
//        val msg = "=================== [$name]: $message"
//        if (isWarn) console.warn(msg)
//        else console.log(msg)
//    }

    eventManager.withLock("dc.runTransaction") {
        localLogger.log("run with shouldSetDirty = $shouldSetDirty")

        val chain = DocChain(eventManager.allBlocksCopy, caret, name, debug)
        val offsetBefore = chain.caretOffset()
//        val debugChain = DocChain(chain.initialElements, caret, name, debug)
        var isOk = true

        try {
            fn(chain, nextTransactionStyle)
            if (chain.elements.isEmpty() || !chain.isLinked()) throw BugException("Broken chain")
            resetNextTransactionStyle()
            localLogger.log("Apply transaction to last saved chain")
//            lastSavedChain?.printCase("ls before")
//            chain.getAUDTransaction().printCase("ls transaction")
            editorChain.apply(chain)
//            lastSavedChain?.printCase("ls after")
        } catch(e: Throwable) {
            isOk = false
//            console.error(e)
            localLogger.error("ERROR: ${e.message}")
            localLogger.error(e)
            setIsActive(false)

            replay.onError(e.toString() + "\n" + e.stackTraceToString(), e)
        }

        if (isOk && chain.isChanged()) {
            val transaction = chain.getAUDTransaction()
            replay.finishOperation(transaction)

//            if (debug) transaction.printCase(name)
            val caretBefore = transaction.caretBefore ?: throw BugException("no caret before transaction")
            val caretAfter = transaction.caretAfter ?: throw BugException("no caret after transaction")

            if (canUndo) {
                if (isReplay) {
                    undoManager.record(isReplayForcedUndo, chain)
                    replayUndoForce = false
                }
                else undoManager.record(shouldForceRecord, chain)
            }
            else localLogger.log("this transaction can't be reverted")

            // DEBUG
//            domObserver.watch(doc.firstBlock.guid)

            chain.inserted.forEach { domObserver.watch(it, "runTransaction: inserted") }
            chain.updated.forEach { domObserver.watch(it, "runTransaction: updated") }
            chain.deleted.forEach { domObserver.cancelJobs(it) }
            if (caretBefore != caretAfter && showCaret)
                domObserver.watch(CaretId, "runTransaction: caret")


//            console.log("APPLY TO DOC! before")
//            logBlocks(doc.allBlocksCopy)
//            transaction.printCase("modifyDoc")
            eventManager.runTransaction(name, transaction, shouldSetDirty, debug)
//            console.log("APPLY TO DOC! after")
//            logBlocks(doc.allBlocksCopy)

            val offsetAfter = chain.caretOffset()

            if (offsetAfter > offsetBefore) lastMove = lastMove.copy(direction = MoveDirection.DOWN)
            else lastMove = lastMove.copy(direction = MoveDirection.UP)

            val escaped = escapeCaret(chain.caret)

            caret = escaped ?: throw BugException("Empty caret after transaction")

            if (shouldResetChain) {
//                console.log("RESET LAST SAVED CHAIN TO")
                editorChain = DocChain(eventManager.allBlocksCopy, escaped, name="editorChain")
//                lastSavedChain?.printCase("New last saved chain")
                replay.init(eventManager.allBlocksCopy, escaped)
            }
            localLogger.success("DONE")
        } else {
            localLogger.log("ABORTED ${if (isOk) "(EMPTY)" else "(ERROR)"}")
        }
    }
}
