package views

import androidx.compose.runtime.*
import controls.Spa
import controls.classNames
import controls.textField
import editor.Editor
import kotlinx.browser.window
import net.sergeych.intecowork.api.ApiEvent
import net.sergeych.mp_tools.globalLaunch
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.dom.Span
import org.w3c.dom.HTMLInputElement

@Composable
fun EditableTitle(context: Editor) {

    val doc = context.document!!
    // doc is this untracked by compose so we use mutableStateOf:
    var title by remember { mutableStateOf(doc.titleOrDefault) }
    var noname by remember { mutableStateOf(doc.title == null) }
    var editMode by remember { mutableStateOf(false) }
    var titleText by remember { mutableStateOf(doc.titleOrDefault) }

    DisposableEffect(true) {
        val saveState = context.isActive.value
        onDispose {
            context.setIsActive(saveState)
        }
    }

    LaunchedEffect(doc.docId) {
        doc.events().collect {
            when (it) {
                is ApiEvent.Doc.MetaChanged, is ApiEvent.Doc.BodyChanged -> {
                    title = doc.titleOrDefault
                    titleText = doc.titleOrDefault
                    noname = doc.title == null
                }

                else -> {}
            }
        }
    }

    fun close(save: Boolean) {
        if (save)
            globalLaunch {
                val t= titleText.trim()
                if( t == "" ) titleText = title
                else doc.setTitle(t)
            }
        editMode = false
        context.setIsActive(true)
    }

    if (editMode) {
        Spa("d-inline-block ms-1 me-1") {
            val id = textField(
                titleText,
                onBlur = { close(true) },
                attrs = {
                    onKeyDown {
                        when (it.getNormalizedKey()) {
                            "Enter" -> {
                                close(true)
                                it.stopPropagation()
                                it.preventDefault()
                            }

                            "Escape" -> {
                                close(false)
                                it.stopPropagation()
                                it.preventDefault()
                            }
                        }
                    }
                }) {
                titleText = it
            }
            LaunchedEffect(id) {
                (window.document.getElementById(id) as? HTMLInputElement)?.focus()
            }
        }
    } else {
        Span({
            style {
                cursor("pointer")
            }
            onClick {
                editMode = true
                titleText = title
                context.setIsActive(false)
            }
        }) {
            if (noname)
                Span({ classNames("text-muted") }) { +title }
            else
                +title
        }
    }
}