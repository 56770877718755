package views

import DocsCache
import Folder
import androidx.compose.runtime.*
import controls.Icon
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.dom.Span

@Composable
fun ShowPath(folderId: Long?, onClick: (Folder) -> Unit = {}) {
    var folders by remember { mutableStateOf<List<Folder>?>(null) }

    LaunchedEffect(folderId) {
        launch {
            var id = folderId
            val ff = mutableStateListOf<Folder>()
            ff.clear()
            while (id != null) {
                ff.add(
                    0,
                        Folder(id, DocsCache.getDoc(id)
                            ?.also {
                                id = it.parentId
                            }
                            ?.titleOrDefault ?: "???"
                        )
                )
            }
            ff.add(0,Folder(id, "/"))
            folders = ff
        }
    }

    folders?.let { ff ->
        for ((i, f) in ff.withIndex()) {
            if (i > 1) +" /"
            Span({
                classes("cursor-pointer")
                onClick {
                    it.preventDefault()
                    it.stopPropagation()
                    onClick(f)
                }
            }) {
                +" ${f.name}"
            }
        }
    }
    if( folders == null || folders?.size == 0)
        Icon.ArrowRepeat.render("spin2")
}