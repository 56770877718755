package controls

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLTableCellElement
import org.w3c.dom.HTMLTableElement
import views.unaryPlus


object PropTableBuilder {

    @Composable
    fun p(name: String,value: String) {
        p(name) { Text(value) }
    }

    @Composable
    fun p(name: String,builder: ContentBuilder<HTMLTableCellElement>) {
        Tr {
            Td({
                classNames("d-none d-sm-table-cell text-end pe-3 py-2 align-middle fw-bold")
                style {
                    width(30.percent)
                }
            }) { +name }
            Td {
                Di("d-none d-sm-block") { builder() }
                Di("d-sm-none") {
                    if (name.isNotBlank())
                        Di("w-100 border-top border-secondary my-2") {
                            Di("w-100 text-end fw-bold mb-2") { +name }
                            builder()
                        }
                    else {
                        Di("mt-2") { builder() }
                    }
                }
            }
        }
    }
}

@Composable
fun Props(attrs: AttrBuilderContext<HTMLTableElement>?=null, handler: @Composable PropTableBuilder.()->Unit) {
    Table({
        classes("p-2")
        style {
            width(100.percent)
        }
        attrs?.invoke(this)
    }) {
        Tbody {
            PropTableBuilder.handler()
        }
    }
}
