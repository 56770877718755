package net.sergeych.collections

import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import net.sergeych.mptools.withReentrantLock
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

/**
 * MRU cache with expiration, with safe async concurrent access.
 *
 * Expired items are removed when accessing the map, when reading values or when putting
 * it when [maxCapacity] is reached. See note about freeing resources below.
 *
 * It is much like [Map] and [MutableMap], but using suspend functions now limit usage of
 * operator functions so we are not implementing it. Also, modification with [entries] is
 * not allowed.
 *
 * Unlike [MRUCache], it drops expired values. Removing expired item is lazy, actual resource
 * freeing could be delayed. To force actual removal use [cleanup].
 *
 * @param lifeTime how long the value should be kept
 * @param maxCapacity if set, limits the capacity. Least Recent Used elements would be dropped
 *      to fit this parameter.
 * @param onItemRemoved called when some item is removed for any reason (e.g. expiration or overwriting).
 *      Note that this call also suspends put variants until done
 */
class ExpirableAsyncCache<K, V>(
    val lifeTime: Duration = 30.seconds,
    val maxCapacity: Int? = null,
    val onItemRemoved: (suspend (V) -> Unit)? = null
) {

    class Slot<V>(
        var value: V,
        var lastUsedAt: Instant = Clock.System.now(),
    )

    private val access = Mutex()

    private val cache = mutableMapOf<K, Slot<V>>()

    suspend fun get(key: K): V? {
        return access.withReentrantLock {
            cache.get(key)?.let {
                val now = Clock.System.now()
                println("lifetime $key: ${now - it.lastUsedAt}")
                if (now - it.lastUsedAt > lifeTime) {
                    cache.remove(key)
                    onItemRemoved?.invoke(it.value)
                    null
                } else {
                    it.lastUsedAt = now
                    it.value
                }
            }
        }
    }

    /**
     * Put the value for key. Calls [onItemRemoved] if needed.
     * @return previous value or null
     */
    suspend fun put(key: K, value: V): V? {
        // insert may replace existing item, so we do it first:
        return access.withLock {
            cache[key]?.let {
                if (value != it.value)
                    onItemRemoved?.invoke(it.value)
                val oldValue = it.value
                it.value = value
                it.lastUsedAt = Clock.System.now()
                oldValue
            } ?: run {
                // overflow could be caused by put, so put first
                cache.put(key, Slot(value))
                // now check size
                fixSize()
                null
            }
        }
    }

    private suspend fun fixSize() {
        maxCapacity?.let {
            if (it >= cache.size) {
                cache.remove(cache.minBy { it.value.lastUsedAt }.key)
                    ?.also { onItemRemoved?.invoke(it.value) }
                    ?.value
            }
        }
    }

    /**
     * Remove all expired elements. This function is not needed unless you
     * want to free resources associated with expired elements immediately.
     *
     * [onItemRemoved] is called for each removed item before returning.
     */
    @Suppress("unused")
    suspend fun cleanup() {
        access.withLock {
            val d = Clock.System.now()
            for( e in cache.entries.toList()) {
                if( d - e.value.lastUsedAt > lifeTime )
                    cache.remove(e.key)
            }
        }
    }

    suspend fun getOrDefault(key: K, value: V): V = get(key) ?: value

    /**
     * Atomically get or put value to the cache.
     *
     * If there is expired existing value, [onItemRemoved] will be called for it
     * before assigning new value.
     */
    suspend fun getOrPut(key: K, defaultValue: suspend () -> V): V {
        return access.withLock {
            cache[key]?.let {
                if( Clock.System.now() - it.lastUsedAt > lifeTime) {
                    onItemRemoved?.invoke(it.value)
                }
                it.lastUsedAt = Clock.System.now()
                it.value
            } ?: run {
                val v = defaultValue()
                cache[key] = Slot(v)
                fixSize()
                v
            }
        }
    }

    data class Entry<K, V>(override val key: K, override val value: V) : Map.Entry<K, V>

    val entries: Set<Map.Entry<K, V>>
        get() = cache.entries.map { Entry(it.key, it.value.value) }.toSet()

    val keys: Set<K>
        get() = cache.keys

    val size: Int
        get() = cache.size

    @Suppress("unused")
    val values: Collection<V>
        get() = cache.values.map { it.value }

    @Suppress("unused")
    fun isEmpty(): Boolean = cache.isEmpty()

    @Suppress("unused")
    fun containsValue(value: V): Boolean {
        for (v in cache.values)
            if (v.value == value) return true
        return false
    }

    @Suppress("unused")
    fun containsKey(key: K): Boolean = key in cache

    operator fun contains(k: K): Boolean = k in cache
}

