package controls

import Browser
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import kotlinx.browser.document
import kotlinx.coroutines.delay
import net.sergeych.mp_tools.globalLaunch
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tools.randomId
import tools.trimMiddle


@Composable
fun Copyable(toShow: String, toCopy: String = toShow,maxLength: Int=41,content: ContentBuilder<HTMLDivElement>?=null) {
    val ttId = remember { randomId(7) }
    Div({
        if( content == null ) classes("text-primary")
        style {
            display(DisplayStyle.Inline)
            cursor("pointer")
            if( content == null ) {
                property("border-bottom", "1px dashed")
                position(Position.Relative)
                property("word-break", "break-word")
                overflowX("hidden")
            }
            else {
                margin(0.px)
                padding(0.px)
            }
        }
        id(ttId)
        onClick {
            @Suppress("UNUSED_VARIABLE") val e = document.getElementById(ttId)
            js(
                "e = bootstrap.Tooltip.getOrCreateInstance(e, { title: 'скопировано', trigger: 'manual' })"
            )
            it.stopPropagation()
            globalLaunch {
                Browser.clipboardWriteText(toCopy)
                js("e.show()")
                delay(1500)
                js("e.hide()")
            }
        }
    }) {
        if( content != null )
            content()
        else {
            Text(toShow.trimMiddle(maxLength))
            Icon.ClipboardPlus.render({
                style {
                    position(Position.Relative)
                    display(DisplayStyle.Inline)
                    padding(0.px)
                    marginBottom(0.px)
                    marginLeft(2.px)
                    marginTop(0.px)
                    fontSize(0.7.em)
                }
            })
        }
    }
}
