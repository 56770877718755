package editor.operations

import document.Block
import document.Fragment
import document.IParagraph
import document.ParagraphStyle
import editor.DocChain
import editor.Editor
import editor.plugins.paragraphStyle
import views.ParagraphTextStyle

//enum class Migrations(val migration: suspend DocChain.() -> Unit) {
//
//}

val LAST_MIGRATION = 1

fun getOldParagraphTextStyle(pStyle: ParagraphStyle): ParagraphTextStyle? {
    val textStyle = pStyle.defaultTextStyle

    if (textStyle == null) return ParagraphTextStyle.NORMAL
    else return ParagraphTextStyle.entries.find { it.style == textStyle }
}

fun migrateParagraphStyle(pStyle: ParagraphStyle?): ParagraphStyle? {
    if (pStyle == null) return null

    val existing = ParagraphStyle.Companion.Predefined.entries.find { it.style.name == pStyle.name && it.style.defaultTextStyle == pStyle.defaultTextStyle }
    if (existing != null) return existing.style

    val oldStyle = getOldParagraphTextStyle(pStyle)

    val newStyle = when(oldStyle) {
        ParagraphTextStyle.NORMAL -> ParagraphStyle.Companion.Predefined.Normal.style
        ParagraphTextStyle.HEADING -> ParagraphStyle.Companion.Predefined.Title.style
        ParagraphTextStyle.SUBHEADING -> ParagraphStyle.Companion.Predefined.Subtitle.style
        ParagraphTextStyle.HEADING1 -> ParagraphStyle.Companion.Predefined.Heading1.style
        ParagraphTextStyle.HEADING2 -> ParagraphStyle.Companion.Predefined.Heading2.style
        ParagraphTextStyle.HEADING3 -> ParagraphStyle.Companion.Predefined.Heading3.style
        ParagraphTextStyle.HEADING4 -> ParagraphStyle.Companion.Predefined.Heading4.style
        null -> ParagraphStyle.Companion.Predefined.Normal.style
    }

    console.log(">>>>>>>> OLD", oldStyle?.name, "<<< NEW ", newStyle.name)
    return newStyle
}

fun fixParagraphStyle(p: IParagraph): IParagraph {
    var updated = p

    updated = p.makeCopy(paragraphStyle = migrateParagraphStyle(p.paragraphStyle))

    val updatedElements = p.elements.map {
        if (it is IParagraph) fixParagraphStyle(it) as Fragment
        else it
    }

    updated = updated.makeCopy(elements = updatedElements)

    return updated
}

fun DocChain.fixParagraphStyles() {
    elements.forEach { b ->
        val updated = b.withUpdatedParagraph(fixParagraphStyle(b.paragraph))

        update(updated)
    }
}

suspend fun Editor.runMigration1() {
    val doc = document ?: return
    val meta = doc.meta

    if (meta.formatVersion == 0) {
        console.log("run migration 0 -> 1")
        // TODO: add replay call

        runTransaction("RUN MIGRATION 0 -> 1") { chain, style ->
            chain.fixParagraphStyles()
        }

        saveManager.forceSave {
            doc.saveMeta(doc.meta.copy(formatVersion = 1))
        }
    }
}

suspend fun Editor.runMigrations() {
    runMigration1()
}