import androidx.compose.runtime.*
import androidx.compose.runtime.snapshots.SnapshotStateList
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.*
import kotlinx.coroutines.launch
import net.sergeych.intecowork.api.ApiDoc
import net.sergeych.intecowork.api.ApiEvent
import net.sergeych.intecowork.doc.IcwkDocument
import net.sergeych.mp_logger.LogTag
import net.sergeych.mp_logger.debug
import net.sergeych.mp_logger.exception
import net.sergeych.mp_logger.info
import net.sergeych.mp_tools.globalLaunch

object DocsCache : LogTag("CACHED") {

    val all: SnapshotStateList<IcwkDocument> = mutableStateListOf()
    val my = mutableStateListOf<IcwkDocument>()
    val shared = mutableStateListOf<IcwkDocument>()
    val trashed = mutableStateListOf<IcwkDocument>()

    private val _allFlow = MutableStateFlow<List<IcwkDocument>>(listOf())
    private val _myFlow = MutableStateFlow<List<IcwkDocument>>(listOf())
    private val _sharedFlow = MutableStateFlow<List<IcwkDocument>>(listOf())
    private val _trashedFlow = MutableStateFlow<List<IcwkDocument>>(listOf())

    val allFlow = _allFlow.asStateFlow()
    val myFlow = _myFlow.asStateFlow()
    val sharedFlow = _sharedFlow.asStateFlow()
    val trashedFlow = _trashedFlow.asStateFlow()

    var loading by mutableStateOf(true)
        private set

    private fun propagateUpdated(list: SnapshotStateList<IcwkDocument>) {
        // note we need list.toList() otherwise state flow will consider state is not changed:
        when (list) {
            all -> _allFlow.value = list.toList()
            my -> _myFlow.value = list.toList()
            shared -> _sharedFlow.value = list.toList()
            trashed -> _trashedFlow.value = list.toList()
        }
    }

    private fun addDoc(doc: IcwkDocument) {
        fun addTo(list: SnapshotStateList<IcwkDocument>) {
            for ((i, d) in list.withIndex()) {
                if (d.docId == doc.docId) {
                    list[i] = d
                    return
                }
            }
            // ordered:
            var pos = 0
            while (pos < list.size) {
                if (doc.updatedAt > list[pos].updatedAt) break
                pos += 1
            }
            list.add(pos, doc)
            propagateUpdated(list)
        }
        if (doc.isTrashed)
            addTo(trashed)
        else {
            addTo(all)
            if (doc.role.isOwner) addTo(my)
            else addTo(shared)
        }
    }

    private fun removeFrom(docId: Long, list: SnapshotStateList<IcwkDocument>): IcwkDocument? {
        for ((i, d) in list.withIndex()) {
            if (d.docId == docId) {
                list.removeAt(i)
                propagateUpdated(list)
                return d
            }
        }
        return null
    }

    private fun removeDocId(docId: Long): IcwkDocument? = listOf(
        removeFrom(docId, all),
        removeFrom(docId, my),
        removeFrom(docId, shared),
        removeFrom(docId, trashed)
    ).firstOrNull { it != null }

    private suspend fun loadAll(): Boolean =
        try {
            coroutineScope {
                launch { client.listDocs(ApiDoc.Group.All, fromAllFolders = true).collect { addDoc(it) } }
                launch { client.listDocs(ApiDoc.Group.Trashed).collect { addDoc(it) } }
            }
            loading = false
            true
        } catch (t: Throwable) {
            exception { "ошибка считывания документов" to t }
            false
        }

    fun openFolder(docId: Long?): Flow<List<IcwkDocument>> =
        flow {
            _allFlow.collect {
                emit(it.filter { it.parentId == docId })
            }
        }

    suspend fun listFolder(docId: Long?): List<IcwkDocument> =
        client.listDocs(ApiDoc.Group.All, fromAllFolders = false, parentId = docId).toList()
            .apply { forEach { addDoc(it) } }

    private fun clearAll() {
        all.clear()
        my.clear()
        shared.clear()
        trashed.clear()
        _allFlow.value = all
        _myFlow.value = my
        _sharedFlow.value = shared
        _trashedFlow.value = trashed
    }

    suspend fun getDoc(docId: Long): IcwkDocument? {
        all.firstOrNull { it.docId == docId }?.let { return it }
        return client.getDocHeader(docId)
            ?.let {
                IcwkDocument.openById(client, it.id)
                    ?.also { addDoc(it) }
            }
    }

    /**
     * Calculate the overall size of this document and all its children.
     * Please note it calculates only the cached (already loaded) doc headers..
     */
    fun treeSize(doc: IcwkDocument): Long {
        fun calcSize(d: IcwkDocument): Long {
            return d.lastCloudSize + all.filter { it.parentId == d.docId }.sumOf { it.lastCloudSize }
        }
        return calcSize(doc)
    }

    fun watchSize(doc: IcwkDocument): Flow<Long> = flow {
        _allFlow.collect { allDocs ->
            val current = allDocs.find { it.docId == doc.docId }
            fun calcSize(d: IcwkDocument): Long {
                return d.lastCloudSize + all.filter { it.parentId == d.docId }.sumOf { it.lastCloudSize }
            }
            emit(calcSize(current ?: doc))
        }
    }

    init {
        globalLaunch {
            debug { "отслеживаем изменения в документах" }
            client.events.collect { event ->
                if (event is ApiEvent.Doc) {
                    debug { "событие документа $event" }
                    when (event) {
                        is ApiEvent.Doc.Erased -> removeDocId(event.docId)
                        is ApiEvent.Doc.New -> {
                            IcwkDocument.openById(client, event.docId)?.let { addDoc(it) }
                                ?: error { "не удалось открыть док по new: $event" }
                        }

                        is ApiEvent.Doc.Trashed, is ApiEvent.Doc.Restored, is ApiEvent.Doc.MetaChanged,
                        is ApiEvent.Doc.ShareChanged, is ApiEvent.Doc.ParentChanged -> {
                            println("processing changed in ${event}")
                            removeDocId(event.docId)
                            IcwkDocument.openById(client, event.docId)?.let {
                                println("now adding doc for $event: ${it.isTrashed}")
                                addDoc(it)
                            }
                                ?: error { "не удалось открыть док по new: $event" }
                        }

                        else -> {
                            debug { "игнорируем событие документа $event" }
                        }
                    }
                }
            }
        }
        globalLaunch {
            info { "отслеживаем статус пользователя для кеша" }
            client.userFlow.collect { u ->
                if (u == null) {
                    info { "пользователь не авторизован, чистим кеш" }
                    clearAll()
                } else {
                    while (!loadAll()) info { "пользователь авторизован: $u, загружаем списки документов" }
                }
            }
        }
        globalLaunch {
            info { "отслеживаем изменение метаданных для кеша" }
            client.events.collect { e ->
                try {
                    when (e) {
                        is ApiEvent.Doc.MetaChanged, is ApiEvent.Doc.ShareChanged -> {
                            // to compensate a bug in IDE, we don't need it otherwise
                            e as ApiEvent.Doc
                            val doc = IcwkDocument.openById(client, e.docId)
                            removeDocId(e.docId)
                            doc?.let {
                                addDoc(it)
                            }
                        }

                        else -> {}
                    }
                } catch (t: Throwable) {
                    exception { "ошибка в отслеживании события документа" to t }
                }
            }
        }
    }

}

class Folder(val id: Long?, val name: String) {
    override fun toString(): String = "F#$id:$name"
}


