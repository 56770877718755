package document

import editor.operations.CaretRoot
import editor.truncateEnd
import kotlinx.serialization.Serializable
import kotlin.math.min

@Serializable
data class Caret(
    // path[0] is block guid, then nested IParagraphs guids, last is Text fragment guid (StyledSpan)
    val path: List<String>,
    val offset: Int,
) {
    val spanId by lazy { path.last() }
    val containerId by lazy { path.dropLast(1).last() }
    val blockId by lazy { path.first() }
    val cellId by lazy { path[1] }

    fun withParent(p: Fragment): Caret =
        Caret(listOf(p.guid) + path, offset)

    fun withParent(guid: String): Caret =
        Caret(listOf(guid) + path, offset)

    override fun toString(): String = "Ca:${path.joinToString(":") {it.truncateEnd(5)}}:$offset"

    fun toFullString(): String = "Caret ${path.joinToString(" -> ")}:${offset}"

    override fun equals(other: Any?): Boolean {
        return other is Caret && other.offset == offset && other.path == path
    }

    override fun hashCode(): Int {
        return offset + path.sumOf { it.hashCode() }
    }

    fun getMutualPath(other: Caret): List<String> {
        val mutual = mutableListOf<String>()
        var i = 0
        val mutualPathLength = min(path.size, other.path.size)

        while(i < mutualPathLength && path[i] == other.path[i]) {
            mutual.add(path[i])
            i++
        }

        return mutual
    }

    fun indexOf(guid: String): Int {
        return path.indexOf(guid)
    }

    init {
        if (path.isEmpty()) throw IllegalArgumentException("Empty cursor should not be used")
    }

    val rootType = when(path.size) {
        2 -> CaretRoot.PARAGRAPH
        4 -> CaretRoot.TABLE
        else -> {
            // TODO: need to prevent creating broken temp caret
            //  like caret at nested paragraph element, than adding root block id to the path manually
            CaretRoot.TEMPORARY
        }
    }
}

data class OffsetCaret(
    val paragraph: IParagraph,
    val offset: Int
) {
}