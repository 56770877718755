package views

import androidx.compose.runtime.*
import client
import controls.*
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import net.sergeych.intecowork.api.ApiCodeActivation
import net.sergeych.intecowork.api.ApiEvent
import net.sergeych.intecowork.tools.nowToSeconds
import net.sergeych.sprintf.sprintf
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import tools.Loading
import kotlin.time.Duration.Companion.days

@Composable
fun ListActiveCodes() {
    ListCodes(true)
}

@Composable
fun ListCodes(active: Boolean, attrs: AttrBuilderContext<HTMLDivElement>? = null) {
    val codes = remember { mutableStateListOf<ApiCodeActivation>() }
    var loading by remember { mutableStateOf(true) }
    Loading(loading) {
        if (codes.isEmpty())
            Txt("нет активных кодов", "alert alert-secondary")
        else {
            Di("row", attrs) {
                for (c in codes)
                    Di("col-md-6 col-sm-12 mb-2") {
                        CodeCard(c)
                    }
            }
        }
    }

    val scope = rememberCoroutineScope()
    fun reloadCodes() {
        scope.launch {
            loading = true
            Toaster.runAsync {
                // there is a possible RC condition that codes information
                // could be updated later than this event occurs (it's not
                // about codes), so we give some time for a server
                delay(200)
                client.call(client.api.getCodes, active).let {
                    codes.clear()
                    codes += it
                }
            }
            loading = false
        }
    }

    LaunchedEffect(true) {
        reloadCodes()
        client.events.collect { ev ->
            when (ev) {
                is ApiEvent.User.LimitsChanged -> reloadCodes()
                is ApiEvent.User.CodeActivated -> {
                    if (codes.all { it.id != ev.codeId }) reloadCodes()
                }
                else -> {}
            }
        }

    }
}


@Composable
fun CodeCard(code: ApiCodeActivation) {
    Di("card position-relative bg-success-subtle h-100") {
        Div({
            classNames("position-absolute top-0 end-0 p-1")
            style {
                fontSize(0.7.em)
            }
        }) {
            val n = "%06d".sprintf(code.id)
            +"№ ${n.slice(0..2)}-${n.substring(3)}"
        }
        Di("card-body") {
            Txt(code.title, "h5 card-title")
            Div({
                classes(
                    "card-subtitle",
                    if (code.validUntil - 40.days < nowToSeconds()) "text-danger" else "text-secondary"
                )
            }) {
                +"истекает "
                RelativeTime(code.validUntil)
            }
            Txt(code.description, fontSize = 0.8.em)
        }
    }
}