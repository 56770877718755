package net.sergeych.intecowork.api

import kotlinx.serialization.Serializable
import net.sergeych.intecowork.tools.encodeToBase64Url
import net.sergeych.unikrypto.Container
import net.sergeych.unikrypto.DecryptingKey
import net.sergeych.unikrypto.SymmetricKey
import net.sergeych.unikrypto.SymmetricKeys

@Serializable
class ApiPublicHandle(
    val handle: String,
    val packedContainer: ByteArray
) {
    @Serializable
    data class Contents(
        val secretKey: SymmetricKey,
        val outerKeyBytes: ByteArray
    ) {
        val outerKey by lazy { SymmetricKeys.create(outerKeyBytes) }
    }

    fun decrypt(someKey: SymmetricKey): Contents? =
        decryptContainer(packedContainer,someKey)

    /**
     * Construct public share (URL with no protocol host, like <handleId>#<packedKey>
     * @return public share or null if the key can't decrypt this handle
     */
    fun publicShare(someKey: SymmetricKey): String? = decrypt(someKey)?.let {
            "${handle}#${it.outerKey.keyBytes.encodeToBase64Url()}"
        }

    companion object {
        /**
         * Create random encrypted container than includes a secret key and
         * some random outer key, and is encrypted to both of them. It allows
         * knowing either one to restore the other.
         */
        fun createRandomContainer(secretKey: SymmetricKey): ByteArray {
            val outerKey = SymmetricKeys.random()
            // important: we encrypt it to 2 keys: secretLKey itself and outerKey
            // while strange, it lets anybody who known either outer or secret key
            // to open container and restore _both keys_
            return Container.encrypt(Contents(secretKey, outerKey.keyBytes),
                    secretKey, outerKey
                )
        }

        fun decryptContainer(packedContainer: ByteArray,someKey: DecryptingKey): Contents? {
            return Container.decrypt<Contents>(packedContainer,someKey)
        }
    }
}

@Serializable
class ApiCreateHandleArgs(
    val docId: Long,
    val packedKeyContainer: ByteArray
)

