package views

import Router
import androidx.compose.runtime.*
import client
import controls.*
import kotlinx.browser.window
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import modalWaitPanel
import net.sergeych.mp_tools.globalLaunch
import net.sergeych.unikrypto.InvalidPasswordError
import net.sergeych.unikrypto.Passwords
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Br
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Label
import org.w3c.dom.HTMLInputElement
import tools.UITheme
import withConfirm

const val MIN_PASSWORD_STRENGTH = 64

@Composable
fun ChangePassword() {
    val coscope = rememberCoroutineScope()
    LoggedIn {
        ComfortableForm {
            Heading("Изменение пароля") {}

            var oldPassword by remember { mutableStateOf("") }
            var oldPasswordState by remember { mutableStateOf<Boolean?>(null) }
            var oldPasswordMessage by remember { mutableStateOf("") }

            var newPassword1 by remember { mutableStateOf("") }
            var newPassword1Message by remember { mutableStateOf("") }
            var newPassword1State by remember { mutableStateOf<Boolean?>(null) }

            var newPassword2 by remember { mutableStateOf("") }
            var newPassword2Message by remember { mutableStateOf("") }
            var newPassword2State by remember { mutableStateOf<Boolean?>(null) }

            Br()
            Label { +"Введите текущий пароль:" }
            val oldPasswordId = textField(
                oldPassword, "текущий пароль*",
                isValid = oldPasswordState,
                message = oldPasswordMessage, type = InputType.Password
            ) {
                oldPassword = it
                if (oldPassword.isNotBlank()) {
                    oldPasswordState = null
                    oldPasswordMessage = ""
                }
            }

            Div({
                style {
                    fontSize(0.85.em)
                }
                classes("mb-3", "mt-2")
            }) {
                var showDetails by remember { mutableStateOf(false) }
                +"Новый пароль должен быть "
                A("#", {
                    onClick {
                        showDetails = !showDetails
                        it.stopPropagation()
                    }
                }) { +"стоек к подбору" }
                +". "
                if (showDetails) {
                    +"""используйте символы разных регистров, цифры, знаки
                        |препинания, и избегайте слов, немного измененных слов и последовательностей клавиш образующих
                        |простые фигуры на клавиатуре. Рекомендуемая оценочная стойкость пароля (отображается под паролем)
                        |128 бит.
                    """.trimMargin()
                }
            }

            textField(
                newPassword1, "Новый пароль*",
                isValid = newPassword1State,
                message = newPassword1Message, type = InputType.Password
            ) {
                newPassword1 = it
                val strength = Passwords.estimateBitStrength(it)
                if (it.isBlank()) {
                    newPassword1State = null
                    newPassword1Message = "пароль не может быть пустым"
                } else {
                    if (newPassword1.trim() != newPassword1) {
                        newPassword1State = false
                        newPassword1Message = "пароль не может содержать пробелы начале и конце"
                    }
                    if (strength < MIN_PASSWORD_STRENGTH) {
                        newPassword1State = false
                        newPassword1Message = "слишком слабый пароль (~$strength бит)"
                    } else {
                        newPassword1State = true
                        newPassword1Message = "оценочная стойкость $strength бит"

                        if (newPassword1 != newPassword2) {
                            if (newPassword2.isNotEmpty()) {
                                newPassword2State = false
                            } else {
                                newPassword2State = null
                            }
                            newPassword2Message = "пароли не совпадают"
                        } else {
                            newPassword2State = newPassword2.isNotEmpty()
                            newPassword2Message = ""
                        }
                    }
                }
            }

            textField(
                newPassword2, "Введите новый пароль ещё раз*",
                isValid = newPassword2State,
                message = newPassword2Message, type = InputType.Password
            ) {
                newPassword2 = it
                if (it.isBlank()) {
                    newPassword2State = null
                    newPassword2Message = ""
                } else {
                    if (newPassword2 != newPassword1) {
                        newPassword2State = false
                        newPassword2Message = "пароли не совпадают"
                    } else {
                        newPassword2State = true
                        newPassword2Message = ""
                    }
                }
            }
            Di("text-warning fw-bold mv-2") {
                +"Сохраните пароль в надежном месте, или выучите его наизусть."
            }
            Br()
            val canChange = newPassword1.trim() == newPassword1 && newPassword1.isNotBlank()
                    && Passwords.estimateBitStrength(newPassword1) >= MIN_PASSWORD_STRENGTH
                    && newPassword1 == newPassword2
                    && oldPassword.isNotBlank()
            Btn("сменить пароль", Icon.ShieldLock, Variant.Warning, disabled = !canChange,
                outline = UITheme.composeState.value.isDark,
            ) {
                modalWaitPanel("Изменяю пароль...") {
                    try {
                        if (client.changePassword(oldPassword, newPassword1)) {
                            Toaster.success("Пароль успешно изменен")
                            Router.back()
                        } else
                            Toaster.error("Не удалось изменить пароль, попробуйте ещё раз")
                    }
                    catch(e: InvalidPasswordError) {
                        oldPasswordState = false
                        oldPasswordMessage = "неправильный пароль"
                        coscope.launch {
                            delay(700)
                            setFocus(oldPasswordId)
                        }
                    }
                }
            }
            Di("text-end float-end") {
                Btn("использовать секретный код", Icon.Key, Variant.Secondary, attr = {
                }) {
                    withConfirm(
                        """Чтобы использовать секретный код, будет выполнен выход из системы и переход
                            |на страницу восстановления пароля. Продолжить?
                        """.trimMargin()
                    ) {
                        globalLaunch {
                            client.logout()
                            Router.push("/restore_access")
                        }
                    }
                }
            }
        }
    }
}

fun setFocus(id: String) {
    window.document.getElementById(id)?.let {
        (it as? HTMLInputElement)?.focus()
    }
}