package views

import Router
import androidx.compose.runtime.*
import controls.*
import editor.Editor
import editor.RenderMode
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock.System.now
import kotlinx.datetime.Instant
import net.sergeych.intecowork.LocalEvent
import net.sergeych.intecowork.api.ApiEvent
import net.sergeych.intecowork.doc.IcwkDocument
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import tools.localUrl
import views.DocSaveState.*

enum class DocSaveState {
    Unchanged, Dirty, Saving, Saved, SaveFailed
}

@Composable
fun EditHeading(
    doc: IcwkDocument,
    context: Editor,
    renderMode: RenderMode = RenderMode.EDITOR,
) {
    val scope = rememberCoroutineScope()
//    var title by remember { mutableStateOf(doc.title) }
    var hasShare by remember { mutableStateOf<Boolean?>(null) }
    var docSaveState: DocSaveState by remember { mutableStateOf(Unchanged) }
    var docStateChangedAt: Instant by remember { mutableStateOf(now()) }
    var docSavedAt: Instant by remember { mutableStateOf(doc.updatedAt) }

    LaunchedEffect(true) {
        doc.events().collect {
            when (it) {
                is ApiEvent.Doc.ShareChanged -> hasShare = it.isShared
                is ApiEvent.Doc.BodyChanged, is ApiEvent.Doc.MetaChanged -> {
                    // TODO: there should be an event on merge ok?
                    docSavedAt = now()
                }
                else -> {}
            }
        }
    }
    if (renderMode.isInteractive) LaunchedEffect(doc.docId) {
        hasShare = doc.getPublicShare() != null || doc.sharedWith().isNotEmpty()
    }

    LaunchedEffect(doc.docId) {
        doc.localEvents().collect { ev ->
            docStateChangedAt = now()
            when (ev) {
                is LocalEvent.Doc.Dirty -> docSaveState = Dirty
                is LocalEvent.Doc.SaveFailed -> docSaveState = SaveFailed
                is LocalEvent.Doc.Saving -> docSaveState = Saving
                is LocalEvent.Doc.Saved -> {
                    docSaveState = Saved
                    if (renderMode.isInteractive) docSavedAt = now()
                }

                else -> {}
            }
        }
    }

    Di("fixed-top p-2 shadow-sm bg-body") {
        Div({
            style {
                width(100.percent)
                position(Position.Relative)
                overflow("hidden")
            }
        }) {
            Table({
                style { width(100.percent) }
            }) {
                Tr {
                    Td({
                        attr("width", "1%")

                        style {
                            whiteSpace("nowrap")
                        }
                    }) {
                        Di("mb-0") {
                            Di("h2 mb-0 d-inline", {
                                style {
                                    cursor("pointer")
                                }
                                onClick { Router.push("/") }
                            }) {
                                Icon.ArrowLeft.render()
                                Icon.RichText.render({
                                    style { height(1.2.em) }
                                    classNames("ms_1 me_1 d-none d-sm-inline")
                                })
                            }
                        }
                    }
                    // Title:
                    Td({
                        style {
                            property("max-width", "0")
                            whiteSpace("nowrap")
                            overflow("hidden")
                            property("text-overflow", "ellipsis")
                        }
                    }) {
                        Di("h2 mb-0 me-1 d-inline", {
                            style {
                                width(100.percent)
                                whiteSpace("nowrap")
                                overflow("hidden")
                                property("text-overflow", "ellipsis")
                            }

                        }) {
                            EditableTitle(context)
                        }
                        // cloud/save icon
                        Di("d-lg-inline-block position-relative my-0 py-0", {
                            style {
                                fontSize(0.9.em)
                            }
                            // no colors (distracting
//                            when (docSaveState) {
//                                SaveFailed -> classes("text-danger")
//                                Saved -> classes("text-success")
//                                else -> {}
//                            }
                        }) {
                            val icon = when (docSaveState) {
                                Unchanged -> Icon.CloudCheck
                                Dirty -> Icon.CloudArrowUp
                                Saving -> Icon.CloudUpload
                                Saved -> Icon.CloudCheck
                                SaveFailed -> Icon.CloudSlash
                            }
                            icon.render(1.em, "me-1")

                            Spa("text-secondary") {
                                if (docSaveState == Dirty)
                                    +" изменён"
                                else {
                                    +" сохранён "
                                    RelativeTime(docSavedAt)
                                }
                            }
                        }

                    }
                    Td({
                        attr("width", "1%")
                        style {
                            whiteSpace("nowrap")
                        }
                    }) {
                        if (renderMode.isInteractive) {
                            Di("btn btn-outline-success me-2", {
                                onClick {
                                    collaboratorsDialog(doc)
                                }
                            }) {
                                (if (hasShare == true) Icon.People else Icon.PersonAdd).render(1.em)
                                Spa("d-none d-sm-inline") {
                                    +" доступ…"
                                }
                            }
                            Di("shadow-sm p-1 bg-body rounded d-inline-block position-relative cursor-pointer",
                                {
                                    onClick {
                                        it.preventDefault()
                                        scope.launch {
                                            context.search.open(false)
                                        }
                                    }
                                }) {
                                Icon.Search.render(1.2.em)
                            }
                            Di("shadow-sm p-1 bg-body rounded cursor-pointer d-inline-block", {
                                onClick { e ->
                                    Router.push("/profile")
                                    e.preventDefault()
                                    e.stopPropagation()
                                }
                            }) {
                                Icon.Gear.render({ style { fontSize(1.5.em) } })
                            }


                        } else {
                            Di("shadow-sm p-1 bg-body rounded d-inline-block") {
                                Div({
                                    classNames("d-none d-sm-inline me-1")
                                    style {
                                        property("vertical-align", "text-top")
                                        property("font-size", "smaller")
                                        position(Position.Relative)
                                        bottom(0.4.em)
                                    }
                                }) {
                                    Text("только чтение")
                                }
                                Icon.Eye.render(1.5.em)
                            }
                        }
                    }
                }
            }
        }
    }
}

@Composable
fun anonymousShare(doc: IcwkDocument) {
    var publicShare by remember { mutableStateOf<String?>(null) }
    var loading by remember { mutableStateOf(true) }

    LaunchedEffect(true) {
        Toaster.launchCatching {
            publicShare = doc.getPublicShare()?.let { localUrl(it) }
        }
        loading = false
    }
    if (loading)
        WaitPanel("Загружаю данные")
    else {
        publicShare?.let { share ->
            Di("text-center") {
                Text("разрешен анонимный просмотр по супер-ссылке: ")
                Di("mt-2 mb-3") { Copyable(share) }
                Di("mt-2") {
                    Btn("удалить супер-ссылку", Icon.ExclamationTriangle, Variant.Danger) {
                        loading = true
                        Toaster.launchCatching({ loading = false }) {
                            doc.deletePublicShare()
//                                    info("публичная ссылка удалена" )
                            publicShare = null
                        }
                    }
                }
            }
        } ?: run {
            Di("text-center") {
                Text("Публичный анонимный доступ запрещен. ")
                Br {}
                Btn(
                    "создать супер-ссылку", Icon.ExclamationTriangle, Variant.Secondary,
                    cls = "mt-1"
                ) {
                    Toaster.launchCatching({ loading = false }) {
                        loading = true
                        publicShare = localUrl(doc.createPublicShare())
                    }
                }
            }
        }
    }

}


//fun showDocSharing(doc: IcwkDocument) {
//    modalDialg("Совместный доступ") {
//        body {
//            anonymousShare(doc)
//        }
//    }
//}
