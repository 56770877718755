package controls

import Router
import androidx.compose.runtime.Composable
import client
import kotlinx.browser.document
import kotlinx.coroutines.delay
import net.sergeych.mp_tools.globalLaunch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLUListElement
import views.Logo
import views.StorageInfo
import views.confirmLogout

@Composable
fun SidebarLayout(
    title: String,
    sidebarContent: ContentBuilder<HTMLDivElement>,
    topContent: ContentBuilder<HTMLDivElement>? = null,
    mainComposable: ContentBuilder<HTMLDivElement>
) {

    Di("row") {
        Di("sidebar border border-left col-md-3 col-xl-2 p-0 bg-body-tertiary") {
            Div({
                classNames("offcanvas-md offcanvas-start bg-body-tertiary")
                id("sidebarMenu")
            }) {
                Di("offcanvas-header") {
                    H5({ classNames("offcanvas-title") }) {
                        Logo(1.4.em)
                        Text(title)
                    }
                    Input(InputType.Button) {
                        classes("btn-close")
                        attr("data-bs-dismiss", "offcanvas")
                        attr("data-bs-target", "#sidebarMenu")
                        attr("aria-label", "Close")
//                        onClick { closeOffcanvas() }
                    }
                }
                Di("offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto") {
                    Di("mt-2 ms-1 d-none d-md-block") {
                        Logo(2.em, "d-inlinee")
                        H4({ classNames("d-inline align-middle onbig") }) { Text(title) }
                    }
                    Di("mx-1") {
                        sidebarContent()
                    }

                    Hr({ classNames("my-1") })

//                    H5({ classNames("text-center") }) {
//                        Text(client.currentUser?.name ?: "аноним!")
//                    }
                    Ulist {
                        Ulink(Icon.Gear, client.currentUser?.name ?: "настройки", "/profile")
                        Ulink(Icon.BoxArrowLeft, "выйти") {
                            closeOffcanvas()
                            confirmLogout()
                        }
                    }

                    Di("mx-2") {
                        StorageInfo()
                    }
                }
            }
        }
        Main({
            classNames("col-md-9 col-xl-10 px-md-4")
        }) {
            Div({
                style {
                    height(100.vh)
                    overflowY("auto")
                    overflowX("hidden")
                }
            }) {
                Di("mt-2 d-md-none d-flex align-items-center") {
                    Div({
                        style { cursor("pointer") }
                        classNames("nav-link d-inline")
                        attr("data-bs-toggle", "offcanvas")
                        attr("data-bs-target", "#sidebarMenu")
                    }) {
                        Icon.List.render(1.5.em, "ms-0")
                    }
                    // collapsable title
                    Di("fw-bold fs-3 me-1", {
                        id("topTitle")
                        onClick { showTopTitle(false) }
                    }) { Text(title) }
                    topContent?.invoke(this)
                }
                topContent?.let { tc ->
                    Di("mt-3 d-none d-md-block w-100", {
                        style {
                            property("max-width", "23cm")
                            property("margin-left", "auto")
                            property("margin-right", "auto")
                        }
                    }) {
                        tc()
                    }
                }
                mainComposable()
            }
        }
    }
}

@Composable
fun Ulink(icon: Icon, text: String, link: String = "#", extraClasses: String? = null, doOnClick: (() -> Unit)? = null) {
    Li({ classNames("nav-item my-0") }) {
        A("#", {
            classNames("nav-link d-flex align-items-center gap-2 my-0")
            extraClasses?.let { classNames(it) }
            if (link != "#") {
                onClick {
                    closeOffcanvas()
                    globalLaunch {
                        delay(70)
                        Router.push(link)
                    }
                    it.preventDefault()
                    it.stopPropagation()
                }
            } else {
                doOnClick?.let { d -> onClick { d() } }
            }
        }) {
            icon.render()//(1.2.em,"my-0")
            Text(text)
        }
    }
}

@Composable
fun Ulist(builder: ContentBuilder<HTMLUListElement>) {
    Ul({ classNames("nav flex-column mb-auto") }) {
        builder()
    }
}

/**
 * Показывает или скрывает заголовок на узком экране
 */
fun showTopTitle(visible: Boolean) {
    (document.getElementById("topTitle") as? HTMLDivElement)?.let {
        if (visible) {
            it.classList.remove("slideout")
        } else {
            it.classList.add("slideout")
        }
    }
}

external fun closeOffcanvas()