import document.EventManager

fun TestDoc2() = EventManager {
    h1 { t("Иоганн Генсфляйш цур Ладен цум Гутенберг[2] ") }

    p {
        i("(нем. Johannes Gensfleisch zur Laden zum Gutenberg; между 1397 и 1400, Майнц — 3 февраля 1468, Майнц)")
    }
    p {
        t("также Гутенберг[3][4] — немецкий первопечатник, ")
        b("первый типограф Европы")
        t(". В 1440-х годах создал способ книгопечатания подвижными литерами, оказавший огромное влияние не только на европейскую культуру, но и на всемирную историю.")
    }
    p {
        t("Гутенберг в 1439 году был первым европейцем, который использовал ")
        b("подвижные литеры")
        t(". В числе его вкладов в книгопечатание: ")
        i {
            b {
                t("изобретение процесса массового производства при помощи подвижных литер")
            }
        }
        t("; использование чернил на масляной основе для печати книг, регулируемой матрицы, механических подвижных литер и деревянной печатной машины, аналогичной сельскохозяйственным винтовым прессам того периода. Он объединил эти элементы в практическую систему, которая позволяла массово выпускать печатные книги и была экономически выгодной как для типографов, так и для читателей. Метод изготовления литер Гутенберга традиционно включает гарт (сплав) и ручную литейную форму. Сплав представлял собой смесь свинца, олова и сурьмы, которая расплавлялась при относительно низкой температуре для более быстрого и экономичного литья, хорошо отливалась и создавала долговечные литеры.")
    }
    img("/printing_press.jpg")
    p {
        u {
            t("Использование подвижных литер")
        }
        t(" было явным улучшением рукописи, которая была основным методом производства книг в Европе, а также ксилографии, и произвело революцию в европейском книжном производстве. Технология печати Гутенберга быстро распространилась по всей Европе, а затем и по всему миру.")
    }
    p {
        t("Его главная работа, Библия Гутенберга ")
        i {
            b {
                u {
                    t("(также известная как Библия из 42 строк)")
                }
            }
        }
        t(", была первой печатной версией Библии и получила признание за её высокое эстетическое и техническое качество.")
    }
}