package views

import client
import controls.Toaster
import withConfirm

fun confirmLogout() {
    withConfirm(
        "Вы действительно хотите выйти из системы?",
    ) {
        Toaster.launchCatching {
            client.logout()
            info("выполнен выход из учетной записи")
        }
    }
}