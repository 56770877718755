package views

import Router
import androidx.compose.runtime.*
import client
import controls.*
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import modalWaitPanel
import net.sergeych.unikrypto.Passwords
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Br
import org.jetbrains.compose.web.dom.Div
import tools.UITheme

internal val reSecretCode = """^\w{5}(?:-\w{5}){4}$""".toRegex()

@Composable
fun RestoreAccess() {
    ComfortableForm {

        var secretCode by remember { mutableStateOf("") }
        var secretCodeIsValid by remember { mutableStateOf<Boolean?>(null) }
        var secretCodeMessage by remember { mutableStateOf("") }

        var newPassword1 by remember { mutableStateOf("") }
        var newPassword1Message by remember { mutableStateOf("") }
        var newPassword1State by remember { mutableStateOf<Boolean?>(null) }

        var newPassword2 by remember { mutableStateOf("") }
        var newPassword2Message by remember { mutableStateOf("") }
        var newPassword2State by remember { mutableStateOf<Boolean?>(null) }

        val coscope = rememberCoroutineScope()

        Heading("Изменение пароля") {}

        Br()

        val secretCodeFieldId = textField(
            secretCode, "Введите секретный код", isValid = secretCodeIsValid,
            message = secretCodeMessage, requestFocus = true
        ) {
            secretCode = it
            secretCodeIsValid = it matches reSecretCode
            secretCodeMessage = if (secretCodeIsValid == false) "формат секретного кода не верен" else ""
        }

        Div({
            style {
                fontSize(0.85.em)
            }
            classes("mb-3", "mt-2")
        }) {
            var showDetails by remember { mutableStateOf(false) }
            +"Новый пароль должен быть "
            A("#", {
                onClick {
                    showDetails = !showDetails
                    it.stopPropagation()
                }
            }) { +"стоек к подбору" }
            +". "
            if (showDetails) {
                +"""используйте символы разных регистров, цифры, знаки
                        |препинания, и избегайте слов, немного измененных слов и последовательностей клавиш образующих
                        |простые фигуры на клавиатуре. Рекомендуемая оценочная стойкость пароля (отображается под паролем)
                        |128 бит.
                    """.trimMargin()
            }
        }

        textField(
            newPassword1, "Новый пароль*",
            isValid = newPassword1State,
            message = newPassword1Message, type = InputType.Password
        ) {
            newPassword1 = it
            val strength = Passwords.estimateBitStrength(it)
            if (it.isBlank()) {
                newPassword1State = null
                newPassword1Message = "пароль не может быть пустым"
            } else {
                if (newPassword1.trim() != newPassword1) {
                    newPassword1State = false
                    newPassword1Message = "пароль не может содержать пробелы начале и конце"
                }
                if (strength < MIN_PASSWORD_STRENGTH) {
                    newPassword1State = false
                    newPassword1Message = "слишком слабый пароль (~$strength бит)"
                } else {
                    newPassword1State = true
                    newPassword1Message = "оценочная стойкость $strength бит"

                    if (newPassword1 != newPassword2) {
                        if (newPassword2.isNotEmpty()) {
                            newPassword2State = false
                        } else {
                            newPassword2State = null
                        }
                        newPassword2Message = "пароли не совпадают"
                    } else {
                        newPassword2State = newPassword2.isNotEmpty()
                        newPassword2Message = ""
                    }
                }
            }
        }

        textField(
            newPassword2, "Введите новый пароль ещё раз*",
            isValid = newPassword2State,
            message = newPassword2Message, type = InputType.Password
        ) {
            newPassword2 = it
            if (it.isBlank()) {
                newPassword2State = null
                newPassword2Message = ""
            } else {
                if (newPassword2 != newPassword1) {
                    newPassword2State = false
                    newPassword2Message = "пароли не совпадают"
                } else {
                    newPassword2State = true
                    newPassword2Message = ""
                }
            }
        }
        Di("text-warning fw-bold mv-2") {
            +"Сохраните пароль в надежном месте, или выучите его наизусть."
        }
        Br()
        val canChange = newPassword1.trim() == newPassword1 && newPassword1.isNotBlank()
                && Passwords.estimateBitStrength(newPassword1) >= MIN_PASSWORD_STRENGTH
                && newPassword1 == newPassword2
                && secretCode matches reSecretCode
        Btn(
            "восстановить пароль", Icon.ShieldLock, Variant.Warning, disabled = !canChange,
            outline = UITheme.composeState.value.isDark,
        ) {
            modalWaitPanel("восстанавливаю пароль...") {
                delay(200)
                if (client.resetPasswordAndLogin(secretCode, newPassword1)) {
                    Toaster.success("Пароль успешно изменен")
                    Router.replace("/")
                } else {
                    secretCodeIsValid = false
                    secretCodeMessage = "неправильный секретный код"
                    coscope.launch {
                        delay(700)
                        setFocus(secretCodeFieldId)
                    }
                }
            }
        }
    }
}
