import androidx.compose.runtime.*
import controls.*
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.CompletableDeferred
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import net.sergeych.intecowork.api.NotFoundException
import net.sergeych.mp_logger.LogTag
import net.sergeych.mp_logger.info
import net.sergeych.mp_logger.warning
import net.sergeych.mp_tools.globalLaunch
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Hr
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.Location
import views.*

typealias ModalHandler = @Composable (() -> Unit) -> Unit

object Router : LogTag("ROUTR") {

    val DOCUMENT_ROUTE = "/documents/(\\d+)"
    val anonymourRe = "/[^#/?]{2,}#[^/#?]{43}".toRegex()

    var regex: Regex? = null
        private set

    var match: MatchResult? = null
        private set

    val defaultTitle = "доколлабр"

    data class Target(val title: String = defaultTitle, val content: @Composable () -> Unit)

    fun param(index: Int): String? =
        match?.groupValues?.get(index + 1)

    @Suppress("unused")
    fun longParam(index: Int): Long =
        param(index)?.toLong() ?: throw NotFoundException()

    @Suppress("unused")
    fun longParamOrNull(index: Int): Long? =
        kotlin.runCatching { param(index)?.toLong() }.getOrNull()

    val userRouting = listOf(
        "/" to Target { Home() },
        "/testdoc" to Target { TestDoc() },
        DOCUMENT_ROUTE to Target { DocumentPage() },
        "/profile/delete" to Target { ProfileDelete() },
        "/profile" to Target { Profile() },
        "/change_password" to Target { ChangePassword() },
//        "/(\\d+)?" to Target { DesignedLayout(PageContent.HOME) },
//        "/system" to Target { views.Home() },
//        "/buildings/new" to Target("Здания") { NewBuilding() },
//        "/buildings/(\\d+)" to Target("Зданиe") { EditBuilding() },
//        "/buildings" to Target("Здания") { ShowBuildings() },
//        "/company/buildings/(\\d+)" to Target("Здания" ) { CompanyBuilding() },
//        "/companies/new" to Target("Организация") { NewCompany() },
//        "/companies/(\\d+)(?:/([^#/?]+))?" to Target("Организация") { EditCompany() },
//        "/companies" to Target("Организации") { ShowCompanies() },
//        "/profile" to Target("Профиль") { UserProfile() },
//        "/building_counters/(\\d+)" to Target("Счетчики") { AdminCounters() },
//        "/admin" to Target("Сисадмин") { AdminSwitchboard() },
//        "/people(?:/([^#/?]+))?" to Target("Пользователи") { AdminUsers() },
//        "/househead/(\\d+)(?:/([^#/?]+))?" to Target("Главдом") { HeadHome() },
//        "/chat/(\\d+)?" to Target("Чат с УК") { DesignedLayout(PageContent.CHAT) },
//        "/employee_chats(?:/([^#/?]+))?" to Target("Чат с УК") { EmployeeChats() },
    ).map { (k, v) -> Regex("$k(?:\\?.*)?$") to v }

    val guestRouting = listOf(
        "/" to Target { Home() },
        "/mergetest" to Target { TestMerge() },
        "/restore_access" to Target { RestoreAccess() },
        anonymourRe.pattern to Target { GuestVeiwDocument() },
        "/logout" to Target { Logout() },
        "/help_locked" to Target { HelpOnLocked() },
        "/testdoc" to Target { TestDoc() }
    ).map { (k, v) -> Regex(k) to v }

    data class Redirect(
        val url: String,
        val aliases: Collection<String>
    ) {
        companion object {
            operator fun invoke(url: String, vararg aliases: String): Redirect {
                require(aliases.isNotEmpty())
                return Redirect(url, aliases.toList())
            }
        }
    }

    val redirects: Map<String, Redirect> = run {
        val rr = mutableMapOf<String, Redirect>()
        listOf(
            Redirect("/ftorKh-vzOL#XQ-nTgb2v2o4OhDYPUUKLayfo8UNo8Y1_eUdtyqRj3o", "about_security"),
            Redirect("/w8YcgkKvaVf#Ap1jBV-P40d0pknr3tN1PvWSG9GMiRbiqNSpLa4adIk", "about_superlink"),
            Redirect("/De-d8c8tp70#TnshSoVLf99t3S_aq7MeXaGVFa7Hy7D64nJoDPsEXeY", "btla"),
            Redirect("/ZZKf8PmGW2C#Lobq_zbsex3h56NqBUkIcNvs96JhhQ2zSKYeM6RHIIk", "public_offer"),
        ).forEach { r ->
            for (a in r.aliases) rr["/" + a] = r
        }
        rr.toMap()
    }

    /**
     * If the current URL is redirected, this property is not null anc should be used to retrieve actual
     * information. Note that `window.location` is not rewritten on redirect. See, for example,
     * [locationPathWithHash].
     */
    var activeRedirect: Redirect? = null
        private set

    private fun targetFor(loc: Location?): Target {
        if (loc == null) return matchPath("/") ?: guestRouting.first().second
        redirects[loc.pathname]?.let { r ->
            matchPath(r.url, r)?.let { return it }
        }
        return matchPath(loc.pathname)
            ?: matchPath(loc.pathname + loc.hash)
            ?: guestRouting.first().second
    }

    private fun targetForUrl(url: String): Target {
        return matchPath(url)
            ?: guestRouting.first().second
    }

    private var pathFlow = MutableStateFlow(targetFor(document.location))

    private fun matchPath(url: String, redirect: Redirect? = null): Target? {
        info { "selecting content for ${client.currentUser}: $url" }
        val routes = if (client.currentUser != null) userRouting + guestRouting else guestRouting
        for ((re, target) in routes) {
            re.matchEntire(url)?.let {
                match = it
                activeRedirect = redirect
                return target
            }
        }
        return null
    }

    /**
     * Get location path with hash, e.g. "path#hash" string, respecting possible active redirect.
     * If redirect is active for current location, the `window.location` keeps alias, while this
     * method returns redirected values. See also [activeRedirect]
     */
    fun locationPathWithHash(): String {
        val s = activeRedirect?.url ?: "${window.location.pathname}${window.location.hash}".drop(1)
        return if (s[0] == '/') s.drop(1) else s
    }

    fun push(url: String) {
        tryQuit(url) {
            val t = targetForUrl(url)
            localHistory.add(url)
            if (localHistory.size > 500) localHistory.removeFirst()
            window.history.pushState(url, t.title, url)
            document.title = t.title
            pathFlow.value = t
        }
    }

    private val quitHandlers = mutableMapOf<Int, QuitHandlerRecord>()

    class QuitHandlerRecord(val id: Int, val callback: QuitHandler.() -> Boolean) {
        fun disconnect() {
            quitHandlers.remove(id)
        }

    }

    class QuitHandler(val url: String?, val record: QuitHandlerRecord) {
        fun disconnect() {
            record.disconnect()
        }

        fun retry() {
            if (url == null || url == "/") back()
            else push(url)
        }
    }

    private var quitIds = 0

    fun tryQuit(url: String?, f: () -> Unit) {
        for (h in quitHandlers.values.toList()) {
            if (!h.callback.invoke(QuitHandler(url, h))) return
        }
        f()
    }

    fun quitHandler(f: QuitHandler.() -> Boolean): QuitHandlerRecord {
        return QuitHandlerRecord(quitIds++, f).also { quitHandlers[it.id] = it }
    }

    private val _modalIsVisible = MutableStateFlow(false)

    /**
     * Allow check the state and collect the event that there are modal windows active.
     */
    val modalIsVisible: StateFlow<Boolean> = _modalIsVisible

    private val localHistory = mutableListOf<String>()

    fun back() {
        if (localHistory.removeLastOrNull() == null)
            push("/")
        else
            tryQuit(null) {
                window.history.go(-1)
            }
    }

    fun replace(url: String) {
        tryQuit(url) {
            targetForUrl(url).let {
                window.history.replaceState(url, it.title, url)
                if (localHistory.isNotEmpty()) localHistory.removeLast()
                localHistory.add(url)
                pathFlow.value = it
                document.title = it.title
            }
        }
    }

    private val modalStack = mutableStateListOf<ModalHandler>()

    fun pushModal(modalHandler: ModalHandler) {
        modalStack.add(modalHandler)
        _modalIsVisible.value = true
    }

    @Composable
    fun userContent() {
        var target by remember { mutableStateOf(pathFlow.value) }
        var lastUser by remember { mutableStateOf(client.currentUser) }
        var modalHandler by remember { mutableStateOf<ModalHandler?>(null) }

//        RequireAction(ApiAgreement.Action.GuestAccess) {
        target.content()
//        }
        Toaster.contents()

        // the logic is complex before modal can cause other modals to modify the stack, so
        // check that we have an active modal:
        modalHandler?.let { handler ->
            // render it and onclose:
            handler {
                // drop current modal, it well cause recompose
                modalHandler = null
                // and remove it from the stack: could be not the last already:
                modalStack.remove(handler)
                _modalIsVisible.value = modalStack.isNotEmpty()
            }
        } ?: run {
            // recompose with no current modal - pick if exists,
            // it will call recompose so code above will work:
            modalHandler = modalStack.lastOrNull()
        }

        LaunchedEffect("collectStatus") {
            client.userFlow.collect {
                if (lastUser != it) {
                    lastUser = it
                    val url = document.location?.let { it.pathname + it.search + it.hash } ?: ""
                    replace(url)
                }
            }
        }

        LaunchedEffect("collectFlow") {
            pathFlow.collect { target = it }
        }
        LaunchedEffect(true) {
//            console.log("launched: DDF")
//            delay(500)
//            withConfirm("А правда это надо?") {
//                console.log("Правда!")
//                showSecret("12312312lkjhlk;lkqwje;rkjqwhekjrhwqkljhrlkwqjrhlqwkjrhqwlkjerqj")
//            }
        }
//
    }


    @Suppress("unused")
    suspend fun okDialog(f: DialogScope.() -> Unit) = okDialog(false, f)
    suspend fun <T> okDialog(result: T, f: DialogScope.() -> Unit): T? {
        val d = CompletableDeferred<T>()
        try {
            pushModal { doClose ->
                Dialog {
                    f()
                    footer {
                        Bn({
                            classNames("btn-primary")
                            onClick {
                                d.complete(result)
                                close()
                            }
                        }) {
                            Text("OK")
                        }
                    }
                    onClose { doClose() }
                }
            }
        } catch (t: Throwable) {
            t.printStackTrace()
            d.completeExceptionally(t)
        }
        return d.await()
    }


    @Suppress("unused")
    fun queryParam(name: String): String? =
        Regex("[?&]$name=([^&]+)").find(window.location.search)?.groups?.get(1)?.value

    init {
        globalLaunch {
            client.userFlow.collect {
                if (it != null)
                    pathFlow.value = targetFor(document.location)
            }
        }
        window.onpopstate = {
//            window.history.go(1)
//            val l = document.location!!
//            tryQuit(l.pathname + l.hash + l.search) {
//                console.log("^^^^^^^^^^^^^^ POPS ${document.location}")
//                console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^")
//                console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^")
//                console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^")
//                console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^")
            document.location?.let { loc ->
                pathFlow.value = targetFor(loc).also {
                    document.title = it.title
                }
            } ?: warning { "popstate without location: ${it.state}" }
//            }
        }
        document.title = pathFlow.value.title
    }

}

/**
 * Opens the modal wait panel atop the screen and wait until [f] is executed in
 * global scope. If it throws exception, the [Toaster.error] is shown.
 *
 * This method fixes most problem with asynchronous usage of bootstrap dialog by the cost
 * of sometimes waiting for dialog to appear.
 */
fun modalWaitPanel(title: String, text: String? = null, f: suspend () -> Unit) {
    // it must be here to avoid re-creation while wait modal dialog is active:
    val dscope = CompletableDeferred<DialogScope>()

    Router.pushModal { doClose ->
        // small delay before showing wait modal, for fast operations
        var warmUp by remember { mutableStateOf(true) }

        LaunchedEffect(true) {
            globalLaunch {
                try {
                    f()
                } catch (t: Throwable) {
                    Toaster.error("непредвиденная ошибка: $t")
                    t.printStackTrace()
                } finally {
                    if (!warmUp) {
                        // dialog already start to appear, we need it to be fully
                        // shown, then we must close it, or UI will be broken.
                        kotlin.runCatching { dscope.await().close() }
                    }
                    doClose()
                }
            }
        }

        LaunchedEffect(true) {
            delay(150)
            warmUp = false
        }

        if (!warmUp) {
            Dialog {
                staticBackdrop()
                body {
                    WaitPanel(title, text)
                    LaunchedEffect(true) {
                        // important: if yje dialog won't successfully appear before closing
                        // using the handle, UI would be broken (bootstrap bug)
                        delay(600)
                        // now scope could be used to close the dialog:
                        dscope.complete(this@Dialog)
                    }
                }
            }
        }
    }
}

fun modalDialog(title: String? = null, f: DialogScope.() -> Unit) {
    try {
        Router.pushModal { doClose ->
            Dialog(title) {
                f()
                onClose(doClose)
            }
        }
    } catch (t: Throwable) {
        console.error("ошибка при создании модального диалога: $t")
        t.printStackTrace()
    }
}

/**
 * Input string value in a modal dialog.
 *
 * onEnter callback has complex usage. Its only argument means:
 * - not null - user selected "ok"
 * - null - user selected "cancel"
 *
 * The result value must be:
 * - null: close the dialog
 * - non-null: show as error and continue
 */
fun modalInputString(
    label: String, title: String? = null,
    initialValue: String = "",
    useStaticBackdrop: Boolean = false,
    onEnter: suspend (String?) -> String?
) {
    modalDialog {
        if (useStaticBackdrop)
            staticBackdrop()
        title?.let {
            heading(it)
        }
        body {
            var text by remember { mutableStateOf(initialValue) }
            var errorMessage by remember { mutableStateOf<String?>(null) }

            suspend fun callOnEnter(ok: Boolean) {
                val result = if (ok) onEnter(text) else onEnter(null)
                if (result == null)
                    close()
                else
                    errorMessage = result
            }

            val scope = rememberCoroutineScope()
            textField(
                text, label, isValid = errorMessage.isNullOrBlank(),
                message = errorMessage,
                requestFocus = true,
                attrs = {
                    onKeyDown {
                        if( it.key == "Enter" )
                            scope.launch { callOnEnter(true) }
                    }
                }
            ) { text = it }

            Hr()

            Btn("OK", Icon.Check, me = 2) {
                scope.launch {
                    callOnEnter(true)
                }
            }
            Btn("Отмена", Icon.XCircle,Variant.Secondary) {
                scope.launch { callOnEnter(false) }
            }
        }
    }
}


fun confirm(
    text: String? = null, title: String? = null,
    yesText: String = "Да",
    noText: String = "Нет",
    yesVariant: Variant = Variant.Primary,
    noVariant: Variant = Variant.Secondary,
    iconVariant: Variant = Variant.Primary,
    icon: Icon? = null,
    builder: ContentBuilder<HTMLDivElement>? = null
): Deferred<Boolean> {
    val done = CompletableDeferred<Boolean>()
    try {
        Router.pushModal { doClose ->
            Dialog {
                title?.let {
                    heading(it)
                }
                body {
                    Di("container-fluid") {
                        Row {
                            icon?.let { ic ->
                                Di("col-auto text-center", {
                                    if (iconVariant != Variant.Primary)
                                        classes(iconVariant.textClass)
                                }) {
                                    ic.render(4.em)
                                }
                            }
                            Di("col") {
                                text?.let { Text(it) }
                                builder?.invoke(this)
                            }
                        }
                    }
                }
                footer {
                    Bn({
                        classes(yesVariant.buttonClass)
                        onClick {
                            close()
                            done.complete(true)
                        }
                    }) {
                        Text(yesText)
                    }
                    Bn({
                        classes(noVariant.buttonClass)
                        onClick {
                            close()
                            done.complete(false)
                        }
                    }) {
                        Text(noText)
                    }
                }
                onClose {
                    if (done.isActive) done.complete(false)
                    doClose()
                }
            }
        }
    } catch (t: Throwable) {
        t.printStackTrace()
        done.completeExceptionally(t)
    }
    return done
}

@Suppress("unused")
fun withConfirm(
    text: String? = null, title: String? = null,
    yesText: String = "Да",
    noText: String = "Нет",
    yesVariant: Variant = Variant.Primary,
    noVariant: Variant = Variant.Secondary,
    icon: Icon? = null,
    iconVariant: Variant = Variant.Primary,
    body: ContentBuilder<HTMLDivElement>? = null,
    onConfirmed: () -> Unit,
) {
    globalLaunch {
        confirm(text, title, yesText, noText, yesVariant, noVariant, iconVariant, icon, body).await().let {
            if (it) onConfirmed()
        }
    }
}

@Suppress("unused")
suspend fun waitConfirm(
    text: String, title: String? = null,
    yesText: String = "Да",
    noText: String = "Нет",
    yesVariant: Variant = Variant.Primary,
    noVariant: Variant = Variant.Secondary,
    iconVariant: Variant = Variant.Primary,
    icon: Icon? = null,
): Boolean {
    return confirm(text, title, yesText, noText, yesVariant, noVariant, iconVariant, icon).await()
}

