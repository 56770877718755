package editor.operations

import document.*
import editor.AlgoLogger
import editor.DebugList
import editor.PackageLogger

val optimizerLogger = PackageLogger("optimizer", "DarkGoldenRod", DebugList.optimizer)

data class ParagraphOptimizeResult(
    val paragraph: IParagraph,
    val caret: Caret? = null
)

data class BlockOptimizeResult(
    val block: Block,
    val caret: Caret? = null
)

fun optimizeBlock(b: Block, c: Caret? = null, debug: Boolean = DebugList.cleaner): BlockOptimizeResult {
    val cleaned = optimize(b, c, debug)

    return BlockOptimizeResult(cleaned.paragraph as Block, cleaned.caret)
}

fun isText(f: Fragment?): Boolean {
    return f is Fragment.StyledSpan && !f.isLink()
}

fun optimizeStyle(fragments: List<Fragment>): List<Fragment> {
    val logger = AlgoLogger(optimizerLogger, "style")
    val updated = mutableListOf<Fragment>()

    if (fragments.isEmpty()) return fragments
    if (fragments.size == 1) return fragments

    var accumulator: Fragment.StyledSpan? = null

    fragments.forEach { el ->
        if (el !is Fragment.StyledSpan) {
            accumulator?.let {
                updated += accumulator as Fragment
                accumulator = null
            }
            updated += el
        } else {
            accumulator?.let {
                if (it.textStyle == el.textStyle && it.href == el.href) accumulator = it.copy(text = it.text + el.text)
                else {
                    updated += it
                    accumulator = el
                }
            } ?: run {
                accumulator = el
            }
        }
    }

    accumulator?.let {
        updated += it
    }

    return updated
}

fun optimizeEmpty(fragments: List<Fragment>): List<Fragment> {
    val logger = AlgoLogger(optimizerLogger, "empty")
    val updated = mutableListOf<Fragment>()

    if (fragments.isEmpty()) return fragments

    fun emptySpan(): Fragment {
        return Fragment.StyledSpan("")
    }

    fun getLeft(i: Int): Fragment? {
        return updated.lastOrNull()
    }

    fun getRight(i: Int): Fragment? {
        if (i < fragments.size - 1) return fragments[i + 1]
        return null
    }

    fun ensureTextWrap(f: Fragment, left: Fragment?, right: Fragment?) {
        if (left !is Fragment.StyledSpan) {
            logger.log("prepend guid=${left?.guid} with empty span")
            updated += emptySpan()
        }
        logger.log("add guid=${left?.guid}")
        updated += f
        if (right !is Fragment.StyledSpan) {
            logger.log("append guid=${left?.guid} with empty span")
            updated += emptySpan()
        }
    }

    fun processFragment(f: Fragment, left: Fragment?, right: Fragment?) {
        val nonTextNeighbours = !isText(left) && !isText(right)

        if (f !is Fragment.StyledSpan) {
            ensureTextWrap(f, left, right)
        } else {
            if (f.isLink()) {
                if (f.text.isEmpty()) {
                    if (nonTextNeighbours) {
                        // empty link with non-text neighbours
                        ensureTextWrap(f, left, right)
//                        updated += emptySpan()
                    }
                    else {
                        // link with empty text
                        logger.log("add link guid=${f.guid} href=${f.href} text=${f.text}")
                        updated += f
                    }
                } else {
                    // wrap non-empty link
                    ensureTextWrap(f, left, right)
                }
            } else {
                if (f.text.isNotEmpty() || nonTextNeighbours) {
                    logger.log("add span guid=${f.guid} href=${f.href} text=${f.text}")
                    updated += f
                } else {
                    logger.log("skip guid=${f.guid}")
                    // skip span
                }
            }
        }
    }

    if (!isText(fragments.first())) updated += emptySpan()

    var i = 0

    while (i < fragments.size) {
        val fragment = fragments[i]
        val left = getLeft(i)
        val right = getRight(i)

        processFragment(fragment, left, right)

        i++
    }

    return updated
}

/**
 * Glues neighbour styled spans with identical style and remove empty spans
 */
fun optimize(paragraph: IParagraph, caret: Caret? = null, debug: Boolean = DebugList.cleaner): ParagraphOptimizeResult {
    fun log(msg: String) {
        if (debug) console.warn("[CLEAN]: $msg")
    }

    return when (paragraph) {
        is Fragment.Paragraph -> {
            if (paragraph.elements.size == 1) ParagraphOptimizeResult(paragraph, caret)
            else {
//                val updated = mutableListOf<Fragment>()
                var i = 1
                val firstElement = paragraph.elements[0]
                if (firstElement !is Fragment.StyledSpan)
                    throw BugException("First element of L0 ${paragraph.guid} is not StyledSpan ${firstElement}")

                log("Before BEGIN")
                paragraph.elements.forEach { log(it.guid) }
                log("Before CARET: ${caret?.toFullString()}")
                log("Before END")

                var sum: Fragment.StyledSpan? = firstElement

                var updated = optimizeEmpty(paragraph.elements)
                updated = optimizeStyle(updated)

                var newCaret: Caret? = null
                if (caret != null && caret.path.indexOf(paragraph.guid) != -1) {
                    val updatedCaretSpan = updated.find { it.guid == caret.spanId } as Fragment.StyledSpan?

                    if (updatedCaretSpan == null || updatedCaretSpan.lastOffset < caret.offset) {
                        val oldSpan = paragraph.find(caret.spanId)
                        val indexOfP = caret.path.indexOf(paragraph.guid)
                        val localOffset = paragraph.getLocalOffsetCaret(caret).offset
                        val updatedP = paragraph.makeCopy(updated)
                        var localCaret = updatedP.caretAt(
                            localOffset,
                            caret.offset == 0 && oldSpan?.lastOffset != 0
                        ) ?: throw BugException("Can't restore local caret")

                        // sometimes we need right version of caret of the same offset

                        val newPath = caret.path.slice(0 until indexOfP) + localCaret.path
                        newCaret = Caret(newPath, localCaret.offset)
                    }
//                    console.log("OPTIMIZE NEW CARET ${newCaret?.toFullString()}")
                }

                log("After BEGIN")
                updated.forEach { log(it.guid) }
                log("After CARET: ${(newCaret ?: caret)?.toFullString()}")
                log("After END")

                ParagraphOptimizeResult(paragraph.makeCopy(updated), newCaret ?: caret)
            }

        }
        is Fragment.TableParagraph -> {
            var newCaret: Caret? = null

            val elements = paragraph.elements.map {
                if (it !is IParagraph) throw BugException("Broken table cell")
                var cell = it as IParagraph

                val brokenP = cell.elements.find { it !is Fragment.Paragraph }
                if (brokenP != null) throw BugException("Broken table cell content")

                val paragraphs = cell.elements as List<Fragment.Paragraph>
                val updated = paragraphs.map {
                    val op = optimize(it, caret, debug)
                    if (op.caret != null && op.caret != caret) newCaret = op.caret
                    op.paragraph.toFragment()
                }

                cell.makeCopy(updated).toFragment()
            }
            ParagraphOptimizeResult(paragraph.makeCopy(elements), newCaret ?: caret)
        }
        is Block -> {
            val cleaned = optimize(paragraph.paragraph, caret, debug)
            ParagraphOptimizeResult(paragraph.withUpdatedParagraph(cleaned.paragraph), cleaned.caret)
        }
    }
}
