package editor.operations

import document.*
import editor.*

data class TableCell(
    val row: Int,
    val col: Int,
    val p: Fragment.Paragraph,
    override val guid: String = p.guid,
    override val nextGuid: String?,
    override val prevGuid: String?
): L2Caret<TableCell> {
    override fun copyListElement(prev: String?, next: String?): TableCell {
        return copy(prevGuid = prev, nextGuid = next)
    }

    override fun contentEquals(other: TableCell): Boolean {
        return p == other.p
    }

    override fun caretAt(guid: String, offset: Int): Caret? {
        return p.caretAt(guid, offset)
    }

    override fun caretAt(offset: Int, preferRight: Boolean): Caret? {
        TODO("Not yet implemented")
    }

    override fun caretAtEnd(): Caret {
        return p.caretAtEnd()
    }

    override fun getOffset(c: Caret): Int {
        TODO("Not yet implemented")
    }
}

fun Chain<Block>.tableAddRow(insertAfter: Int, tableId: String, row: List<Fragment.Paragraph>? = null) {
    var tableBlock = elements.find { it.guid == tableId }
        ?: throw BugException("Can't add row, table not found")
    val table = tableBlock.paragraph as Fragment.TableParagraph
    var updatedTable = if (row == null) table.addEmptyRow(insertAfter) else table.addRow(insertAfter, row)
    tableBlock = tableBlock.withUpdatedParagraph(updatedTable)
    updateBlockAndClean(tableBlock)
}

suspend fun Editor.tableAddRow(insertAfter: Int, tableId: String, row: List<Fragment.Paragraph>? = null) {
    replay.tableAddRow(insertAfter, tableId, row)

    runTransaction("TABLE ADD ROW", shouldForceRecord = true) { chain, transactionStyle ->
        chain.tableAddRow(insertAfter, tableId, row)
    }
}

fun Chain<Block>.tableDeleteRow(rowIndex: Int, tableId: String) {
    var tableBlock = elements.find { it.guid == tableId }
        ?: throw BugException("Can't delete row, table not found")
    var table = tableBlock.paragraph as Fragment.TableParagraph

    val c = caret ?: throw BugException("Can't delete row, no caret")

    if (table.rowsCount == 1) {
        if (tableBlock.prevBlockGuid == null) throw BugException("Table cannot be the first block")
        caret = prev(tableBlock)?.caretAtEnd()
        delete(tableBlock.guid)
    } else {
        if (c.blockId == tableId) {
            table.getCaretCoords(c)?.let {
                val cellRowIndex = it.first
                val cellColIndex = it.second

                if (cellRowIndex == rowIndex) {
                    val safeRow = if (rowIndex > 0) rowIndex - 1 else rowIndex + 1
                    caret = table.caretAtCellEnd(safeRow, cellColIndex)
                }
            }
        }

        table = table.deleteRow(rowIndex)
        tableBlock = tableBlock.withUpdatedParagraph(table)
        updateBlockAndClean(tableBlock)
    }
}

suspend fun Editor.tableDeleteRow(rowIndex: Int, tableId: String) {
    replay.tableDeleteRow(rowIndex, tableId)

    runTransaction("TABLE DELETE ROW", shouldForceRecord = true) { chain, transactionStyle ->
        chain.tableDeleteRow(rowIndex, tableId)
    }
}

fun Chain<Block>.tableAddCol(insertAfter: Int, tableId: String, col: List<Fragment.Paragraph>? = null) {
    var tableBlock = elements.find { it.guid == tableId }
        ?: throw BugException("Can't add col, table not found")
    val table = tableBlock.paragraph as Fragment.TableParagraph
    var updatedTable = if (col == null) table.addEmptyCol(insertAfter) else table.addCol(insertAfter, col)
    tableBlock = tableBlock.withUpdatedParagraph(updatedTable)
    updateBlockAndClean(tableBlock)
}

suspend fun Editor.tableAddCol(insertAfter: Int, tableId: String, col: List<Fragment.Paragraph>? = null) {
    replay.tableAddCol(insertAfter, tableId, col)

    runTransaction("TABLE ADD COL", shouldForceRecord = true) { chain, transactionStyle ->
        chain.tableAddCol(insertAfter, tableId, col)
    }
}

fun Chain<Block>.tableDeleteCol(colIndex: Int, tableId: String) {
    var tableBlock = elements.find { it.guid == tableId }
        ?: throw BugException("Can't delete col, table not found")
    var table = tableBlock.paragraph as Fragment.TableParagraph

    val c = caret ?: throw BugException("Can't delete row, no caret")

    if (table.colsCount == 1) {
        if (tableBlock.prevBlockGuid == null) throw BugException("Table cannot be the first block")
        caret = prev(tableBlock)?.caretAtEnd()
        delete(tableBlock.guid)
    } else {
        if (c.blockId == tableId) {
            table.getCaretCoords(c)?.let {
                val cellRowIndex = it.first
                val cellColIndex = it.second

                if (cellColIndex == colIndex) {
                    val safeCol = if (colIndex > 0) colIndex - 1 else colIndex + 1
                    caret = table.caretAtCellEnd(cellRowIndex, safeCol)
                }
            }
        }

        table = table.deleteCol(colIndex)
        tableBlock = tableBlock.withUpdatedParagraph(table)
        updateBlockAndClean(tableBlock)
    }
}

suspend fun Editor.tableDeleteCol(colIndex: Int, tableId: String) {
    replay.tableDeleteCol(colIndex, tableId)

    runTransaction("TABLE DELETE COL", shouldForceRecord = true) { chain, transactionStyle ->
        chain.tableDeleteCol(colIndex, tableId)
    }
}

fun Chain<Block>.insertTable(rows: Int, cols: Int, hasHeader: Boolean, stylesheet: Stylesheet, atCaret: Caret? = null) {
    val logger = log("insertTable")
    logger("run")
    val c = atCaret ?: caret
    ?: throw BugException("Can't perform operation without caret")

    when(c.rootType) {
        CaretRoot.TABLE -> { console.log("Can't insert table in table" ) }
        CaretRoot.PARAGRAPH -> {
            val leftBlockId = c.blockId
            split(stylesheet, c)
            val tableBlock = Block(Fragment.TableParagraph(rowsCount=rows, colsCount=cols, hasHeader=hasHeader))
            insertBlockAndClean(tableBlock, leftBlockId)
        }
        else -> {}
    }
}

suspend fun Editor.insertTable(rows: Int, cols: Int, hasHeader: Boolean) {
    replay.insertTable(rows, cols, hasHeader)

    runTransaction("INSERT TABLE", shouldForceRecord = true) { chain, transactionStyle ->
        chain.insertTable(rows, cols, hasHeader, stylesheet)
    }
}