package document.fts

import DocsCache
import document.Block
import kotlinx.coroutines.cancel
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.launch
import kotlinx.coroutines.yield
import net.sergeych.TokenMatch
import net.sergeych.intecowork.doc.IcwkDocument

/**
 * Perform the search and return best matching result.
 */
suspend fun IcwkDocument.textSearch(parts: Set<String>): FtsMatch {
    var bestMatch: FtsMatch = FtsMatch.TextMatch(this, TokenMatch.Score.Zero, "")
    coroutineScope {
        launch {
            val title = title?.lowercase()?.trim()
            this@textSearch.bodyBlocks().collect {
                it.decode<Block>()?.plainText?.let { text ->
                    if (text.lowercase().trim() != title) {
                        val s = TokenMatch.score(text, parts, true)
                        if (s > bestMatch.score) {
                            bestMatch = FtsMatch.TextMatch(this@textSearch, s, limitTextSample(text, parts))
                            if (s.count >= parts.size) {
                                // this is  a good enough match to stop here
                                cancel()
                            }
                        }
                    }
                    yield()
                }
            }
        }
    }
    return bestMatch
}

/**
 * Full text search in document names (first, fast) then on document bodies (slower).
 * It watches for documents changes (probably only in meta) and re-searches again, so yje
 * flow newer collects fully.
 *
 * To understand that one search pass is performed, use [FtsResult.inProgress]. Note that when it is
 * false, it is still continuing search in response of DocCache changes.
 */
fun fullTextSearch(patternString: String): Flow<FtsResult> = flow {

    val parts = TokenMatch.tokenize(patternString).map { it.key }.toSet()

    var stepInProgress = true

    if (parts.isNotEmpty()) {
        // strangely it does not work on new docs
        DocsCache.allFlow.collect { docs ->
            // search in file names
            val mm = mutableListOf<FtsMatch>()
            mm.addAll(
                docs.mapNotNull {
                    it.title?.let { title ->
                        val score = TokenMatch.score(title, parts)
                        if (score.isEmpty)
                            null
                        else
                            FtsMatch.NameMatch(it, score)
                    }
                }.sortedBy { it.score }.reversed()
            )
            emit(FtsResult(mm, stepInProgress))
            // TODO: Select matching keywords
            // this is a slow operation, so we process it wisely and in the background:
            for (d in docs) {
                val r = d.textSearch(parts)
                if (!r.score.isEmpty) {
                    println("found match: $r")
                    val existingIndex = mm.indexOfFirst { it.document.docId == r.document.docId }
                    if (existingIndex >= 0) {
                        if (mm[existingIndex].score > r.score) continue
                        mm.removeAt(existingIndex)
                    }
                    mm.add(r)
                    mm.sortBy { it.score }
                    emit(FtsResult(mm.reversed(), stepInProgress))
                }
            }
            stepInProgress = false
            emit(FtsResult(mm.reversed(), stepInProgress))
        }
    }
}

private val ignoredStartPunctuation = "({[\"'`~".toSet()

/**
 * Check that this string starts with any of the prefixes, ignoring possible
 * punctuation/specials in the beginning. This is why it is private.
 */
private fun String.startsWithAnyOf(parts: Collection<String>): String? {
    var startIndex = 0
    while (this[startIndex] in ignoredStartPunctuation) startIndex++
    val src = if (startIndex == 0) this else this.substring(startIndex)
    for (w in parts) {
        if (src.startsWith(w)) return w
    }
    return null
}

fun limitTextSample(text: String, parts: Collection<String>, maxWords: Int = 4): String {
    val words = text.split(" ").dropWhile { it.isBlank() }
    val lcWords = words.map { it.lowercase() }
    val lcParts = parts.map { it.lowercase() }
    var firstIndex = -1
    var lastIndex = words.lastIndex

    val remaining = lcParts.toMutableList()

    for ((i, w) in lcWords.withIndex()) {
        w.startsWithAnyOf(remaining)?.let { part ->
            if (firstIndex < 0) {
                firstIndex = i
                lastIndex = i
            } else lastIndex = i
            remaining.remove(part)
        }
        if (remaining.isEmpty()) break
    }
    return if (firstIndex >= 0) {
        var lead = ""
        var tail = ""

        firstIndex -= maxWords
        if (firstIndex < 0) firstIndex = 0
        else lead = "…"

        lastIndex += maxWords
        if (lastIndex > words.lastIndex) lastIndex = words.lastIndex
        else tail = "…"
        "$lead${words.subList(firstIndex, lastIndex + 1).joinToString(" ")}$tail"
    } else "!ошибка выделения релевантного текста"
}

