package controls

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.H4
import org.jetbrains.compose.web.dom.Text
import tools.ThemedSvg

@Composable
fun OverlayWaitMessage(title: String, text: String? = null) {
    Div({
        classes("position-absolute", "top-0", "bottom-0", "start-0", "end-0")
        style {
            property("z-index", 10000)
        }
    }) {
        WaitMessage(title, text)
    }
}

@Composable
fun WaitMessage(title: String, text: String? = null) {
    CenteredForm {
        Div({
            classes("text-center")
        }) {
            WaitMascot()
            H3 { Text(title) }
//            for (i in 1..3) {
                Div({ classes("spinner-border","spinner-border-lg") })
//                Text(" ")
//            }
            text?.let { t -> Div { Text(t) } }
        }
    }
}

@Composable
fun WaitMascot() {
    ThemedSvg("/mascots/new-doc",attrs = {
        style {
            height(11.em)
        }
    })
}

@Composable
fun WaitPanel(title: String, text: String? = null) {
    Div({ classes("text-center") }) {
        WaitMascot()
        H4 { Text(title) }
        Div({ classes("spinner-border") })
        text?.let { t -> Div { Text(t) } }
    }
}

@Suppress("unused")
@Composable
fun <T>WaitFor(value: T?, message: String, content: @Composable (T)->Unit) {
    if( value == null) WaitPanel(message)
    else content(value)
}
