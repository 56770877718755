package editor.operations

import androidx.compose.runtime.*
import controls.Di
import controls.OverlayWaitMessage
import controls.classNames
import document.Block
import document.BugException
import document.Fragment
import editor.*
import kotlinx.browser.window
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.dom.Div
import views.titleOrDefault

val EXPORT_VERSION_ID = "document-export-version"

fun log(msg: String) {
    if (DebugList.export) console.log("[EXPORT]: ${msg}")
}

class Exporter {
    private val _exportMode = MutableStateFlow(RenderMode.READONLY)
    val exportMode: StateFlow<RenderMode> = _exportMode.asStateFlow()

    fun setMode(mode: RenderMode) {
        _exportMode.value = mode
    }
}

fun Editor.print() {
    exporter.setMode(RenderMode.PRINT)
}

fun Editor.exportDOCX() {
    exporter.setMode(RenderMode.DOCX)
}

fun Editor.resetExport() {
    exporter.setMode(RenderMode.READONLY)
}

@Composable
fun ExportContainer(dc: Editor, mode: RenderMode) {
    val scope = rememberCoroutineScope()
    var isComplete by remember { mutableStateOf(false) }

    LaunchedEffect(isComplete) {
        log("ExportContainer isComplete=${isComplete}, wait all")
//        console.log("run logic for ", mode)
        if (isComplete) scope.launch {
            val fileTitle = dc.document!!.titleOrDefault
            dc.domObserver.waitAll("ExportContainer")
            if (mode.isExport) {
                export(fileTitle, mode)
                isComplete = false
                dc.resetExport()
            }
            else isComplete = false
        }
    }

    val waitMessage = when(mode) {
        RenderMode.PRINT -> "Подготовка печати"
        RenderMode.DOCX -> "Подготовка экспорта"
        else -> ""
    }

    if (mode.isExport) {
        OverlayWaitMessage(waitMessage)
        Div({
            classes("d-none")
            id(EXPORT_VERSION_ID)
        }) {
            dc.RenderExport(mode) {
                log("ExportContainer got complete")
                isComplete = true
            }
        }
    }
}

@Composable
fun renderDOCXBlocks(ct: Editor, blocks: List<Block>) {
    val groups = groupLists(blocks)

    groups.forEach {
        renderListLevel(it) {
            val b = it as Block
            RenderBlock(ct, b, RenderMode.DOCX)
        }
    }
}

@Composable
fun Editor.RenderDOCX() {
    val ct = this

    Div({
        id(DOCUMENT_CONTENT_ID)
        classNames("shadow p-3 mb-3 bg-body rounded")
    }) {
        Di("mb-auto mt-auto") {
            renderDOCXBlocks(ct, ct.eventManager.allBlocks)
        }
    }
}

@Composable
fun Editor.RenderPrint() {
    val ct = this

    Div({
        id(DOCUMENT_CONTENT_ID)
        classNames("bg-body")
    }) {
        Di("mb-auto mt-auto") {
            eventManager.allBlocks.forEach {
                RenderBlock(ct, it, RenderMode.PRINT)
            }
        }
    }
}

@Composable
fun Editor.RenderExport(mode: RenderMode, onComplete: () -> Unit) {
    var isReady by remember { mutableStateOf(false) }

    log("RenderExport, isReady=$isReady")

    LaunchedEffect(mode) {
        log("RenderExport: mode=${mode.name} schedule waiters")
        if (mode.isExport) {
            eventManager.allBlocks.forEach {
                it.findAll { it is Fragment.Frame && it.type == "image" }.forEach {
                    domObserver.watch(it.guid, "RenderExport")
                }
            }

            isReady = true
            onComplete()
        }
    }

    if (isReady) {
        log("RenderExport: render doc")

        when(mode) {
            RenderMode.DOCX -> RenderDOCX()
            RenderMode.PRINT -> RenderPrint()
            else -> {}
        }
    }
}

fun exportDOCX(title: String) {
    val content = getExportHTMLVersion()
    val filename = "$title.docx"

    js("html2docx.default(content).then(function(f) { saveAs(f, filename) })")
}

fun getExportHTMLVersion(): String {
    val printElement = window.document.querySelector("#$EXPORT_VERSION_ID") ?:
    throw BugException("no export container")

    val content = printElement.innerHTML

//    console.log("got content", content)

    val bootstrapCss = window.document.getElementById("bootstrap-css") ?:
    throw BugException("no bootstrap css style")

    val exportCss = window.document.getElementById("export-css") ?:
    throw BugException("no export style")

    var template = """
        <!DOCTYPE html>
        <html lang="en" xmlns="http://www.w3.org/1999/html">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1">
                <title>intecowork.ru</title>
                ${bootstrapCss.outerHTML}
                ${exportCss.outerHTML}
            </head>
            <body><div id="root">%CONTENT%</div></body>
        </html>
    """.trimIndent().replace("%CONTENT%", content)

    return template
}

fun exportPRINT() {
    var win = window.open() ?: return
    win.document.write(getExportHTMLVersion())
    win.print()
    win.close()
}

fun export(title: String, mode: RenderMode) {
    when(mode) {
        RenderMode.PRINT -> exportPRINT()
        RenderMode.DOCX -> exportDOCX(title)
        else -> {}
    }
}