package editor

import Router
import androidx.compose.runtime.*
import controls.Di
import controls.classNames
import document.*
import editor.operations.insertTerminalFragment
import editor.operations.paste
import editor.operations.runTransaction
import editor.plugins.CrossPaste
import editor.plugins.getGeometry
import editor.views.Image
import editor.views.renderParagraph
import editor.views.renderTableParagraph
import editor.views.resizeImage
import kotlinx.browser.window
import kotlinx.coroutines.launch
import net.sergeych.boss_serialization_mp.BossEncoder
import net.sergeych.mp_logger.LogTag
import net.sergeych.mp_logger.error
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.events.Event
import org.w3c.dom.events.KeyboardEvent
import org.w3c.dom.events.MouseEvent

val DOCUMENT_CONTENT_ID = "_document-content"
val DOCUMENT_CONTROL_CLASS = "_document-control"
val DOCUMENT_FRAME = "_document-frame"
val rendererLogger = LogTag("[RENDER]")

enum class RenderMode(val isInteractive: Boolean, val isExport: Boolean) {
    PRINT(false, true),
    DOCX(false, true),
    READONLY(false, false),
    EDITOR(true, false)
}

fun groupLists(paragraphs: List<IParagraph>): List<List<IParagraph>> {
    if (paragraphs.size == 0) return emptyList()

    val groups = mutableListOf<List<IParagraph>>()
    var i = 0
    var groupStyle: ParagraphStyle.List? = null
    val group = mutableListOf<IParagraph>()

    while (i < paragraphs.size) {
        val member = paragraphs[i]
        val memberStyle = member.paragraphStyle?.listStyle

        if (group.isEmpty()) {
            groupStyle = memberStyle
            group += member
        } else {
            if (groupStyle == memberStyle) group += member
            else {
                groups += group.toList()
                group.clear()
                groupStyle = memberStyle
                group += member
            }
        }

        i++
    }

    if (!group.isEmpty()) groups += group

    return groups
}

fun getOlType(level: Int): String {
    return when (level % 3) {
        0 -> "1"
        1 -> "a"
        2 -> "I"
        else -> "1"
    }
}

@Composable
fun renderListLevel(list: List<IParagraph>, itemRenderer: @Composable (item: IParagraph) -> Unit) {
    if (list.isEmpty()) return

    fun getLevel(p: IParagraph): Int {
        return p.paragraphStyle?.indentLevel ?: 0
    }

    val rootLevelP = list.minBy { getLevel(it) }
    val rootLevel = getLevel(rootLevelP)

    @Composable
    fun ensureLiWrap(shouldWrap: Boolean, renderer: @Composable () -> Unit) {
        if (shouldWrap) Li { renderer() }
        else renderer()
    }

    @Composable
    fun renderList(array: List<IParagraph>, rootLevel: Int, shouldWrap: Boolean = true) {
        var i = 0

        // There may be broken list started with not root level
        val children1 = array.takeWhile { getLevel(it) != rootLevel }
        if (!children1.isEmpty()) {
            ensureLiWrap(shouldWrap) {
                itemRenderer(
                    Block(
                        Fragment.Paragraph(
                            listOf(Fragment.StyledSpan("")),
                            ParagraphStyle(indentLevel = rootLevel)
                        )
                    )
                )
                renderListLevel(children1, itemRenderer)
            }

            i = children1.size
        }

        while (i < array.size) {
            val root = array[i]
            val children = array.slice(i + 1 until array.size).takeWhile { getLevel(it) != rootLevel }

            ensureLiWrap(shouldWrap) {
                itemRenderer(root)
                renderListLevel(children, itemRenderer)
            }

            i += children.size + 1
        }
    }

    when (rootLevelP.paragraphStyle?.listStyle) {
        ParagraphStyle.List.Numbers -> {
            Ol({
                attr("type", getOlType(rootLevel))
            }) {
                renderList(list, rootLevel)
            }
        }

        ParagraphStyle.List.Bullets -> {
            Ul {
                renderList(list, rootLevel)
            }
        }

        null -> {
            renderList(list, rootLevel, false)
        }
    }
}

external fun openKeyboard()

@Composable
fun RenderDocContent(eventManager: EventManager, ct: Editor = Editor(eventManager), mode: RenderMode = RenderMode.EDITOR) {
    val scope = rememberCoroutineScope()

    if (!mode.isInteractive) ct.showCaret = false

    var blockGuids by remember { mutableStateOf(eventManager.allBlocks.map { it.guid }) }
    var mobileVariant by remember { mutableStateOf(window.outerWidth < 500) }
    var hasFocus by remember { mutableStateOf(true) }
    var isActive by remember { mutableStateOf(ct.isActive.value) }

    DisposableEffect("launch") {
        val callback: (Event) -> Unit = { _ ->
            mobileVariant = window.outerWidth < 500
        }
        window.addEventListener("resize", callback)
        ct.domObserver.connect()
//        ct.linkObserver.connect()
        openKeyboard()

        onDispose {
            window.removeEventListener("resize", callback)
            ct.domObserver.disconnect()
//            ct.linkObserver.disconnect()
        }
    }

    LaunchedEffect("launch") {
        ct.isActive.collect {
//            console.log("RENDER DOC IS ACTIVE = ${it}")
            isActive = it
        }
    }

    fun isDocTarget(ev: Event): Boolean {
        return isClickTargetId(DOCUMENT_CONTENT_ID, ev) && !isClickTargetClass(DOCUMENT_CONTROL_CLASS, ev)
    }

    fun isFrameTarget(ev: Event): Boolean {
        return isClickTargetId(DOCUMENT_CONTENT_ID, ev) && isClickTargetClass(DOCUMENT_FRAME, ev)
    }

    // this is a bad implementation: it redraws everything
    // TODO: draw only the visible portion of the document
    Div({
        id(DOCUMENT_CONTENT_ID)
        if (mobileVariant)
            classNames("p-1 mb-3")
        else if (mode != RenderMode.PRINT) {
            classNames("shadow p-3 mb-3 bg-body rounded")
            style {
                property("max-width", "24cm")
                property("margin-left", "auto")
                property("margin-right", "auto")
            }
        } else
            classNames("bg-body")
    }) {
        Di("mb-auto mt-auto") {
            blockGuids.forEach {
                val block = eventManager.safeGet(it) // can be deleted at this moment
                block?.let { RenderBlock(ct, it, mode) }
            }
        }
    }

    ct.linkObserver.render()

    // space for the bottom toolbar. We can't do it with margins to not to runi
    // position detection logic!
    Di("mt-5 mb-5") {
        Br()
        Text("")
    }

    ct.spellChecker?.Monitor()

    if (mode.isInteractive) LaunchedEffect("docListeners") {
        eventManager.events.collect { e ->
            when (e) {
                is EventManager.Event.DeleteBlock -> {
                    blockGuids = blockGuids.filter { it != e.block.guid }
                }

                is EventManager.Event.NewBlock -> {
                    val i = blockGuids.indexOfFirst { it == e.block.prevBlockGuid }

                    if (i < 0) {
                        rendererLogger.error { "NewBlock: not in list ${e.block.guid}" }
                    } else {
                        val blocksBefore = blockGuids.slice(0..i)
                        val blocksAfter = blockGuids.slice(i + 1 until blockGuids.size)

                        blockGuids = blocksBefore + listOf(e.block.guid) + blocksAfter

                        for (j in i until blockGuids.size) {
                            val guid = blockGuids[j]
                            eventManager.safeGet(guid)?.let {
                                eventManager.sendUpdateBlock(it)
                            }
                        }
                    }
                }

                else -> {}
            }
        }
    }

    if (hasFocus && mode.isInteractive) LaunchedEffect(true) {
        Router.modalIsVisible.collect {
            // modals queue changed: show or hide cursor but only if we have focus,
            // otherwise cursor is always and already hidden
            ct.displayCaret(!it)
        }
    }

    DisposableEffect("listeners") {
        val focusListener = { _: Event ->
            // we got focus: display caret if there are no modals only
            if (!Router.modalIsVisible.value) ct.displayCaret(true)
            hasFocus = true
        }

        val blurListener = { _: Event ->
            ct.displayCaret(false)
            hasFocus = false
        }

        val crossPaste = CrossPaste(scope) { text, imgFile, ev ->
            if (isActive) {
                scope.launch {
                    if (imgFile != null) {
                        val bin = readFile(imgFile)
                        val geometry = getGeometry(bin)
                        val resized = resizeImage(bin, geometry)
                        val img = Image(resized.bin, resized.format)
                        val frame = Fragment.Frame("image", BossEncoder.encode(img))
                        ct.runTransaction("insert image file") { chain, transactionStyle ->
                            chain.insertTerminalFragment(frame)
                        }
                    } else {
                        text?.let {
                            if (ev is KeyboardEvent && ev.shiftKey) ct.paste(text, true)
                            else ct.paste(text, false)
                        }
                    }
                }
            }
        }

        val keydownListener = { ev: Event ->
            // if we have modal window active, we should not process any UI events here:
            if (!Router.modalIsVisible.value && ev is KeyboardEvent && isActive) {
                scope.launch {
                    try {
                        val shortcutResult = ct.shortcuts.handle(ev)

                        if (shortcutResult?.shouldPropagate != true) {
                            ev.preventDefault()
                            ev.stopImmediatePropagation()
                            ev.stopPropagation()
                        }
                    } catch (x: Exception) {
                        x.printStackTrace()
                        console.error(
                            "exception is caugth in keyboard processing coroutine adn therefore ignored",
                            x
                        )
                    }
                }
            }
        }

        val mouseListener = { ev: Event ->

            if (isActive) {
                val mev = ev as MouseEvent

                scope.launch {
                    if (mev.type == "contextmenu") {
                        if (isDocTarget(ev) || isFrameTarget(ev)) {
                            ct.linkObserver.turnOff()
                            ct.mouseObserver.onContextMenu(mev)
                        }
                    } else {
                        if (isDocTarget(ev)) {
                            ct.linkObserver.turnOff()
                            if (listOf(
                                    "mouseup",
                                    "mousedown"
                                ).indexOf(mev.type) != -1 && mev.button.toInt() != 2
                            ) ct.mouseObserver.onEvent(mev)
                        }
                    }
                }
            }
        }

        if (mode.isInteractive) {
            crossPaste.init()
            window.addEventListener("focus", focusListener)
            window.addEventListener("blur", blurListener)
            window.addEventListener("keydown", keydownListener)

            listOf("mousedown", "mouseup", "contextmenu").forEach {
                window.addEventListener(it, mouseListener)
            }
        }

        onDispose {
            crossPaste.terminate()

            if (mode.isInteractive) {
                window.removeEventListener("focus", focusListener)
                window.removeEventListener("blur", blurListener)
                window.removeEventListener("keydown", keydownListener)

                listOf("mousedown", "mouseup", "contextmenu").forEach {
                    window.removeEventListener(it, mouseListener)
                }
            }
        }
    }
}

@Composable
fun BlockWrapper(block: Block, mode: RenderMode, content: @Composable () -> Unit) {
    if (mode === RenderMode.DOCX) content()
    else Div({
        attr("data-ftype", "block")
        attr("data-blockid", block.guid)
        style {
            display(DisplayStyle.Flex)
        }
    }) {
        content()
    }
}

@Composable
fun RenderBlock(ct: Editor, block: Block, mode: RenderMode) {
    var b by remember { mutableStateOf(block) }
    var caret by remember { mutableStateOf(ct.caret) }
    // FIXME: component was reused, update block to receive events correct
    if (block.guid != b.guid) {
        b = block
        caret = ct.caret
    }

    if (!ct.showCaret) caret = null
    var style by remember { mutableStateOf(block.paragraphStyle?.defaultTextStyle ?: ct.defaultTextStyle) }

    if (b.isVisible) {
        val paragraph = b.paragraph

        BlockWrapper(b, mode) {
            when (paragraph) {
                is Fragment.TableParagraph -> renderTableParagraph(
                    paragraph,
                    null,
                    caret, style, ct, mode, b.renderVersion
                )

                is Fragment.Paragraph -> renderParagraph(paragraph, {
                    style {
                        width(100.percent)
                    }
                    if (paragraph.paragraphStyle?.marginBefore == null) {
                        classes("mt-1")
                    }

                }, caret, style, ct, mode, b.renderVersion)

                is Block -> { /* can't be nested blocks */
                }
            }
        }
    }

//    val scope = rememberCoroutineScope()

    LaunchedEffect("renderBlock${b.guid}") {
        ct.eventManager.events.collect { e ->
            if (e.block.guid == b.guid) {
//                scope.launch {
                when (e) {
                    is EventManager.Event.UpdateBlock -> {
//                            console.log("[RENDER]: schedule update block ${e.block.guid} with version ${ct.domObserver.verCodeG(e.block.guid)}")
//                            console.log("[RENDER]: caret = ${ct.caret}")
                        b = e.block.copy(renderVersion = b.renderVersion + 1)
                        style = b.paragraphStyle?.defaultTextStyle ?: ct.defaultTextStyle
//                            val oldVersion = caret?.renderVersion ?: 1
                        caret = ct.caret

//                            if (ct.caret?.blockId == e.block.guid) ct.doc.sendCaretBlockUpdate(e.block, ct.caret)
                    }

                    is EventManager.Event.RedrawBlock -> {
//                            console.log("[RENDER]: schedule redraw block ${e.block} with version ${ct.domObserver.verCodeG(e.block.guid)}")
//                            console.log("[RENDER]: caret = ${e.caret?.toFullString()}")
                        b = e.block.copy(renderVersion = b.renderVersion + 1)
                        style = b.paragraphStyle?.defaultTextStyle ?: ct.defaultTextStyle
//                            val oldVersion = caret?.renderVersion ?: 1
                        caret = e.caret

                        if (e.caret?.blockId == e.block.guid) {
//                                console.log("Redraw caret block", ct.domObserver.verCodeG(CaretId))
                            ct.eventManager.sendCaretBlockUpdate(e.block, e.caret)
                        }
                    }

                    else -> {}
                }
            }
        }
//        }
    }
}
