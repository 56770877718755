package views

import androidx.compose.runtime.*
import client
import controls.*
import kotlinx.browser.window
import net.sergeych.mp_tools.globalLaunch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.dom.*


@Composable
fun ProfileDelete() {
    var confirm by remember { mutableStateOf(false) }
    var isDeleting by remember { mutableStateOf(false) }

    LoggedIn { user ->
        if (isDeleting) {
            WaitMessage("Удаляю учетную запись, подождите пожалуйста...")
        } else {
            Di("row justify-content-center") {
                Di("col-12 col-lg-10 col-xl-8") {
                    HeadingTitle("Удаление учетной записи")
                    P {
                        +"""Вы выбрали удалить вашу учетную запись. Пожалуйста ознакомьтесь с этой информацией, и подтвердите
                        |что она вам понятна и вы даете согласие на удаление.
                    """.trimMargin()
                    }
                    h3("Что будет невосстановимо удалено:")
                    Ul {
                        Li { +"Ваш логин, пароль, секретный код и все данные учетной записи." }
                        Li { +"Имя, которое вы выбрали видимым для других участников." }
                        Li {
                            +"""Все документы, в том числе, те, для которых вы добавили соавторов, и те, в которых 
                        |вы создали секретные ссылки.""".trimMargin()
                        }
                        Li { +"Текущие оплаченные подписки. Возврат средств за неиспользованную часть подписки не предусмотрен Офертой." }
                    }
                    h3("Информация, которая не будет удалена:")
                    Ul {
                        Li { +"Изменения, которые Вы, возможно, вносили в документы других участников." }
                        Li {
                            +"""Комментарии в документам других участников, при этом они будут обезличены и не смогут 
                        |быть соотнесены с Вами при помощи информации, хранимой в данных Сервиса.""".trimMargin()
                        }
                    }
                    h3("Необратимость удаления учетной записи")
                    P {
                        +"""Удаление учетной записи необратимо. Восстановление ее невозможно. Повторное использование
                    |логина и/или видимого имени участника может быть недоступно в течение неопределенного времени,
                    |из соображений безопасности.
                """.trimMargin()
                    }
                    Br()
                    Di("form-check") {
                        Input(InputType.Checkbox) {
                            onInput { confirm = it.value }
                            classes("form-check-input")
                        }
                        Di("form-check-label") {
                            +"""Отмечая данный пункт, я подтверждаю, что внимательно прочитал условия удаления учетной записи, 
                            |они мне понятны и я их полностью принимаю, и хочу удалить свою учетную запись,
                            |в соответствии с ними.""".trimMargin()
                        }
                    }
                    Br()
                    Btn(
                        "Удалить мою учетную запись", Icon.Danger, Variant.Danger, outline = false,
                        disabled = !confirm, mb = 3
                    ) {
                        isDeleting = true
                        globalLaunch {
                            try {
                                client.deleteAccount()
                                window.open("/", "_self")
                                isDeleting = false
                            }
                            catch (e: Throwable) {
                                Toaster.error(e)
                            }
                            finally {
                                isDeleting = false
                            }
                        }
                    }
                }
            }
        }
    }
}
