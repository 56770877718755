package views

import androidx.compose.runtime.Composable
import controls.Di
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.ContentBuilder
import org.w3c.dom.HTMLDivElement

@Composable
fun ExceptXs(attrs: AttrBuilderContext<HTMLDivElement>?=null, f: ContentBuilder<HTMLDivElement>) {
    Di("d-none d-sm-block", attrs, f)
}

@Suppress("unused")
@Composable
fun OnlyXs(attrs: AttrBuilderContext<HTMLDivElement>?=null, f: ContentBuilder<HTMLDivElement>) {
    Di("d-sm-none", attrs, f)
}