package views

import androidx.compose.runtime.Composable
import controls.Icon
import controls.Variant
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Li
import org.w3c.dom.HTMLButtonElement

@Composable
fun DropdownUlink(
    text: String, icon: Icon? = null, attrs: AttrBuilderContext<HTMLButtonElement>? = null,
    variant: Variant? = null,
    enabled: Boolean = true,
    f: () -> Unit
) {
    Li {
        Button({
            classes("dropdown-item")
            variant?.let { classes(it.textClass) }
            if (!enabled) classes("disabled")
            onClick { f() }
            attrs?.invoke(this)
        }) {
            icon?.render({ classes("me-1") })
            +text
        }
    }
}