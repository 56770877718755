package views

import DocumentPage
import Router
import androidx.compose.runtime.*
import client
import controls.Toaster
import controls.WaitMessage
import editor.RenderMode
import net.sergeych.intecowork.doc.IcwkDocument
import net.sergeych.superlogin.InitSuperlogin

@Composable
fun GuestVeiwDocument() {
    var cloudDocument: IcwkDocument? by remember { mutableStateOf(null) }
    var mode by remember { mutableStateOf(RenderMode.READONLY) }

    cloudDocument?.let { DocumentPage(it, mode) }
        ?: WaitMessage("Открываю секретную ссылку")

    LaunchedEffect(true) {
        InitSuperlogin()
        try {
            val share = Router.locationPathWithHash()
            cloudDocument = IcwkDocument.openByShare(client, share).also { d ->
                if (client.isLoggedIn) {
                    IcwkDocument.openById(client, d.docId)?.let { d2 ->
                        if (d2.role.canWrite || d2.role.canComment) {
                            mode = RenderMode.EDITOR
                            Toaster.info("Вам предоставлен расширенный доступ к этому документу")
                            cloudDocument = d2
                        }
                    }
                }
            }
        } catch (t: Throwable) {
            Toaster.error("Ошибка: ${t.message}")
            Router.replace("/error_doc_not_found")
        }
    }
}