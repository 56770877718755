package editor.plugins

import Browser
import document.*
import editor.Editor
import editor.invisibleNBSP
import editor.operations.CRange
import editor.operations.getCRange
import editor.operations.getOrdered
import kotlinx.browser.window
import org.w3c.dom.*

fun getSelectionDOM(): Selection {
    return js("document.getSelection()")
}

external class JSSelectionRange {
    fun getClientRects(): Array<DOMRect>
}

external class Selection {
    val rangeCount: Int
    fun addRange(range: Range)
    fun removeAllRanges()

    fun getRangeAt(idx: Int): JSSelectionRange

    fun setBaseAndExtent(anchorNode: Node, anchorOffset: Int, focusNode: Node, focusOffset: Int)
}

data class CaretDOM(val node: Node, val offset: Int)

data class SelectionMenuData(
    val range: CRange?,
    val hasCopied: Boolean
)

data class SelectionMove(
    val canMove: Boolean,
    val newCaret: Caret? = null
)

class SelectionCRange(val dc: Editor) {
    var range: CRange? = null
    var copied: List<IParagraph>? = null

    fun getCopiedText(list: List<IParagraph>): String {
        var text = ""
        list.forEach { text += it.plainText }
        return if (text.endsWith('\n')) text.substring(0 until text.length - 1) else text
    }

    fun clear() {
        range = null
        val selection = getSelectionDOM()
        selection.removeAllRanges()
    }

    // returns true if selection action can be performed
    fun onArrow(isShift: Boolean, cBefore: Caret?, cAfter: Caret?): SelectionMove {
        if (!isShift || cBefore == null) {
            clear()
            return SelectionMove(true)
        }
        if (cAfter == null) return SelectionMove(true)

        range?.let {
            val pair = dc.getOrdered(cBefore, cAfter)
            val isLeftChanged = it.left == cBefore
//            val isRight = pair.second == cAfter
            val newRange =
                if (isLeftChanged) dc.getCRange(cAfter, it.right)
                else dc.getCRange(it.left, cAfter)

            if (newRange != null) {
                val newCaret = if (isLeftChanged) newRange.left else newRange.right

                range = newRange
                return SelectionMove(true, newCaret)
            } else {
                clear()
                return SelectionMove(false)
            }

//            if (newRange.left != newRange.right) {
//                if (!newRange.isOk()) {
//                    clear()
//                    return false
//                }
//                range = newRange
//                return true
//            }
//            else {
//                clear()
//                return true
//            }
        } ?: run {
            val pair = dc.getOrdered(cBefore, cAfter)
            val isRight = pair.second == cAfter

            val newRange = dc.getCRange(cBefore, cAfter)
            if (newRange != null) {
                val newCaret = if (isRight) newRange.right else newRange.left
                range = newRange
                return SelectionMove(true, newCaret)
            } else {
                clear()
                return SelectionMove(false)
            }

//            if (!newRange.isOk()) {
//                return false
//            }
//
//            if (newRange.left == newRange.right) {
//                clear()
//                return true
//            } else {
//                range = newRange
//                return true
//            }
        }
    }

    fun select(r: CRange) {
        range = r
        showDOM()
    }

    suspend fun setCopy(list: List<IParagraph>) {
        copied = list
        Browser.clipboardWriteText(getCopiedText(list))
    }

    private fun getCaretDOM(c: Caret): CaretDOM? {
//        console.log("get Caret DOM", c.offset)
        val caretBlock = dc.caretBlockSafe(c) ?: return null
//        val caretSpan = caretBlock.find(c.spanId) as Fragment.StyledSpan

//        console.log("get Caret DOM '${caretSpan.text}' '${caretSpan.text.length}'", c.offset)


        val c0 = c.copy(offset = 0)
        val cOffset = caretBlock.getLocalOffsetCaret(c)
        val c0Offset = caretBlock.getLocalOffsetCaret(c0)
        val spanOffset = cOffset.offset - c0Offset.offset

//        console.log("span offset", spanOffset, caretSpan)

        fun nodeText(n: Node): String? {
            // FIXME: remove invisible from doc
            return n.textContent?.replace(Regex("$invisibleNBSP"), "")
        }

        fun textLength(n: Node): Int {
            return nodeText(n)?.length ?: 0
        }

        return window.document.getElementById(c.spanId)?.let { span ->
            val nodes = span.childNodes.asList().filter { it is Text }
            var target: Node? = null
            var currentOffset = spanOffset

            nodes.forEach {
                if (target == null && it is Text) {
                    val length = textLength(it)
                    if (currentOffset > length) {
                        currentOffset -= length
                    }
                    else target = it
                }
            }

            target?.let {
                if (it.textContent?.startsWith("$invisibleNBSP") == true) currentOffset += 1
                return CaretDOM(it, currentOffset)
            } ?: run {
                return null
            }
        }
    }

    fun showDOM() {
        range?.let {
            val left = getCaretDOM(it.left)
            val right = getCaretDOM(it.right)

//            console.log("SHOW DOM", left, right)

            if (left == null || right == null) return

            val selection = getSelectionDOM()
            if (selection.rangeCount == 0) {
//                console.log("NO RANGE")
                val range = Range()
                range.setStart(left.node, left.offset)
                range.setEnd(right.node, right.offset)
//                console.log("SHOW DOM", left.node, left.offset, right.node, right.offset)
                selection.addRange(range)
            } else {
//                console.log("EDIT EXISTING")
                selection.setBaseAndExtent(left.node, left.offset, right.node, right.offset)
            }
        }
    }

    suspend fun getSelectionMenu(ev: MouseContextMenuEvent, elements: Array<Element>): SelectionMenuData? {
        val clipboardText = Browser.clipboardReadText()

        val r = ev.caret.caret?.let { pointCaret ->
            range?.let { r ->
                val left = dc.getOrdered(r.left, pointCaret)
                val right = dc.getOrdered(pointCaret, r.right)

                if (left.first == r.left && right.second == r.right) r
                else null
            }
        }

        return SelectionMenuData(r, !clipboardText.isNullOrEmpty() || copied != null)
    }
}