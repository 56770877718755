package controls

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.remember
import kotlinx.browser.window
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLLabelElement
import tools.randomId

@Composable
fun textInputHeadless(
    controlId: String,
    value: String,
    hasLabel: Boolean,
    placeholder: String?,
    isValid: Boolean? = null,
    type: InputType<String> = InputType.Text,
    attrs: AttrBuilderContext<HTMLInputElement>?=null,
    disabled: Boolean = false,
    id: String? = null,
    isFocused: Boolean? = false,
    autocomplete: Boolean? = null,
    onBlur: (() -> Unit)? = null,
    setter: (String) -> Unit,
) {
    Input(type) {
        id(controlId)
        onInput {
            try {
                setter(it.value)
            }
            catch(t: Throwable) {
                console.error("unexpected exception in input handler")
                t.printStackTrace()
            }
        }
        classes("form-control")
        value(value)
        if (isValid == true) classes("is-valid")
        else if (isValid == false) classes("is-invalid")
        if (hasLabel) placeholder(placeholder ?: "введите значение")
        if( disabled ) disabled()
        attrs?.invoke(this)


        id?.let { id(id) }

        ref {
            if (isFocused == true) it.focus()
            it.onblur = {
                onBlur?.invoke()
            }

            onDispose {

            }
        }
        autocomplete?.let {
            attr("autocomplete", it.toString())
        }
    }
}

@Composable
fun textField(
    value: String,
    label: String? = null,
    labelContent: ContentBuilder<HTMLLabelElement>? = null,
    isValid: Boolean? = null,
    message: String? = null,
    type: InputType<String> = InputType.Text,
    floating: Boolean = true,
    additionalClasses: String? = "mb-3",
    attrs: AttrBuilderContext<HTMLInputElement>?=null,
    disabled: Boolean = false,
    id: String?=null,
    autocomplete: Boolean? = null,
    onBlur: (()->Unit)? = null,
    requestFocus: Boolean = false,
    setter: (String) -> Unit,
): String {

    val controlId: String = remember { randomId(17) }

    val hasLabel = label != null || labelContent != null
    Div({
        if (hasLabel && floating) classes("form-floating")
        additionalClasses?.let { classes(it) }
    }) {
        if (hasLabel && !floating) {
            Label(controlId) {
                label?.let { Text(it) }
                labelContent?.invoke(this)
            }
        }
//        Input(type) {
//            id(controlId)
//            onInput {
//                try {
//                    setter(it.value)
//                }
//                catch(t: Throwable) {
//                    console.error("unexpected exception in input handler")
//                    t.printStackTrace()
//                }
//            }
//            classes("form-control")
//            value(value)
//            if (isValid == true) classes("is-valid")
//            else if (isValid == false) classes("is-invalid")
//            if (hasLabel) placeholder("введите значение")
//            attrs?.invoke(this)
//        }

        textInputHeadless(
            controlId,
            value,
            hasLabel,
            if (!hasLabel) "" else null,
            isValid,
            type,
            attrs,
            disabled,
            id,
            autocomplete = autocomplete,
            onBlur = onBlur,
        ) { newValue -> setter(newValue) }

        if (hasLabel && floating) {
            Label(controlId) {
                label?.let { Text(it) }
                labelContent?.invoke(this)
            }
        }
        if (message != null) {
            Div({
                when (isValid) {
                    true -> classes("valid-feedback")
                    false -> classes("invalid-feedback")
                    else -> classes("small")
                }
            }) {
                Text(message)
            }
        }
    }

    if( requestFocus ) {
        LaunchedEffect(true) {
            delay(600)
            (window.document.getElementById(controlId) as? HTMLInputElement)
               ?.also { console.log("focused item $it") }
               ?.focus()
                   ?: console.error("can't find text input for a focus()")
        }
    }

    return controlId
}