package views

import Router
import androidx.compose.runtime.*
import client
import controls.*
import kotlinx.browser.window
import net.sergeych.intecowork.api.ApiUserDetails
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement
import tools.ThemedSvg

private enum class HomeGuestMode {
    Landing, Registration, Authentication
}

@Composable
fun LoggedIn(useFluid: Boolean = false, f: @Composable ElementScope<HTMLDivElement>.(ApiUserDetails) -> Unit) {
    var mode by remember {
        mutableStateOf(
            if (Router.locationPathWithHash() != "") HomeGuestMode.Authentication else HomeGuestMode.Landing
        )
    }

    LaunchedEffect(true) {
        if (Router.locationPathWithHash() != "" && !client.isLoggedIn) {
            Toaster.info("для доступа необходимо войти в систему")
        }
    }

    @Composable
    fun backButton() {
        Bn({
            classes("btn-link")
            onClick { mode = HomeGuestMode.Landing }
        }) {
            Text("отмена")
        }
        if (mode == HomeGuestMode.Authentication) {
            Hr()
            Di("text-center") {
                Bn({
                    classes("btn-link")
                    onClick {
                        Router.push("/restore_access")
                    }
                }) {
                    Text("использовать секретный код")
                }
            }
        }
    }

    val footer: ContentBuilder<HTMLDivElement> = {
        Di("p-0 m-0 mt-5 mb-3 w-100", {
            style {
                position(Position.Absolute)
                left(0.px)
                right(0.px)
//                height(30.percent)
            }
        }) {
            Hr()
            Di("container mt-3 mb-4") {
                Table({
                    classNames("w-100 d-none d-md-table")
                }) {
                    Tbody {
                        Tr {
                            Td({
                                style {
                                    width(33.percent)
                                    whiteSpace("nowrap")
                                }
                            }) {
                                linkToBTLA()
                            }
                            Td {
                                linkToBugTracker("w-100 text-center")
                            }
                            Td({
                                style {
                                    width(33.percent)
                                    whiteSpace("nowrap")
                                }
                                classes("text-end")
                            }) {
                                linkToTelegram()
                            }
                        }
                    }
                }
                Di("d-md-none text-center") {
                    linkToBTLA()
                    linkToBugTracker()
                    linkToTelegram()
                }
            }
        }
    }

    Template(useFluid, footerNotLoggedIn = if (mode == HomeGuestMode.Landing) footer else null) { currentUser ->
        if (currentUser != null)
            f(currentUser)
        else {
//            CenteredNarrowForm {
            if (mode != HomeGuestMode.Landing) {
                Di("text-center") {
                    Br {}
                    H1 {
                        Img("/logo-candy1.svg") {
                            style {
                                height(1.1.em)
                            }
                            classes("me-1")
                        }
                        Text("доколлабр.рф")
                    }
                    P({ classes("text-secondary") }) { Text("Бета верcия проекта") }
                }
            }
            when (mode) {
                HomeGuestMode.Landing ->
                    LandingPage { mode = it }

                HomeGuestMode.Registration ->
                    ComfortableWidth("col-lg-8 col-xl-6") {
                        Registration {
                            Bn({
                                classes("btn-success")
                                onClick { mode = HomeGuestMode.Authentication }
                            }) {
                                Text("Уже зарегистрирован")
                            }
                            backButton()
                        }
                    }

                HomeGuestMode.Authentication -> ComfortableWidth("col-lg-8 col-xl-6") {
                    Authentication {
                        Bn({
                            classes("btn-secondary")
                            onClick { mode = HomeGuestMode.Registration }
                        }) {
                            Text("Зарегистрироваться")
                        }
                        backButton()
                    }
                }
            }
        }
    }
}

@Composable
fun linkToTelegram() {
    A("https://t.me/docollabr", {
        target(ATarget.Blank)
        classes("text-dark")
    }) {
        +"Tелеграм канал"
    }
}

@Composable
fun linkToBugTracker(styles: String? = null) {
    Div({
        styles?.let { classNames(it) }
    }) {
        A("mailto:help@docollabr.ru", {
            classNames("text-dark")
        }) {
            +"Cообщить об ошибке"
        }
    }
}

@Composable
fun linkToBTLA() {
    A("/btla", {
        target(ATarget.Blank)
        classes("text-dark")
    }) {
        +"Cоглашение бета-тестера1"
    }
}

@Composable
private fun LandingPage(setMode: (HomeGuestMode) -> Unit) {

    Div({
        classNames("x-0 w-100")
    }
    ) {
        Table({
            classNames("start-0 end-0 mt-4 position-absolute mx-3")
        }) {
            Tr {
                Td({
                    style {
                    }
                }) {
                    Img("/logo-candy1.svg") {
                        style {
                            height(3.2.em)
                        }
                    }
                }
                Td({
                    classNames("align-middle")
                    style {
                        width(100.percent)
                    }
                }) {
                    Di("ms-2 mb-1") {
                        Di("size3") { +"доколлабр" }
                        Di("small-muted", {
                            style {
                                marginTop(-0.2.em)
                            }
                        }) { +"бета-версия" }
                    }
                }
                Td({
                    style {
                        whiteSpace("nowrap")
                    }
                }) {
                    A("https://t.me/docollabr", {
                        target(ATarget.Blank)
                        classes("link-noborder", "small")
                    }) {
                        Img("/telegram.svg") {
                            style {
                                height(1.5.em)
                            }
                            classes("me-3")
                        }
                        Spa("link2 d-none d-sm-inline") { +"Telegram канал" }
                    }
                }
            }
        }
    }

    ComfortableWidth {
        Div({
            style {
                marginTop(7.em)
            }
        })
        Hr {}// { style { color(Color("#FFFFFF")) }}
        Di("text-center whitespace-nowrap") {
            ThemedSvg("/welcome-left", "d-none d-md-inline", {
                style {
                    height(9.em)
                }
            })
            Img("/mascots/welcome.svg") {
                style {
                    height(15.em)
                }
            }
            ThemedSvg("/welcome-right", "d-none d-md-inline", {
                style {
                    height(9.em)
                }
            })
        }
        Di("text-center fw-bold m-2") {
            +"Приглашаем вас к бета-тестированию текстового редактора для совместной работы"
        }
        Di("text-center fs-2 fw-bold m-3") {
            +"Docollabr"
        }
        P {
            +"Мы разработали защищенный текстовый редактор,  "
            Bold("До")
            +"кументы с "
            Bold("коллаб")
            +"о"
            Bold("р")
            +"""ацией, для совместной работы на базе сквозного шифрования. Мы 
            """
            A("/about_security", { target(ATarget.Blank) }) {
                +"уделили максимум внимания защите данных"
            }
            +""", чтобы содержимое ваших документов было доступно только вам, и тем, с кем вы сами решите
            поделиться. Провайдеры, операторы связи, прочие посредники в сети, и даже мы сами — никогда не
            сможем прочесть ваши документы.
        """.trimIndent()
        }
        P {
            +"""
            Все шифрование происходит на вашем устройстве, при открытии и во время сохранения документа. 
            Нам удалось решить непростую задачу - исключить возможность утечки информации из ваших 
            документов, и теперь мы приглашаем вас познакомиться с системой и вместе сделать ее лучше.
        """.trimIndent()
        }
        P {
            +"""
            В настоящий момент мы предлагаем удобный редактор для пользователей ПК и возможность 
            читать и управлять доступом к документам с мобильных платформ.
        """.trimIndent()
        }

        P {
            +"""
           Не упустите возможность получить пригласительный 1 ГБ (полгода бесплатно), а тем, кто будет активно
            помогать сделать систему лучше, мы продлим бесплатный период еще на три года, а самым-самым подарим
            дополнительные гигабайты.
        """.trimIndent()
        }
        P {
            +"""
            Мы ценим вклад каждого, кто помогает нам улучшать продукт в период бета-тестирования, и обещаем приятный 
            подарок для тех, кто пойдет с нами по этому пути!
        """.trimIndent()
        }
        P {
            +"""
            Подпишитесь на наш телеграм канал, чтобы получить уведомления об 
            изменениях:
        """.trimIndent()
        }
        Di("w-100 text-center my-3") {
            Btn("Подписаться на телеграм канал", Icon.Send, Variant.Primary) {
                window.open("https://t.me/docollabr", target = "")
            }
        }
        P {
            +"""
            Чтобы ограничения бета-версии не стали для вас неприятным сюрпризом, настоятельно просим вас прочитать 
            соглашение бета-тестера: 
        """.trimIndent()
            A("/btla", {
                target(ATarget.Blank)
            }) {
                +" пожалуйста, прочитайте соглашение бета-тестера"
            }
        }
        Di("m-3 fw-bold text-center") {
            +"""
            Теперь, когда вы его прочитали, давайте приступим к регистрации:
        """.trimIndent()
        }

        Row {
            Col("sm-6", { classes("mb-2") }) {
                Bn({
                    primary("w-100")
                    onClick { setMode(HomeGuestMode.Registration) }
                }) {
                    Text("Регистрация")
                }
            }
            Col("sm-6") {
                Bn({
                    success("w-100")
                    onClick { setMode(HomeGuestMode.Authentication) }
                }) {
                    Text("Существующий тестер")
                }
            }
        }
    }
}


@Composable
fun ComfortableForm(f: ContentBuilder<HTMLDivElement>) {
    ComfortableWidth("col-lg-8 col-xl-6", f)
}

@Composable
fun ComfortableWidth(widthClasses: String = "col-lg-10 col-xl-8", f: ContentBuilder<HTMLDivElement>) {
    Di("row justify-content-center") {
        Di(widthClasses) {
            f()
        }
    }
}