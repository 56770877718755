package net.sergeych.intecowork.api

import kotlinx.serialization.Serializable

/**
 * The information publically available:
 *
 * @param id identifies a user accross the system
 * @param name non-unique name that user has choosen to show to public
 * @param nameIsApproved if true, the system administration has checked this name and
 *          somehow checked its vaildiy and identity
 */
@Serializable
data class ApiUser(
    val id: Long = 0,
    val name: String,
    val nameIsApproved: Boolean = false
) {

    companion object {
        val invalidNames = setOf(
            "система", "админ", "босс", "директор", "саппорт", "сисадмин", "модер", "модератор",
            "system", "sa", "sysadmin", "boss", "chief", "support", "moderator"
        )

        /**
         * Check the username is valid. If it is not, return error string
         * @return null if name is valid or error description string otherwise
         */
        fun checkName(name: String): String? {
            return when {
                name.trim() != name -> "не должно быть пробелов в начале и конце"
                name.isBlank() -> "не может быть пустым"
                name.length < 5 -> "слишком короткое"
                name.lowercase() in invalidNames -> "уже есть в системе"
                !name[0].isLetter() -> "должно начитаться с буквы"
                else -> null
            }
        }

        /**
         * Ensure user name is valid
         * @throws InvalidUserNameException otherwise
         */
        fun ensureNameIsValid(name: String) {
            checkName(name)?.let { throw InvalidUserNameException(it) }
        }
    }

}

@Serializable
data class UserFindArgs(
    val pattern: String,
    val excludeIds: List<Long> = listOf()
) {
    constructor(p: String,exclude: Collection<ApiUser>) : this(p, exclude.map { it.id })
}