@file:Suppress("UnsafeCastFromDynamic")

package views

import DocsCache
import Router
import androidx.compose.runtime.*
import client
import controls.*
import document.EventManager
import document.ParagraphStyle.Companion.Predefined
import document.fts.FtsMatch
import document.fts.fullTextSearch
import editor.Editor
import editor.operations.LAST_MIGRATION
import editor.operations.caretToHome
import editor.operations.getState
import editor.plugins.importBlocks
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.CompletableDeferred
import kotlinx.coroutines.await
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import modalInputString
import modalWaitPanel
import net.sergeych.TokenMatch
import net.sergeych.intecowork.api.*
import net.sergeych.intecowork.doc.DocBlock
import net.sergeych.intecowork.doc.IcwkDocument
import net.sergeych.mp_tools.globalLaunch
import net.sergeych.parsec3.optStored
import net.sergeych.parsec3.stored
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.colspan
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.asList
import org.w3c.files.File
import settingsStore
import tools.*
import withConfirm
import kotlin.js.Promise

//val reSpace = """\s+""".toRegex()

suspend fun createDocument(parentId: Long?) {
//    Toaster.launchCatching {
    val eventManager = EventManager {
        p(Predefined.Title.style) {
            t("")
        }
    }
    val dc = Editor(eventManager)
    dc.caret = dc.caretToHome()

    val newDoc = IcwkDocument.create(client, DocType.Text, parentId, DocState.pack(dc.getState()))
    newDoc.modify {
        put(DocBlock.pack(BlockType.Body, guid = eventManager.firstBlock.guid) {
            eventManager.firstBlock
        })
    }
    newDoc.mergeAll()
    newDoc.saveMeta(newDoc.meta.copy(formatVersion = LAST_MIGRATION))
    Router.push("/documents/${newDoc.docId}")
//    }
}

fun renameDocument(doc: IcwkDocument) {
    if (!doc.role.canWrite) {
        Toaster.error("вы не можете переименовать этот документ")
    } else {
        modalInputString("Введите новое имя", initialValue = doc.title ?: "") { name ->
            if (name != null) {
                if (name.isBlank()) return@modalInputString "имя не может быть пустым"
                if (name.trim() != name) return@modalInputString "имя не может иметь пробелы в начале и в конце"
                doc.setTitle(name)
            }
            null
        }
    }
}

suspend fun createFolder(parentId: Long?): Long? {
    val result = CompletableDeferred<Long?>()

    modalInputString("Введите название папки") { name ->
        if (name != null) {
            if (name.isBlank()) return@modalInputString "имя не может быть пустым"
            if (name.trim() != name) return@modalInputString "имя не может иметь пробелы в начале и в конце"
            val t = Toaster.defer {
                client.createFolder(parentId, name).docId
            }
            result.complete(t.await())
        } else result.complete(null)
        null
    }

    return result.await()
}

@Suppress("UNUSED_PARAMETER")
fun docxToJSON(file: File): Promise<dynamic> {
    val json = js("docxtojson.default(file)")

    return json
}

suspend fun importDOCX(parentId: Long?, file: File) {
    val json = docxToJSON(file).await()
    val blocks = importBlocks(json)
    val eventManager = EventManager()
    eventManager.initializeWithBlocks(blocks)
    eventManager.calculateViewProperties()
    val dc = Editor(eventManager)
    dc.caret = dc.caretToHome()
    val newDoc = IcwkDocument.create(client, DocType.Text, parentId, DocState.pack(dc.getState()))
    newDoc.modify {
        eventManager.allBlocks.forEach {
            put(DocBlock.pack(BlockType.Body, guid = it.guid, prev = it.prevBlockGuid, next = it.nextBlockGuid) {
                it
            })
        }
    }
    newDoc.mergeAll()
    newDoc.setTitle(file.name)
    Router.push("/documents/${newDoc.docId}")
}

data class MarkedDoc(
    val doc: IcwkDocument,
    val checked: Boolean = false,
    val size: Long,
    val searchEntry: FtsMatch? = null
) {
    val isFolder by lazy { doc.type == DocType.Folder }
    fun toggle(): MarkedDoc = copy(checked = !checked)
}

var storedSortBy by settingsStore.stored(2)
var storedSortReverse by settingsStore.stored(false)

@Composable
fun Directory() {
    val scope = rememberCoroutineScope()
    var storedMode: Int? by storedParam<Int>()
    var selectedMode by remember { mutableStateOf(storedMode ?: 0) }

    var sortBy by remember { mutableStateOf(storedSortBy) }
    var sortReverse by remember { mutableStateOf(storedSortReverse) }

    val list = mutableStateListOf<MarkedDoc>()
    var search by remember { mutableStateOf("") }
    var searchInProgress by remember { mutableStateOf(search != "") }
    var lastFolderId by settingsStore.optStored<Long>()
    var folderId: Long? by remember { mutableStateOf(lastFolderId) }
    var importDOCXFile: File? by remember { mutableStateOf(null) }
    val importFileCtrlId = remember { randomId(11) }

    fun compare(mda: MarkedDoc, mdb: MarkedDoc): Int {
        val a = mda.doc
        val b = mdb.doc
        val cmp = when (sortBy) {
            0 -> a.titleOrDefault.compareTo(b.titleOrDefault)
            1 -> mda.size.compareTo(mdb.size)
            2 -> a.updatedAt.compareTo(b.updatedAt)
            else -> throw Exception("invalid sort index")
        }
        return if ((sortBy == 0 && sortReverse) || (sortBy != 0 && !sortReverse)) -cmp else cmp
    }

    fun resort() {
        list.sortWith(::compare)
    }


    LaunchedEffect(selectedMode, search, folderId) {
        if (search.isNotBlank()) {
            searchInProgress = true
            fullTextSearch(search).collect { fr ->
                if( fr.inProgress ) {
                    list.clear()
                    list.addAll(fr.matches.map {
                        MarkedDoc(
                            it.document,
                            size = DocsCache.treeSize(it.document),
                            searchEntry = it
                        )
                    })
                }
                else searchInProgress = false
            }
        }
    }

    LaunchedEffect(selectedMode, search, folderId) {
        val source = when (selectedMode) {
            0 ->
                if (search.isNotBlank()) DocsCache.allFlow else DocsCache.openFolder(folderId)

            1 -> DocsCache.myFlow
            2 -> DocsCache.sharedFlow
            3 -> DocsCache.trashedFlow
            else -> {
                console.error("недопустимый индекс группы файлов $selectedMode")
                DocsCache.openFolder(folderId)
            }
        }
        launch {
            source.collect { src ->
                if (search.isBlank()) {
                    list.clear()
                    list.addAll(src
                        .map { MarkedDoc(it, size = DocsCache.treeSize(it)) }
                        .sortedWith(::compare))
                }
            }
        }
        launch {
            // calculate/recalculate folder size
            client.events.collect {
                when (it) {
                    is ApiEvent.Doc.ParentChanged,
                    is ApiEvent.Doc.Erased,
                    is ApiEvent.Doc.BodyChanged -> {
                        launch {
                            var changed = false
                            for ((i, d) in list.withIndex()) {
                                val newSize = DocsCache.treeSize(d.doc)
                                if (newSize != d.size) {
                                    list[i] = d.copy(size = newSize)
                                    changed = true
                                }
                            }
                            if (changed)
                                resort()
                        }
                    }

                    else -> {}
                }
            }
        }
    }

    @Composable
    fun DirectoryContextMenu(d: MarkedDoc, i: Int) {
        Di("dropdown d-inline-block p-0 m-0 cursor-pointer") {
            Div({
                attr("data-bs-toggle", "dropdown")
                id("cm_${d.doc.docId}")
            }) {
                Icon.ThreeDotsVertical.render(1.em, "ms-0", "me-0", "p-0")
            }

            Ul({
                classes("dropdown-menu")
            }) {
                DropdownUlink("Выбрать", Icon.Check) {
                    list[i] = d.toggle()
                }
                DropdownUlink(
                    "Переименовать", Icon.InputCursorText,
                    enabled = d.doc.role.canWrite
                ) {
                    renameDocument(d.doc)
                }
                DropdownUlink(
                    "Переместить", Icon.FolderSymlink,
                    enabled = d.doc.role.canWrite
                ) {
                    selectFolder(
                        folderId,
                        "Переместить \"${d.doc.titleOrDefault}\" в"
                    ) {
                        if (!isCancelled) {
                            if (selectedFolderId != lastFolderId) {
                                Toaster.launchCatching {
                                    d.doc.moveTo(selectedFolderId)
                                    close()
                                }
                            } else close()
                        } else close()
                    }
                }
                Hr { classes("dropdown-divider") }
                if (selectedMode != 3) {
                    DropdownUlink("Удалить", Icon.Recycle) {
                        withConfirm(
                            if (d.doc.role.isOwner)
                                """
                                            Вы действительно хотите переместить документ "${d.doc.titleOrDefault}
                                            в корзину?
                                        """".trimIndent()
                            else """
                                            Вы действительно хотите удалить доступ к общему документу "${d.doc.titleOrDefault}?
                                            Это действие может отменить только владелец, документа вновь пригласив Вас.
                                            
                                        """.trimIndent(),
                            yesVariant = Variant.Danger,
                            icon = Icon.Recycle
                        ) {
                            console.log("deleting")
                            Toaster.launchCatching {
                                d.doc.moveToTrash()
                                info("Документ перемещен в корзину")
                            }
                        }
                    }
                } else {
                    DropdownUlink(
                        "восстановить",
                        Icon.Recycle,
                    ) {
                        withConfirm(
                            """
                                            Вы действительно хотите восстановить документ "${d.doc.titleOrDefault}"
                                            из корзины?
                                        """.trimIndent(),
                            yesVariant = Variant.Success,
                            icon = Icon.Recycle
                        ) {
                            console.log("restoring")
                            Toaster.launchCatching {
                                d.doc.restoreFromTrash()
                                info("Документ восстановлен из корзины")
                            }
                        }
                    }
                    Hr { classes("dropdown-divider") }
                    DropdownUlink(
                        "Уничтожить",
                        Icon.Trash,
                        variant = Variant.Danger
                    ) {
                        withConfirm(
                            """
                            Вы действительно хотите необратимо уничтожить документ "${d.doc.titleOrDefault}"
                            и освободить занимаемое им место?
                        """.trimIndent(),
                            yesVariant = Variant.Danger,
                            icon = Icon.Trash,
                            iconVariant = Variant.Danger
                        ) {
                            console.log("destroying")
                            Toaster.launchCatching {
                                recursiveErase(d.doc)
                                info("Успешное уничтожение")
                            }
                        }
                    }

                }
            }
        }
    }

    @Composable
    fun ListHeader(
        sortIndex: Int,
        text: String,
        colSpan: Int = 1,
        showSince: String? = null,
        align: String = "center"
    ) {
        val canSort = search.isBlank()
        Td({
            if (colSpan > 1) colspan(colSpan)
            style {
                if (canSort)
                    cursor("pointer")
                whiteSpace("nowrap")
            }
            classes("text-$align", "px-1")
            showSince?.let { classes("d-none", "d-$it-table-cell") }
            if (canSort)
                onClick {
                    if (sortIndex == sortBy) sortReverse = !sortReverse
                    else {
                        sortBy = sortIndex
                        sortReverse = false
                        storedSortBy = sortBy
                        storedSortReverse = sortReverse
                    }
                    resort()
                }
        }) {
            Text(text)
            if (sortIndex == sortBy && canSort) {
                if (!sortReverse) Icon.SortUp.render(1.em, "bg-transparent", "ms-1")
                else Icon.SortDown.render(1.em)
            }
        }
    }

    SidebarLayout("Документы", {
        Hr { classes("my-2") }

        Btn("новый документ", Icon.RichText, variant = Variant.Success, cls = "w-100", mb = 2, outline = false) {
            scope.launch {
                createDocument(folderId)
            }
        }
        Btn("новая папка", Icon.FolderPlus, variant = Variant.Success, cls = "w-100", mb = 2, outline = true) {
            scope.launch {
                closeOffcanvas()
                createFolder(folderId)
            }
        }
        if (selectedMode == 0) {
            Di("sidebar-folders") {
                Div({
                    style {
                        fontSize(0.9.em)
                    }
                }) {
                    FolderTree(folderId, showIcons = true) { id ->
                        folderId = id
                        search = ""
                        lastFolderId = folderId
                    }
                }
            }
            Hr { classes("my-1") }
        }

        Di("m-2 mx-3") {
            for ((i, s) in arrayOf("Все", "Где я владелец", "Где я соавтор", "Удаленные").withIndex()) {
                Di("form-check") {
                    val buttonId = "list-type:$i"
                    Input(InputType.Radio) {
                        classes("form-check-input")
                        checked(i == selectedMode)
                        id(buttonId)
                        onChange {
                            selectedMode = i
                            storedMode = selectedMode
                            closeOffcanvas()
                        }
                    }
                    Label(buttonId, {
                        classes("form-check-label")
                        style { cursor("pointer") }
                    }) { Text(s) }
                }
            }
        }

        Hr { classNames("my-1") }
        Ulist {
            Ulink(
                Icon.RichText,
                "импорт .docx",
            ) {
                window.document.getElementById(importFileCtrlId)?.let {
                    // FIXME: sometimes doesn't work in chrome. After chrome reopen it usually works
                    (it as HTMLInputElement).click()
                }
            }
        }
        val selected = list.filter { it.checked }.map { it.doc }
        if (selected.isNotEmpty()) {
            Ulist {
                if (selectedMode == 0) MoveMarked(folderId, selected)
                DeleteMarked(selectedMode, selected)
                if (selectedMode == 3) EraseMarked(selected)
            }
        }
        if (selectedMode == 3 && list.size > 0) {
            Ulist { ClearRecycleBin(list.map { it.doc }) }
        }
    }, {
        DisposableEffect(true) {
            onDispose {
                showTopTitle(true)
            }
        }
        Di("input-group") {
            TextInput(search) {
                classNames("form-control")
                placeholder("поиск в документах")
                onKeyDown {
                    if (it.key == "Escape") {
                        search = ""
                        it.stopPropagation()
                    }
                }
                onInput { search = it.value }
                onFocusIn {
                    showTopTitle(false)
                }
                onFocusOut {
                    showTopTitle(true)
                }
            }
            Span({ classNames("input-group-text") }) {
                if( search != "" && searchInProgress )
                    Div({ classes("spinner-border","spinner-border-sm", "me-1") })
                else
                    Icon.Search.render()
            }
        }
    }) {
        Div({
            style {
                property("max-width", "23cm")
                property("margin-left", "auto")
                property("margin-right", "auto")
            }
            classNames("mt-2")
        }) {
            Input(InputType.File) {
                attr("id", importFileCtrlId)
                style { display(DisplayStyle.None) }
                // FIXME: need to set value
                onChange {
                    scope.launch {
                        val file = it.target.files?.asList()?.firstOrNull()

                        console.log("on change")

                        file?.let {
                            Toaster.launchCatching(onFinally = { importDOCXFile = null }) {
                                importDOCXFile = it
                                console.log("file set")
                                importDOCX(folderId, it)
                            }
                        }
                    }
                }
            }

            Row({
                style {
                    position(Position.Relative)
                }
            }) {
                Col("12", { classes("mt-2") }) {
//            TabsHeader(selectedMode, listOf("все", "мои", "чужие", "корзина")) {
//                selectedMode = it
//            }
                    importDOCXFile?.let {
                        WaitPanel("Импортирую ${it.name}")
                    }
                    if (list.isEmpty() && (DocsCache.loading || selectedMode != 0 || search.isNotBlank())) {
                        if (DocsCache.loading) {
                            WaitPanel("загружаю список документов")
                        } else {
                            if( !searchInProgress ) {
                                Di("mt-3 alert alert-secondary") {
                                    if (search != "") {
                                        if (!searchInProgress)
                                            +"Ничего не найдено."
                                    } else {
                                        Text("В данный момент таких документов нет. ")
                                        when (selectedMode) {
                                            0, 1 -> Text("Создайте документ при помощи кнопки \"создать\" выше.")
                                            2 -> Text(
                                                """
                            Здесь отображаются документы, к которым Вам предоставили доступ другие участники.
                            Сейчас документы в совместном доступе отсутствуют.""".trimIndent()
                                            )

                                            3 -> Text(
                                                """
                            Это корзина, здесь появляются документы, которые Вы удаляете. Документы хранятся в корзине
                            неограниченно, пока Вы сами их не восстановите или не уничтожите. Когда место в хранилище
                            подойдет к концу, я предупрежу и предложу очистить корзину.
                        """.trimIndent()
                                            )
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        var checkAllState by remember { mutableStateOf(false) }
                        val selectedCount = list.count { it.checked }
                        if (selectedMode == 3)
                            ShowHelpOnce(
                                "recycle", """
                                Файлы хранятся в корзине неограниченно долго, пока вы их явно не уничтожите, или не
                                восстановите.
                                
                                Документы, находящиеся в корзине, по прежнему доступны другим участникам и по публичным 
                                ссылкам, пока не будут явно уничтожены владельцем.
                            """.trimIndent()
                            )
                        ResponsiveTable {
                            Tr({ classes("bg-themed-light", "fs-6", "p-1") }) {
                                if (selectedCount > 0) {
                                    Td {
                                        CheckboxInput(checkAllState) {
                                            classes("form-check-input")
                                            onChange {
                                                checkAllState = !checkAllState
                                                for ((i, d) in list.withIndex()) {
                                                    list[i] = d.copy(checked = checkAllState)
                                                }
                                            }
                                        }
                                    }
                                }
                                Td {}
                                ListHeader(0, "название", align = "left")
                                ListHeader(1, "размер")
                                ListHeader(2, "изменён", showSince = "sm")
                                Td {}
                            }
                            if (folderId != null && search.isBlank()) {
                                Tr({
                                    style { cursor("pointer") }
                                    onClick {
                                        folderId?.let { id ->
                                            scope.launch {
                                                folderId = DocsCache.getDoc(id)?.parentId
                                                lastFolderId = folderId
                                            }
                                        }
                                    }
                                }) {
                                    if (selectedCount > 0)
                                        Td {}
                                    Td { Icon.FolderOpen.render(1.2.em, "p-0", "m-0", "me-1") }
                                    Td {
                                        +".."
                                    }
                                    Td { +"" }
                                    Td { +"" }
                                    Td { +"" }
                                }
                            }
                            val showFolderInfo = search.isNotBlank() || selectedMode == 1 || selectedMode == 2

                            for ((i, d) in list.withIndex()) {

                                val searchTokens: List<String> = if (search.isBlank())
                                    emptyList()
                                else
                                    TokenMatch.tokenize(search).map { it.key }

                                val isFolder = d.doc.type == DocType.Folder
                                // do not show folders in improper modes:
                                if (selectedMode != 0 && selectedMode != 3 && isFolder) continue
                                val fixName = list.any {
                                    it.doc.docId != d.doc.docId && it.doc.titleOrDefault == d.doc.titleOrDefault
                                }

                                Tr({
                                    classNames("hover-highlight")
                                }) {
                                    if (selectedCount > 0) {
                                        Td({ style { width(1.percent) } }) {
                                            CheckboxInput(d.checked) {
                                                classes("form-check-input")
                                                onClick {
                                                    list[i] = d.toggle()
                                                    it.stopPropagation()
                                                }
                                            }
                                        }
                                    }
                                    Td({
                                        style { width(1.percent); whiteSpace("nowrap"); cursor("pointer") }
                                        onClick {
                                            list[i] = d.toggle()
                                            it.stopPropagation()
                                        }
                                    }) {
                                        val icon = if (d.isFolder) Icon.Folder else Icon.RichText
                                        icon.render(1.3.em, "me-1", "p-0")
                                    }
                                    Td({
                                        if (selectedMode != 3) {
                                            style { cursor("pointer") }
                                            onClick {
                                                if (isFolder) {
                                                    folderId = d.doc.docId
                                                    search = ""
                                                    lastFolderId = folderId
                                                } else {
                                                    Router.push("/documents/${d.doc.docId}")
                                                    lastFolderId = d.doc.parentId
                                                }
                                            }
                                        }
//                                        onContextMenu {
//                                            it.preventDefault()
//                                            it.stopPropagation()
//                                            openDropdown("cm_${d.doc.docId}")
//                                        }
                                    }) {
                                        Di("bigger") {
                                            if (searchTokens.isEmpty())
                                                Text(d.doc.titleOrDefault)
                                            else
                                                Highlight(d.doc.titleOrDefault, searchTokens)
                                            if (fixName) {
                                                Spa("text-secondary") {
                                                    +"(#${d.doc.docId})"
                                                }
                                            }
                                            Spa("prop-icons") {
                                                DocPropIcons(d)
                                            }
                                            d.searchEntry?.let { m ->
                                                if (m is FtsMatch.TextMatch) {
                                                    Di("small fst-italic") {
                                                        Highlight(m.textFragment, searchTokens)
                                                    }
                                                }
                                            }
                                        }
                                        if (showFolderInfo) {
                                            Di("small-muted") {
                                                ShowPath(d.doc.parentId) {
                                                    selectedMode = 0
                                                    search = ""
                                                    folderId = it.id
                                                }
                                            }

                                        }
                                    }
                                    Td({
                                        classes("pe-2")
                                        style {
                                            width(1.percent)
                                            whiteSpace("nowrap")
                                            textAlign("right")
                                        }
                                    }) {
                                        +d.size.toDataSize()
                                    }
                                    Td({
                                        style {
                                            width(1.percent)
                                            whiteSpace("nowrap")
                                        }
                                        classes("d-none", "d-sm-table-cell")
                                    }) {
                                        RelativeTime(d.doc.updatedAt)
//                                Text(d.updatedAt.shortString())
                                    }
                                    Td({
                                        style {
                                            width(1.percent)
                                            whiteSpace("nowrap")
                                        }
                                    }) {
                                        if (selectedCount == 0) DirectoryContextMenu(d, i)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@Composable
fun DocPropIcons(d: MarkedDoc) {
    var hasLink by remember { mutableStateOf<String?>(null) }
    var hasShare by remember { mutableStateOf<Boolean?>(null) }
    when (hasShare) {
        null -> Icon.ArrowRepeat.render(0.9.em, "d-inline", "me-0", "pe-0", "spin2")
        true -> {
            when (d.doc.role) {
                DocRole.Owner -> Icon.People.render(0.9.em, "ms-0", "me-0", "p-0")
                DocRole.Writer -> Icon.Pen.render(0.9.em, "ms-0", "me-0", "p-0")
                DocRole.Reader -> Icon.Eye.render(0.9.em, "ms-0", "me-0", "p-0")
                DocRole.Commenter -> Icon.ChatLeftText.render(
                    0.9.em,
                    "ms-0",
                    "me-0",
                    "p-0"
                )

                else -> {}
            }
        }

        false -> {}
    }
    when (hasLink) {
        null -> if (hasShare == null)
            Icon.ArrowRepeat.render(0.9.em, "d-inline", "me-0", "pe-0", "spin2")

        else -> {
            Copyable(window.location.origin + "/" + hasLink!!) {
                Icon.Link.render(1.em, "ms-0", "me-1", "p-0")
            }
        }
    }
    LaunchedEffect(d.doc.docId) {
        fun refresh() {
            launch {
                hasLink = d.doc.getPublicShare()
            }
            launch {
                hasShare = d.doc.role != DocRole.Owner ||
                        d.doc.collaborators().size > 1
            }
        }
        refresh()
        d.doc.events().collect {
            if (it is ApiEvent.Doc.ShareChanged) {
                // the problemL it can arrive before the document changes
                // in cache, so we give it the chance (yield is enough I think)
                delay(100)
                refresh()
            }
        }
    }
}

@Composable
fun ClearRecycleBin(source: Collection<IcwkDocument>) {
    Ulink(
        Icon.Trash,
        "очистить корзину",
        extraClasses = "text-danger"
    ) {
        globalLaunch { eraseList(source) }
    }
}

@Composable
fun MoveMarked(currentFolderId: Long?, selected: List<IcwkDocument>) {
    val t = docsAndFolders1(selected)
    Ulink(Icon.FolderSymlink, "Переместить $t") {
        selectFolder(currentFolderId, "Переместить $t в") {
            close()
            if (!isCancelled) {
                modalWaitPanel("перемещение $t") {
                    client.moveAllTo(selected.map { it.docId }, selectedFolderId)
                }
            }
        }
    }
}

@Composable
fun DeleteMarked(selectedMode: Int, selected: List<IcwkDocument>) {
    val t = (if (selectedMode != 3) "удалить " else "восстановить ") + docsAndFolders(selected)

    Ulink(
        if (selectedMode != 3) Icon.Trash else Icon.Recycle,
        t
    ) {
        if (selectedMode != 3) {
            globalLaunch {
                val countDelete = selected.count { it.role == DocRole.Owner && it.type != DocType.Folder }
                val countFolders = selected.count { it.role == DocRole.Owner && it.type == DocType.Folder }
                val lostShare = selected.size - countDelete - countFolders
                withConfirm(
                    title = "Подтвердите операцию:",
                    body = {
                        Ul {
                            if (countDelete > 0) {
                                Li {
                                    +"${pluralizeDocWord(countDelete)} ${
                                        pluralize(
                                            countDelete,
                                            "будет помещен",
                                            "будут помещены",
                                        )
                                    } в корзину."
                                }
                            }
                            if (countFolders > 0) {
                                Li {
                                    Text(
                                        "$countFolders " +
                                                pluralize(countFolders, "папка", "папки", "папок") +
                                                " со всем содержимым " +
                                                pluralize(
                                                    countFolders,
                                                    "будет помещена",
                                                    "будут помещены",
                                                ) + " в корзину."
                                    )
                                }
                            }
                            if (lostShare > 0) {
                                Li {
                                    +"Вы потеряете доступ к ${
                                        pluralize(
                                            lostShare,
                                            "$lostShare документу",
                                            "$lostShare документам"
                                        )
                                    }, эта операция не может быть отменена."
                                }
                            }

                        }
                    }
                ) {
                    selected.forEach { d -> globalLaunch { Router.runCatching { d.moveToTrash() } } }
                }
            }
        } else {
            globalLaunch { for (d in selected) Router.runCatching { d.restoreFromTrash() } }
        }
    }
}

fun docsAndFolders(selcted: Collection<IcwkDocument>): String {
    val folderCount = selcted.count { it.type == DocType.Folder }
    val docCount = selcted.size - folderCount
    return docsAndFolders(docCount, folderCount)
}

fun docsAndFolders1(selcted: Collection<IcwkDocument>): String {
    val folderCount = selcted.count { it.type == DocType.Folder }
    val docCount = selcted.size - folderCount
    return docsAndFolders1(docCount, folderCount)
}

fun docsAndFolders(docCount: Int, folderCount: Int): String {
    var t = ""
    if (docCount > 0) t += pluralizeDocWord(docCount)
    if (docCount > 0 && folderCount > 0) t += " и "
    if (folderCount > 0) t += pluralizeFolderWord2(folderCount)
    return t
}

fun docsAndFolders1(docCount: Int, folderCount: Int): String {
    var t = ""
    if (docCount > 0) t += pluralizeDocWord(docCount)
    if (docCount > 0 && folderCount > 0) t += " и "
    if (folderCount > 0) t += pluralizeFolderWord1(folderCount)
    return t
}

@Composable
fun EraseMarked(selected: List<IcwkDocument>) {
    val count = selected.size
    val folderCount = selected.count { it.type == DocType.Folder }
    val docCount = count - folderCount

    Ulink(
        Icon.ExclamationTriangle,
        "Уничтожить " + docsAndFolders(docCount, folderCount),
        extraClasses = "text-danger"
    ) {
        globalLaunch { eraseList(selected) }
    }
}

suspend fun eraseList(selected: Collection<IcwkDocument>) {
    val countDelete = selected.count { it.role == DocRole.Owner }
    val countFolders = selected.count { it.role == DocRole.Owner && it.type == DocType.Folder }
    val countDocs = countDelete - countFolders
    val lostShare = selected.count { it.collaborators().size > 1 }
    val lostPublic = selected.count { it.getPublicShare() != null }
    withConfirm(
        icon = Icon.Trash,
        iconVariant = Variant.Danger,
        title = "уничтожить ${docsAndFolders(selected)}?",
        body = {
            Ul {
                if (countDocs > 0)
                    Li {
                        Text(
                            "${pluralizeDocWord(countDocs)} ${
                                pluralize(
                                    countDocs,
                                    "будет уничтожен",
                                    "будут уничтожены"
                                )
                            } без возможности восстановления."
                        )
                    }
                if (countFolders > 0)
                    Li {
                        Text(
                            "${pluralizeFolderWord1(countFolders)} ${
                                pluralize(
                                    countFolders,
                                    "будет уничтоженa",
                                    "будут уничтожены"
                                )
                            } со всем содержимым, без возможности восстановления."
                        )
                    }
                if (lostShare > 0)
                    Li {
                        Text(
                            """$lostShare ${
                                pluralize(
                                    lostShare,
                                    "из них будет удален также у соавторов",
                                    "из них будут удалены также у соавторов"
                                )
                            }"""
                        )
                    }
                if (lostPublic > 0) {
                    Li {
                        Text(
                            """${pluralizeDocWord(lostPublic)}
                                    |${
                                pluralize(
                                    lostPublic,
                                    "станет недоступен",
                                    "станут недоступны"
                                )
                            } по публичной ссылке.
                                """.trimMargin()
                        )
                    }
                }
                Br()
                Spa("fw-bold") { +"Эта операция необратима." }
            }
        }) {
        modalWaitPanel("Удаление") {
            selected.forEach {
                recursiveErase(it)
            }
        }
    }
}

suspend fun recursiveErase(doc: IcwkDocument) {
    if (doc.role == DocRole.Owner) {
        if (doc.type == DocType.Folder) {
            println("recursive delete ${doc.docId}: ${doc.titleOrDefault}")
            for (child in DocsCache.listFolder(doc.docId).toList()) {
                recursiveErase(child)
            }
        }
        println("delete ${doc.docId}: ${doc.titleOrDefault}")
        doc.erase()
    } else {
        console.warn("skipping erase not-owned document ${doc.docId}")
    }
}

val IcwkDocument.titleOrDefault: String
    get() = if (title.isNullOrBlank()) "без имени $docId" else title!!

@Suppress("unused")
@Composable
fun ShowSize(doc: IcwkDocument) {
    if (doc.type == DocType.Folder) {
        var currentSize by remember { mutableStateOf(doc.lastCloudSize) }
        var done by remember { mutableStateOf(false) }

        LaunchedEffect(doc.docId) {
            DocsCache.watchSize(doc).collect {
                currentSize = it
                done = true
            }
        }

        if (!done) Icon.ArrowRepeat.render("spin2", "me-1")

        if (currentSize > 0L)
            +currentSize.toDataSize()
    } else
        Text(doc.lastCloudSize.toDataSize())
}

@Composable
fun Highlight(text: String, tokens: List<String>) {
    val id = randomId(11)
    Span({ id(id) }) { +text }
    SideEffect {
        var r = text
        for (t in tokens) {
            val re = Regex("(^|\\s|[(){}-])$t", RegexOption.IGNORE_CASE)
            r = re.replace(r) { "${it.groups[1]?.value ?: ""}<span class='highlight'>${it.value}</span>" }
        }
        document.getElementById(id)?.innerHTML = r
    }
}