package views

import controls.*
import modalDialog
import net.sergeych.mp_tools.globalLaunch
import org.jetbrains.compose.web.dom.Text
import tools.UITheme
import waitConfirm

fun showSecret(secret: String) {
    globalLaunch {
        modalDialog {
            staticBackdrop()
            xl()
            heading("Сохраните секретный ключ!")
            body {
                Di("mv-3") {
                    Text("Секретный ключ, указанный ниже, позволит вам сбросить пароль если вы его забудете. ")
                    Bold("Этот секретный ключ больше никогда не будет показан, поэтому сохраните его сечас, ")
                    Text(
                        """
              |скопируте и сохраните в надежном месте, или сфотографируйте и сохраните фотографию. 
              | если злоумышленник сможет похитить секретный ключ, ваш аккаунт может быть украден.
          """.trimMargin()
                    )
                    Danger(" Без секретного ключа, если вы забудете пароль, восстановить доступ к вашим данным будет невозможно.")
                }
                Di("text-center h3 mt-3") {
                    Copyable(secret, maxLength = 64)
                }
            }
            footer {
                Di("w-100 text-center") {
                    Btn(
                        "Я подтверждаю, что сохранил этот секретный ключ",
                        Icon.ExclamationTriangle,
                        Variant.Warning,
                        outline = UITheme.composeState.value.isDark,
                    ) {
                        close()
                        globalLaunch {
                            if (!waitConfirm("пожалуйста, подтвердите, что вы действительно сохранили секретный ключ")) {
                                showSecret(secret)
                            }
                        }
                    }
                }
            }
        }
    }
}