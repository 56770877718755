package editor.operations

import document.*
import editor.*
import editor.plugins.getLinks
import net.sergeych.mp_logger.warning

fun isConsecutiveSpaces(source: String, addition: String): Boolean {
    return addition == " " && source.lastOrNull() == ' '
}

enum class TextType {
    TEXT,
    LINK
}

data class TextPart(
    val range: IntRange,
    val type: TextType,
    val content: String
)

fun classifyText(text: String): List<TextPart> {
    val links = getLinks(text).toMutableList()

//    console.log(links)

    val parts = mutableListOf<TextPart>()

    var i = 0

    while (i < text.length) {
        val link = links.find { it.start == i }

        if (link != null) {
            parts.add(TextPart(link, TextType.LINK, text.substring(link)))
            links.remove(link)
            i = link.endInclusive + 1
        }
        else {
            val nextLink = links.firstOrNull()

            if (nextLink != null) {
                val range = i until nextLink.first
                parts.add(TextPart(range, TextType.TEXT, text.substring(range)))
                i = nextLink.first
            } else {
                val range = i until text.length
                parts.add(TextPart(range, TextType.TEXT, text.substring(range)))
                i = text.length
            }
        }
    }

    return parts
}

suspend fun textToStyledSpans(text: String, style: TextStyle?): List<Fragment.StyledSpan> {
    val list = mutableListOf<Fragment.StyledSpan>()

    val parts = classifyText(text)

    parts.forEach { p ->
        when(p.type) {
            TextType.TEXT -> list.add(Fragment.StyledSpan(p.content, style))
            TextType.LINK -> {
                list.add(Fragment.StyledSpan("", style, href = p.content))
            }
        }
    }

    return list
}

suspend fun Editor.insertChar(ch: String) {
    replay.insertChar(ch)

    runTransaction("INSERT CHAR") { chain, style ->
        deleteSelectionIfExists(chain)

        chain.insertString(ch, style)
    }
}

fun DocChain.insertTerminalFragment(f: Fragment, atCaret: Caret? = null) {
    val logger = log("insertTerminalFragment")
    logger("run")

    val c = atCaret ?: caret
    ?: throw BugException("Can't perform operation without caret")

    val block = caretBlock(c)
    val span = caretSpan(c)
    if (span.isLink() && (f !is Fragment.StyledSpan || f.href != span.href )) {
        return
    }

    val left = span.textLeft(c)
    val right = span.textRight(c)

    val span1 = span.copy(text = left)
    val span2 = Fragment.StyledSpan(right, span.textStyle, href=span.href)

    val updatedParagraph = block.paragraph.replaceFragments(listOf(span), listOf(span1, f, span2))
    val updatedBlock = block.withUpdatedParagraph(updatedParagraph)

//    val updatedCaret = updatedBlock.caretAt(span2.guid, 0)
    val updatedCaret = if (f is Fragment.StyledSpan && !f.isLink()) updatedBlock.caretAt(f.guid, f.lastOffset)
        else updatedBlock.caretAt(span2.guid, 0)
//    console.log("INSERT TERMINAL", updatedCaret?.toFullString())

    updateBlockAndClean(updatedBlock, updatedCaret)
}

fun DocChain.insertString(plainString: String, style: TextStyle?, atCaret: Caret? = null) {
    val logger = log("insertString")
    logger("run")
    if (plainString == "") return

    val c = atCaret ?: caret
        ?: throw BugException("Can't perform operation without caret")

//    val block = caretBlock(c)
    val span = caretSpan(c)
    val left = span.textLeft(c)

    if (isConsecutiveSpaces(left, plainString))
        return warning { "consecutive spaces are ignored" }

    val newStyle = if (span.isLink()) span.textStyle else (style ?: span.textStyle)
    val newSpan = Fragment.StyledSpan(plainString, newStyle, href = span.href)

    insertTerminalFragment(newSpan, c)
}

suspend fun Editor.insertLink(name: String, href: String) {
    val fragment = Fragment.StyledSpan(text = name, href = href)

    insertTerminalFragmentAtCaret(fragment)
}

suspend fun Editor.updateLink(link: Fragment.StyledSpan, name: String, href: String) {
    updateTerminalFragment(link.copy(text = name, href = href))
}

suspend fun DocChain.insertLine(line: String, style: TextStyle?, atCaret: Caret? = null) {
    val logger = log("insertLine")
    logger("run")
    var c = atCaret ?: caret
        ?: throw BugException("Can't perform operation without caret")

    val parts = classifyText(line)

    parts.forEach { p ->
//        console.log("PART", p)
        when(p.type) {
            TextType.TEXT -> {
                insertString(p.content, style, c)
                c = caret ?: throw BugException("Broken caret after insert String")
            }
            TextType.LINK -> {
                val link = Fragment.StyledSpan(text = "", href = p.content)
                insertTerminalFragment(link, c)
                c = caret ?: throw BugException("Broken caret after insert String")
            }
        }
    }
}

suspend fun DocChain.insertMultilineText(plainText: String, style: TextStyle?, stylesheet: Stylesheet, atCaret: Caret? = null) {
    val logger = log("insertMultilineText")
    logger("run")
    var c = atCaret ?: caret
        ?: throw BugException("Can't perform operation without caret")

    val lines = plainText.split('\n')

    if (lines.size == 1) return insertLine(plainText, style, c)

    val block = caretBlock(c)
    val span = caretSpan(c)
    val style = span.textStyle

    val firstLine = lines.first()
    insertLine(firstLine, style, c)
//    console.log("@@@ inserted first line '${firstLine}' at ${c}")

    val lastBlockId = split(stylesheet)
    var currentBlockId = block.guid

    if (lines.size > 2) {
        for (i in 1..lines.size - 2) {
            val spans = textToStyledSpans(lines[i], style)

//            val newSpan = Fragment.StyledSpan(lines[i], style)
            val newParagraph = Fragment.Paragraph(spans)
            val newBlock = Block(newParagraph)

//            console.log("@@@ insert block ${newBlock.guid} after ${currentBlockId}")
            insertBlockAndClean(newBlock, currentBlockId)
            currentBlockId = newBlock.guid
        }
    }

    val lastLine = lines.last()
    val lastBlock = get(lastBlockId) ?: throw BugException("There's no second block of split in chain")
    val lastBlockStart = lastBlock.caretAtStart()
    insertLine(lastLine, style, lastBlockStart)
//    console.log("@@@ inserted last line '${lastLine}' at ${lastBlockStart}")
}